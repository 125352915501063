import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SolveInsightsState } from './types';
import {
  ArticleFeedback,
  DiscoverArticle,
} from 'src/reducers/discoverReducer/types';
import type { RootState } from 'src/store/rootReducer';
import { ArticleDataSorter } from 'src/utils/discover/helpers';

const initialState: SolveInsightsState = {
  generatedArticles: [],
  isLoadingGeneratedArticles: undefined,
  selectedArticleId: null,
};

const mutateArticleFeedbackData = (
  articles: Array<DiscoverArticle>,
  articleId: string,
  value: ArticleFeedback,
) => {
  return articles.map(article => {
    if (article.article_id === articleId) {
      return {
        ...article,
        feedback: {
          ...value,
        },
      };
    }
    return article;
  });
};

const removeArticle = (articles: Array<DiscoverArticle>, articleId: string) => {
  return articles.filter(article => article.article_id !== articleId);
};

const solveInsightsSlice = createSlice({
  initialState,
  name: 'solveInsights',
  reducers: {
    dismissArticle(state: SolveInsightsState, action: PayloadAction<string>) {
      state.generatedArticles = removeArticle(
        state.generatedArticles,
        action.payload,
      );
    },
    setGeneratedArticles(
      state: SolveInsightsState,
      action: PayloadAction<Array<DiscoverArticle>>,
    ) {
      state.generatedArticles = action.payload;
    },
    setIsLoadingGeneratedArticles(
      state: SolveInsightsState,
      action: PayloadAction<boolean>,
    ) {
      state.isLoadingGeneratedArticles = action.payload;
    },
    setSelectedArticleId(
      state: SolveInsightsState,
      action: PayloadAction<string | null>,
    ) {
      state.selectedArticleId = action.payload;
    },
    updateArticleFeedbackData(
      state: SolveInsightsState,
      action: PayloadAction<{ articleId: string; value: ArticleFeedback }>,
    ) {
      const { articleId, value } = action.payload;
      state.generatedArticles = mutateArticleFeedbackData(
        state.generatedArticles,
        articleId,
        value,
      );
    },
  },
});

export const selectSelectedArticleId = (state: RootState): string | null =>
  state.solveInsights.selectedArticleId;

export const selectGeneratedArticles = (
  state: RootState,
): Array<DiscoverArticle> => {
  const sorter = new ArticleDataSorter(
    state.solveInsights.generatedArticles ?? [],
  );

  const sortFilterValue =
    state.ui.globalDiscoverArticleOptions.articleSortFilterValue;

  return sorter
    .handleFilterState()
    .handleArticleDateSort(sortFilterValue)
    .handleThumbSort().data;
};

export const selectIsLoadingGeneratedArticles = (
  state: RootState,
): boolean | undefined => state.solveInsights.isLoadingGeneratedArticles;

export default solveInsightsSlice.reducer;

export const {
  dismissArticle,
  setGeneratedArticles,
  setIsLoadingGeneratedArticles,
  setSelectedArticleId,
  updateArticleFeedbackData,
} = solveInsightsSlice.actions;
