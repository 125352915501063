import { useMemo } from 'react';

import { useGetAgentOptionsFilter } from './useGetAgentOptionsFilter';

export const useGetFilterForBackend = (multiSelected: string[]) => {
  const { filters } = useGetAgentOptionsFilter();

  return useMemo(() => {
    const agentGroupIds: string[] = [];
    const ticketChannels: string[] = [];

    multiSelected.forEach(item => {
      filters.forEach(filter => {
        if (filter.options?.some(option => option.value === item)) {
          if (filter.value === 'agent_group_id') {
            agentGroupIds.push(item);
          } else if (filter.value === 'ticket_channel') {
            ticketChannels.push(item);
          }
        }
      });
    });

    return {
      agent_group_id: agentGroupIds.join(','),
      ticket_channel: ticketChannels.join(','),
    };
  }, [multiSelected, filters]);
};
