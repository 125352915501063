import capitalize from 'lodash/capitalize';
import { DashboardTableProps } from 'src/components/dashboard-table/DashboardTable';
import { DISCOVER_SHARED_PARAM_NAMES } from 'src/constants/discover';
import { DiscoverRecommendationTopicData } from 'src/reducers/discoverReducer/types';
import { replaceIdInRoute } from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

const a11yName = 'discover-automation';

export const a11yTabProps = (index: number) => {
  return {
    'aria-controls': `${a11yName}-tabpanel-${index}`,
    id: `${a11yName}-tab-${index}`,
  };
};

export const a11yTabPanelProps = (index: number) => {
  return {
    'aria-labelledby': `${a11yName}-tab-${index}`,
    id: `${a11yName}-tabpanel-${index}`,
  };
};

const generateUrlParams = (name: string, articleId = '') => {
  const params = new URLSearchParams({
    name,
    [DISCOVER_SHARED_PARAM_NAMES.SOURCE]: 'automation',
  });

  if (articleId) {
    params.set(DISCOVER_SHARED_PARAM_NAMES.SELECTED_ARTICLE_ID, articleId);
    params.set(DISCOVER_SHARED_PARAM_NAMES.TAB, 'Generated articles');
    params.set(DISCOVER_SHARED_PARAM_NAMES.SOURCE, 'knowledge gap');
  }

  return params.toString();
};

export const generateDiscoverTopicDetailUrl = (
  topic: DiscoverRecommendationTopicData & { article_id?: string },
) => {
  return `${replaceIdInRoute(
    Routes.DISCOVER_TOPIC_DETAIL,
    topic.topic_id,
  )}?${generateUrlParams(topic.topic_name, topic.article_id)}`;
};

export const filterByBrand = (selectedBrands: string[], data?: string) => {
  if (!selectedBrands.length) {
    return true;
  }

  return selectedBrands.includes(data as string);
};

export const handleBrandColumn = <
  T extends { api_data_filter?: string | null },
>({
  columns,
  data,
  hasBrands,
}: {
  columns: DashboardTableProps<T>['columns'];
  data: T[] | undefined;
  hasBrands?: boolean;
}) => {
  if (hasBrands && data === undefined) {
    return columns;
  }
  if (hasBrands && data?.some(row => row.api_data_filter)) {
    return columns;
  }
  return columns.filter(column => column.key !== 'api_data_filter');
};

export const getSegmentedDataFilterOptions = (
  data: (Record<string, string> | null | undefined)[],
) => {
  if (!data || data.length === 0) {
    return [];
  }

  const groupedData: Record<string, string[]> = {};

  data.forEach(item => {
    if (!item) {
      return;
    }
    Object.entries(item).forEach(([category, value]) => {
      if (!groupedData[category]) {
        groupedData[category] = [];
      }
      if (!groupedData[category].includes(value)) {
        groupedData[category].push(value);
      }
    });
  });

  return Object.entries(groupedData).map(([category, values]) => {
    const options = values.map(value => ({
      label: value,
      value: `${category}:${value}`,
    }));

    return {
      label: capitalize(category),
      options,
      value: category,
    };
  });
};

export const filterBySegmentedData = <
  T extends { segmentation_api_data_filter?: Record<string, string> | null },
>(
  selectedSegmentedData: string[],
  data: T,
) => {
  if (!selectedSegmentedData || selectedSegmentedData.length === 0) {
    return true;
  }
  return selectedSegmentedData.some(segment => {
    const [key, value] = segment.split(':');
    return data.segmentation_api_data_filter?.[key] === value;
  });
};

export const getSegmentKeys = <
  T extends { segmentation_api_data_filter?: Record<string, string> | null },
>(
  data: T[],
) => {
  const segments = data.reduce((acc, curr) => {
    return [...acc, ...Object.keys(curr.segmentation_api_data_filter || {})];
  }, [] as string[]);
  return [...new Set(segments)];
};

export const handleSegmentColumns = <
  T extends { segmentation_api_data_filter?: Record<string, string> | null },
>({
  columns,
  data,
}: {
  columns: DashboardTableProps<T>['columns'];
  data: T[] | undefined;
}) => {
  if (!data) {
    return [];
  }

  const uniqueSegments = getSegmentKeys(data);
  if (!uniqueSegments || !uniqueSegments.length) {
    return columns;
  }
  const [initialColumns, secondColumn, ...restColumns] = columns;
  const withSegmentColumns = [
    initialColumns,
    secondColumn,
    ...uniqueSegments.map(segment => ({
      key: segment,
      setValue: (row: T) => row.segmentation_api_data_filter?.[segment],
      title: capitalize(segment),
    })),
    ...restColumns,
  ];
  return withSegmentColumns;
};

export const getColumnsWithVisibility = <
  T extends { segmentation_api_data_filter?: Record<string, string> | null },
>(
  data: T[] | undefined,
  isSegmented: boolean | undefined,
) => {
  if (!isSegmented) {
    return {};
  }
  if (data === undefined) {
    return undefined;
  }
  if (data.length === 0) {
    return {};
  }
  const segments = getSegmentKeys(data);
  if (!segments || !segments.length || segments.length <= 3) {
    return {};
  }
  return segments.reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: false,
    };
  }, {});
};
