import { ChangeEventHandler, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormControl, SelectChangeEvent, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconPlus, IconTrash } from '@tabler/icons-react';

import {
  Button,
  Checkbox,
  IconButton,
  MultiStringInput,
  SelectDropdown,
  TextField,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { FormWithErrors } from '../types';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  getContextVariableType,
  getDefaultOperatorByType,
  getDefaultVaulesByType,
  isValuelessOperator,
} from 'src/pages/workflow-builder-edit/conditions/conditionEditorHelpers';
import {
  checkboxTypeOperators,
  checkboxTypeValueOptions,
  FILLED_CONDITION_ERR_MSG,
  listTypeOperators,
  multiOptionsTypeOperators,
  multiSelectListTypeOperators,
  numberTypeOperators,
  operators,
} from 'src/pages/workflow-builder-edit/conditions/constants';
import { isContextVariable } from 'src/pages/workflow-builder-edit/handoff-configuration/helpers';
import { CompositeConditionsConfig } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import {
  BooleanOperators,
  CompositeCondition,
  Condition,
  ConditionOperators,
} from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import {
  selectCanvasWorkflowBuilder,
  selectEditingConditionStepId,
  selectIsEditingCondition,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { emptyCondition } from 'src/slices/canvas-workflow-builder/utils';
import {
  CanvasWorkflowBuilderState,
  selectEditingStepFields,
  setStepFieldValue,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { ContextVariableTypeKeys, ContextVariableTypes } from 'src/utils/enums';
import { getContextVariablevNameById } from 'src/utils/getContextVariablevNameById';
import { isConditionStepFields } from 'src/utils/solve/stepUtils';

const MIN_CONDITION_COUNT = 2;
const INSUFFICIENT_CONDITIONS_ERR_MSG = `Condition step requires at least ${MIN_CONDITION_COUNT} conditions`;
const OHTERWISE_CONDITION_REQUIRED_MSG =
  'The otherwise condition is required and can not be disabled';

const getDeleteCompositeConditionMessage = (
  compositeCondition: CompositeCondition,
  currentCompositeConditions: CompositeCondition[],
  isOtherwiseSelected: boolean,
) => {
  if (compositeCondition.conditions.length > 1) {
    return null; // Always allow delete button with > 1 nested conditions
  }

  // Prevent deletion of last step for cases when minConditionCount is 1 and otherwise is selected,
  // and ensure that minConditionCount is met with or without otherwise step
  if (
    currentCompositeConditions.length === 1 ||
    currentCompositeConditions.length + (isOtherwiseSelected ? 1 : 0) <=
      MIN_CONDITION_COUNT
  ) {
    return INSUFFICIENT_CONDITIONS_ERR_MSG;
  }

  // check whether the branch is already filled with children step
  if (Boolean(compositeCondition.stepId)) {
    return FILLED_CONDITION_ERR_MSG;
  }

  return null;
};

const getOperatorDropdownOptions = (
  type?: keyof typeof ContextVariableTypes,
  contextVariable?: string,
) => {
  const isTopLevelDynamicListCv =
    type === 'DYNAMIC_LIST' && !contextVariable?.includes('/./');

  if (isTopLevelDynamicListCv) {
    return checkboxTypeOperators;
  }

  switch (type) {
    case 'CHECKBOX':
      return checkboxTypeOperators;
    case 'NUMBER':
      return numberTypeOperators;
    case 'LIST':
      return listTypeOperators;
    case 'MULTIOPTIONS':
      return multiOptionsTypeOperators;
    case 'MULTI_SELECT_LIST':
      return multiSelectListTypeOperators;
  }

  return operators;
};
const useUtilsWithContextVariables = () => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const getType = (cvId: string) =>
    getContextVariableType(cvId, contextVariables);

  const getOperator = (cvId: string) =>
    getDefaultOperatorByType(cvId, contextVariables);

  const getValues = (cvId: string) =>
    getDefaultVaulesByType(cvId, contextVariables);

  const getObject = (cvId: string): ContextVariable =>
    contextVariables.find(
      ({ context_variable_id }) => context_variable_id === cvId,
    ) ?? {
      context_variable_id: '',
      context_variable_name: '',
      context_variable_type: 'SHORT_TEXT',
      is_universal_context_variable: false,
    };

  return { getObject, getOperator, getType, getValues };
};

const useConditionHandlers = ({
  index,
  innerIndex,
}: {
  index: number;
  innerIndex: number;
}) => {
  const dispatch = useAppDispatch();
  const { getOperator, getValues } = useUtilsWithContextVariables();

  const fields = useSelector(selectEditingStepFields);

  if (!isConditionStepFields(fields)) {
    return null;
  }

  const onChangeContextVariable = (cvId: string) => {
    const clone = structuredClone(fields.compositeConditions);
    const field = clone[index].conditions[innerIndex];

    const operator = getOperator(cvId);
    const defaultValue = getValues(cvId);
    field.contextVariable = cvId;
    field.operator = operator;
    field.value = defaultValue;

    dispatch(
      setStepFieldValue({
        field: 'compositeConditions',
        value: clone,
      }),
    );
  };

  const onChangeOperator = ({ target }: SelectChangeEvent<string>) => {
    const value = target.value as ConditionOperators;
    const clone = structuredClone(fields.compositeConditions);
    const contextVariable = clone[index].conditions[innerIndex].contextVariable;

    const defaultValue = getValues(contextVariable);
    const updatedCondition = {
      ...clone[index].conditions[innerIndex],
      operator: value,
      value: isValuelessOperator(value) ? '' : defaultValue,
    };
    clone[index].conditions[innerIndex] = updatedCondition;

    dispatch(
      setStepFieldValue({
        field: 'compositeConditions',
        value: clone,
      }),
    );
  };

  const onDelete = () => {
    const clone = structuredClone(fields.compositeConditions);

    const isLastCondition = clone[index].conditions.length === 1;

    if (isLastCondition) {
      clone.splice(index, 1);
      dispatch(
        setStepFieldValue({
          field: 'compositeConditions',
          value: clone,
        }),
      );

      return;
    }

    clone[index].conditions.splice(innerIndex, 1);

    dispatch(
      setStepFieldValue({
        field: 'compositeConditions',
        value: clone,
      }),
    );
  };

  const onChangeValue = (
    value: string | string[],
    cvType?: keyof typeof ContextVariableTypes,
  ) => {
    let receivedValue: string | string[] | number | boolean = value;

    switch (cvType) {
      case ContextVariableTypeKeys.NUMBER: {
        if (!isContextVariable(value as string)) {
          receivedValue = Number(value);
        }
        break;
      }

      case ContextVariableTypeKeys.CHECKBOX: {
        const isTrueSet = value === 'true';
        receivedValue = isTrueSet;
        break;
      }
    }

    const clone = structuredClone(fields.compositeConditions);
    clone[index].conditions[innerIndex].value = receivedValue;

    dispatch(
      setStepFieldValue({
        field: 'compositeConditions',
        value: clone,
      }),
    );
  };

  return { onChangeContextVariable, onChangeOperator, onChangeValue, onDelete };
};

const ConditionStepForm = ({
  errorObject,
}: FormWithErrors<CompositeConditionsConfig>) => {
  const { palette } = useTheme();

  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const stepId = useSelector(selectEditingConditionStepId);
  const stepData = canvasData.steps[stepId];
  const fields = useSelector(selectEditingStepFields);
  const isEditingCondition = useSelector(selectIsEditingCondition);
  const dispatch = useAppDispatch();

  const transitions = stepData?.transitions || [];
  const isOtherwiseConditionSelectedOnStep =
    transitions.length > 0 &&
    transitions[transitions.length - 1].condition_expression === null;

  if (!isConditionStepFields(fields)) {
    return null;
  }

  const { compositeConditions, conditionName, otherwiseCondition } = fields;

  const otherWiseConditionChanged =
    otherwiseCondition.isOtherwiseSelected !==
    isOtherwiseConditionSelectedOnStep;

  const setName: ChangeEventHandler<HTMLInputElement> = e => {
    dispatch(
      setStepFieldValue({ field: 'conditionName', value: e.target.value }),
    );
  };

  const onAddNewCondition = () => {
    const clone = structuredClone(compositeConditions);

    dispatch(
      setStepFieldValue({
        field: 'compositeConditions',
        value: [...clone, { conditions: [{ ...emptyCondition }] }],
      }),
    );
  };

  const onCheckOtherwise = () => {
    dispatch(
      setStepFieldValue({
        field: 'otherwiseCondition',
        value: {
          ...otherwiseCondition,
          isOtherwiseSelected: !otherwiseCondition.isOtherwiseSelected,
        },
      }),
    );
  };

  const OtherwiseCheckbox = () => {
    const { isOtherwiseSelected } = otherwiseCondition;
    const isDisabled =
      !isEditingCondition || isOtherwiseSelected || otherWiseConditionChanged;

    const message =
      !isEditingCondition || (isEditingCondition && isDisabled)
        ? OHTERWISE_CONDITION_REQUIRED_MSG
        : null;

    const checkbox = (
      <Checkbox
        checked={otherwiseCondition.isOtherwiseSelected}
        disabled={isDisabled}
        label={
          <Typography variant='font14'>
            Add an <b>“Otherwise”</b> condition to capture all other cases.
          </Typography>
        }
        onChange={onCheckOtherwise}
      />
    );

    if (message) {
      return <Tooltip tooltipContent={message}>{checkbox}</Tooltip>;
    }

    return checkbox;
  };

  return (
    <>
      <Box borderBottom={'1px solid ' + palette.colors.slate[200]} pb={3}>
        <TextField
          error={errorObject?.conditionName}
          label='Condition display name'
          onChange={setName}
          placeholder='Give it a name'
          value={conditionName}
        />
      </Box>
      {compositeConditions.map((compositeCondition, index) => (
        <CompositionCondition
          compositeCondition={compositeCondition}
          deleteCompositeConditionMessage={getDeleteCompositeConditionMessage(
            compositeCondition,
            compositeConditions,
            otherwiseCondition.isOtherwiseSelected,
          )}
          errorObject={errorObject?.compositeConditions?.[index]}
          index={index}
          key={index}
        />
      ))}
      <Box
        borderBottom={'1px solid ' + palette.colors.slate[200]}
        mb={3}
        py={3}
      >
        <Button
          fullWidth
          onClick={onAddNewCondition}
          startIcon={<IconPlus size={16} />}
          variant='secondary'
        >
          New Condition
        </Button>
      </Box>
      <OtherwiseCheckbox />
    </>
  );
};

interface CompositionConditionProps {
  compositeCondition: CompositeCondition;
  deleteCompositeConditionMessage: string | null;
  errorObject?: CompositeCondition;
  index: number;
}

const CompositionCondition = ({
  compositeCondition,
  deleteCompositeConditionMessage,
  errorObject,
  index,
}: CompositionConditionProps) => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const fields = useSelector(selectEditingStepFields);

  if (!isConditionStepFields(fields)) {
    return null;
  }

  const { booleanOperator = 'and', conditions } = compositeCondition;

  const onAddCompositeCondition = () => {
    const shouldAddAndOperator = conditions.length === 1;
    const newCondition = {
      ...emptyCondition,
    };

    const clone = structuredClone(fields.compositeConditions);
    clone[index].conditions = [...clone[index].conditions, newCondition];

    if (shouldAddAndOperator) {
      clone[index].booleanOperator = 'and';
    }

    dispatch(
      setStepFieldValue({
        field: 'compositeConditions',
        value: clone,
      }),
    );
  };

  const onChangeBooleanOperator = (e: SelectChangeEvent<BooleanOperators>) => {
    const clone = structuredClone(fields.compositeConditions);
    clone[index].booleanOperator = e.target.value as BooleanOperators;

    dispatch(
      setStepFieldValue({
        field: 'compositeConditions',
        value: clone,
      }),
    );
  };

  return (
    <Box
      borderBottom={'1px solid ' + palette.colors.slate[200]}
      display='flex'
      flexDirection='column'
      gap={1}
      py={3}
    >
      {conditions.map((condition, innerIndex) => (
        <Box alignItems='center' display='flex' gap={1} key={innerIndex}>
          <Box
            alignItems='center'
            display='flex'
            justifyContent='center'
            width='79.9px'
          >
            {innerIndex === 0 ? (
              <Typography variant='font16Bold'>{index + 1}</Typography>
            ) : (
              <SelectDropdown
                id={`boolean-operator-dropdown-${index}-${innerIndex}`}
                onChange={onChangeBooleanOperator}
                options={[
                  { label: 'and', value: 'and' },
                  { label: 'or', value: 'or' },
                ]}
                value={booleanOperator}
              />
            )}
          </Box>
          <ConditionItem
            condition={condition}
            deleteCompositeConditionMessage={deleteCompositeConditionMessage}
            errorObject={errorObject?.conditions?.[innerIndex]}
            index={index}
            innerIndex={innerIndex}
          />
        </Box>
      ))}
      <Box>
        <Button
          onClick={onAddCompositeCondition}
          startIcon={<IconPlus size={16} />}
          variant='ghost'
        >
          Add composite logic
        </Button>
      </Box>
    </Box>
  );
};

interface ConditionItemProps {
  condition: Condition;
  deleteCompositeConditionMessage: string | null;
  errorObject?: Condition;
  index: number;
  innerIndex: number;
}

const ConditionItem = ({
  condition,
  deleteCompositeConditionMessage,
  errorObject,
  index,
  innerIndex,
}: ConditionItemProps) => {
  const [numberInputMode, setNumberInputMode] = useState<'number' | 'cvs'>(
    'number',
  );

  const { getObject } = useUtilsWithContextVariables();

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const handlers = useConditionHandlers({
    index,
    innerIndex,
  });

  if (!handlers) {
    return null;
  }

  const { onChangeContextVariable, onChangeOperator, onChangeValue, onDelete } =
    handlers;

  const { contextVariable, operator, value } = condition;

  const contextVariableObject = getObject(contextVariable);
  const { context_variable_type: cvType } = contextVariableObject;

  const DeleteButton = () => {
    const isDisabled = Boolean(deleteCompositeConditionMessage);
    const button = (
      <IconButton
        aria-label='delete condition'
        disabled={isDisabled}
        onClick={onDelete}
        variant='ghost'
      >
        <IconTrash size={20} />
      </IconButton>
    );
    if (deleteCompositeConditionMessage) {
      return (
        <Tooltip tooltipContent={deleteCompositeConditionMessage}>
          {button}
        </Tooltip>
      );
    }
    return button;
  };

  return (
    <>
      <Tooltip
        tooltipContent={getContextVariablevNameById(
          contextVariable,
          contextVariables,
        )}
      >
        <Box flex={1} maxWidth={200}>
          <ContextVariableSelectDropdown
            aria-label='Context variable'
            error={Boolean(errorObject?.contextVariable)}
            onChange={onChangeContextVariable}
            shouldIncludeSystemContextVariables
            shouldOverrideDisabledContextVariableSelection
            value={contextVariable}
          />
        </Box>
      </Tooltip>
      <Box flex={1}>
        <SelectDropdown
          id='operator-dropdown'
          onChange={onChangeOperator}
          options={getOperatorDropdownOptions(cvType, contextVariable).map(
            operator => ({
              label: operator,
              value: operator,
            }),
          )}
          value={operator}
        />
      </Box>
      {!['is not empty', 'is empty'].includes(operator) && (
        <Box alignItems='center' display='flex' flexDirection='row'>
          {cvType === 'NUMBER' && (
            <Box flex={1} maxWidth={100}>
              <FormControl fullWidth>
                <SelectDropdown
                  id='input-mode-select-dropdown-'
                  onChange={e => {
                    const newMode = e.target.value;
                    if (newMode === 'number' || newMode === 'cvs') {
                      setNumberInputMode(newMode);
                    }
                  }}
                  options={[
                    { label: 'INPUT', value: 'number' },
                    { label: 'CV', value: 'cvs' },
                  ]}
                  value={numberInputMode}
                />
              </FormControl>
            </Box>
          )}
          <Tooltip
            tooltipContent={
              isContextVariable(value.toString())
                ? getContextVariablevNameById(
                    value.toString().replace(/^{{\s*|\s*}}$/g, ''),
                    contextVariables,
                  )
                : ''
            }
          >
            <Box flex={1} minWidth={25}>
              <ValueField
                contextVariableObject={contextVariableObject}
                error={errorObject?.value ? String(errorObject?.value) : null}
                numberInputMode={numberInputMode}
                onChangeValue={onChangeValue}
                operator={operator}
                type={cvType}
                value={value}
              />
            </Box>
          </Tooltip>
        </Box>
      )}
      <DeleteButton />
    </>
  );
};

interface ValueFieldProps {
  contextVariableObject: ContextVariable;
  error: string | null;
  numberInputMode?: string | null;
  onChangeValue: (
    value: string | string[],
    cvType?: keyof typeof ContextVariableTypes,
  ) => void;
  operator: Condition['operator'];
  type?: keyof typeof ContextVariableTypes;
  value: Condition['value'];
}

const ValueField = ({
  contextVariableObject,
  error,
  numberInputMode = null,
  onChangeValue,
  operator,
  type,
  value,
}: ValueFieldProps) => {
  switch (type) {
    case 'CHECKBOX':
    case 'LIST':
    case 'MULTI_SELECT_LIST':
      return (
        <SelectDropdown
          disabled={isValuelessOperator(operator)}
          error={Boolean(error)}
          id='list-value-dropdown'
          onChange={e => onChangeValue(e.target.value, type)}
          options={
            type === 'CHECKBOX'
              ? checkboxTypeValueOptions
              : contextVariableObject.list_type_options || []
          }
          placeholder={isValuelessOperator(operator) ? '' : 'Select a value'}
          value={value.toString()}
        />
      );
    case 'MULTIOPTIONS':
      return (
        <MultiStringInput
          onChange={(_, value) => {
            onChangeValue(value, type);
          }}
          options={[]}
          placeholder='Add values on enter'
          value={Array.isArray(value) ? value : []}
        />
      );

    case 'NUMBER':
      return (
        <>
          {numberInputMode === 'number' ? (
            <Box maxWidth={150}>
              <TextField
                aria-label='number value condition'
                disabled={isValuelessOperator(operator)}
                error={Boolean(error)}
                onChange={e => onChangeValue(e.target.value, type)}
                placeholder='Value'
                type='number'
                value={value.toString()}
              />
            </Box>
          ) : (
            <Box maxWidth={150}>
              <ContextVariableSelectDropdown
                aria-label='Context variable'
                contextVariableTypeOptions={[
                  { label: 'Add Number', value: 'NUMBER' },
                ]}
                disabled={isValuelessOperator(operator)}
                error={Boolean(error)}
                filterFn={cv => cv.context_variable_type === 'NUMBER'} // Filters to only show 'NUMBER' type
                onChange={selectedValue => {
                  onChangeValue(selectedValue, type);
                }}
                shouldIncludeSystemContextVariables
                shouldOverrideDisabledContextVariableSelection
                shouldProvideCVIdFormatting
                value={value.toString()}
              />
            </Box>
          )}
        </>
      );
    default:
      return (
        <ContextVariableAutocomplete
          aria-label='Context variable'
          disabled={isValuelessOperator(operator)}
          error={Boolean(error)}
          minWidth='250px'
          onChange={value => {
            onChangeValue(value, type);
          }}
          shouldIncludeSystemContextVariables
          value={value.toString()}
        />
      );
  }
};

export default ConditionStepForm;
