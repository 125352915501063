import { FC } from 'react';
import { Box, useTheme } from '@mui/material';

import {
  NonIdealState,
  Typography,
} from '@forethought-technologies/forethought-elements';
import SegmentSection from '../SegmentSection';
import iconBrokenSearch from 'src/assets/images/icon-broken-search.svg';
import { NA } from 'src/constants/solve';

const GeneratedPolicyTab: FC<{
  brand?: string | boolean;
  policy?: string;
  segments?: Record<string, string> | null;
  topicName: string;
}> = ({ brand, policy, segments, topicName }) => {
  const { palette } = useTheme();

  return (
    <Box display='flex' flexDirection='column' gap='32px' mt='24px'>
      <Box display='flex' flexDirection='column' gap={1}>
        <Box display='grid' gridTemplateColumns='42px auto'>
          <Typography color={palette.colors.grey[600]} variant='font12Medium'>
            Topic:
          </Typography>
          <Typography variant='font12Medium'>{topicName}</Typography>
        </Box>
        {brand !== false && (
          <Box display='grid' gridTemplateColumns='42px auto'>
            <Typography color={palette.colors.grey[600]} variant='font12Medium'>
              Brand:
            </Typography>
            <Typography
              color={!brand ? palette.colors.grey[600] : undefined}
              variant='font12Medium'
            >
              {brand || NA}
            </Typography>
          </Box>
        )}
        <SegmentSection segments={segments} />
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        sx={{
          '&>.policy-items>ol>li': { fontWeight: 600 },
          '&>.policy-items>ol>li>ol>li': { fontWeight: 400, listStyle: 'disc' },
        }}
      >
        {policy ? (
          <>
            <Typography variant='font14Bold'>
              Generated AutoFlow policy
            </Typography>
            <span
              className='policy-items'
              dangerouslySetInnerHTML={{
                __html: policy,
              }}
            />
          </>
        ) : (
          <Box
            alignItems='center'
            display='flex'
            height='400px'
            justifyContent='center'
            width='100%'
          >
            <NonIdealState
              icon={<img alt='no policy available' src={iconBrokenSearch} />}
              title='No policy has been generated for this topic'
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GeneratedPolicyTab;
