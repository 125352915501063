import { Box, styled, useTheme } from '@mui/material';
import { IconInfoCircleFilled } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  ConversationStartedPayload,
  CreateTicketPayload,
  GenericToolPayload,
  IntentPredictionPayload,
  IntentRoutingPayload,
  SearchHelpCenterArticlesPayload,
} from './type';
import warningIcon from 'src/assets/images/circular-warning-icon.svg';
import { getNameForTool } from 'src/pages/workflow-builder-edit/autonomous-agent/action-drawer/helpers';

export const ConversationIDMessage = ({
  payload,
}: {
  payload: ConversationStartedPayload;
}) => {
  const theme = useTheme();

  return (
    <ExecutionMessageContainer>
      <Row>
        <Typography color={theme.palette.colors.grey[100]} variant='font14'>
          Conversation ID:
        </Typography>

        <Typography color={theme.palette.colors.blue[300]} variant='font14'>
          {payload.conversation_id}
        </Typography>
      </Row>
    </ExecutionMessageContainer>
  );
};

export const IntentPredictionMessage = ({
  payload,
}: {
  payload: IntentPredictionPayload;
}) => {
  const theme = useTheme();

  if (payload.intent === 'Knowledge Retrieval') {
    return (
      <ExecutionMessageContainer>
        <Row>
          <img alt='' src={warningIcon} />
          <Typography color={theme.palette.colors.grey[100]} variant='font14'>
            No intent predicted
          </Typography>
        </Row>
        <Typography color={theme.palette.colors.grey[300]} variant='font14'>
          Entering the knowledge retrieval to generate answer
        </Typography>
      </ExecutionMessageContainer>
    );
  }
  return (
    <ExecutionMessageContainer>
      <Typography color={theme.palette.colors.grey[100]} variant='font14'>
        Predicted intent:
      </Typography>

      <Typography color={theme.palette.colors.blue[300]} variant='font14'>
        {payload.intent}
      </Typography>
    </ExecutionMessageContainer>
  );
};

export const SearchHelpCenterArticlesMessage = ({
  payload,
}: {
  payload: SearchHelpCenterArticlesPayload;
}) => {
  const theme = useTheme();

  if (payload.articles && payload.articles.length > 0) {
    return (
      <ExecutionMessageContainer>
        <Row>
          <IconInfoCircleFilled
            size={20}
            style={{
              color: theme.palette.colors.green[500],
              fill: theme.palette.colors.green[500],
            }}
          />
          <Typography color={'white'} variant='font14'>
            Searched help center articles. Generated answer from:
          </Typography>
        </Row>

        <Box display='flex' flexDirection='column' gap='8px' mt='4px'>
          {payload.articles.map(article => {
            return (
              <Box display='flex' flexDirection='column' key={article.title}>
                <Row>
                  <Box display='flex' maxHeight='20px' minWidth='50px'>
                    <Typography
                      color={theme.palette.colors.grey[100]}
                      variant='font14'
                    >
                      Title:
                    </Typography>
                  </Box>

                  <Typography
                    color={theme.palette.colors.grey[100]}
                    variant='font14'
                  >
                    {article.title}
                  </Typography>
                </Row>
                <Row>
                  <Box display='flex' maxHeight='20px' minWidth='50px'>
                    <Typography
                      color={theme.palette.colors.grey[100]}
                      variant='font14'
                    >
                      Link:
                    </Typography>
                  </Box>

                  <Typography
                    color={theme.palette.colors.blue[300]}
                    variant='font14'
                  >
                    {article.link}
                  </Typography>
                </Row>
              </Box>
            );
          })}
        </Box>
      </ExecutionMessageContainer>
    );
  }

  return (
    <ExecutionMessageContainer>
      <Row>
        <IconInfoCircleFilled
          size={20}
          style={{
            color: theme.palette.colors.green[500],
            fill: theme.palette.colors.green[500],
          }}
        />
        <Typography color={'white'} variant='font14'>
          Searched in help center articles but found not articles.
        </Typography>
      </Row>
    </ExecutionMessageContainer>
  );
};

export const CreateTicketMessage = ({
  payload,
  type,
}: {
  payload: CreateTicketPayload;
  type: 'create_zendesk_ticket' | 'create_intercom_ticket';
}) => {
  const theme = useTheme();

  return (
    <ExecutionMessageContainer>
      <Row>
        <IconInfoCircleFilled
          size={20}
          style={{
            color: theme.palette.colors.green[500],
            fill: theme.palette.colors.green[500],
          }}
        />
        <Typography color={'white'} variant='font14'>
          Executed {getNameForTool(type)}. Result is:
        </Typography>
      </Row>

      <Row>
        <Typography color={'white'} variant='font14'>
          Status:
        </Typography>

        <Typography color={theme.palette.colors.blue[300]} variant='font14'>
          {payload.status}
        </Typography>
      </Row>

      <Row>
        <Typography color={'white'} variant='font14'>
          Ticket ID:
        </Typography>

        <Typography color={theme.palette.colors.blue[300]} variant='font14'>
          {payload.ticket_id}
        </Typography>
      </Row>
    </ExecutionMessageContainer>
  );
};

export const GenericToolMessage = ({
  payload,
}: {
  payload: GenericToolPayload;
}) => {
  const theme = useTheme();

  return (
    <ExecutionMessageContainer>
      <Row>
        <IconInfoCircleFilled
          size={20}
          style={{
            color: theme.palette.colors.green[500],
            fill: theme.palette.colors.green[500],
          }}
        />
        <Typography color={'white'} variant='font14'>
          Executed tool:
        </Typography>
        <Typography color={theme.palette.colors.blue[300]} variant='font14'>
          {payload.tool_name}.
        </Typography>
      </Row>

      <Typography color={theme.palette.colors.grey[100]} variant='font14'>
        <pre
          style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
        >
          <span style={{ color: theme.palette.colors.blue[300] }}>Input: </span>
          {JSON.stringify(payload.input, null, 2)}
        </pre>
      </Typography>

      <Typography color={theme.palette.colors.grey[100]} variant='font14'>
        <pre
          style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
        >
          <span style={{ color: theme.palette.colors.blue[300] }}>
            Output:{' '}
          </span>
          {JSON.stringify(payload.output, null, 2)}
        </pre>
      </Typography>
    </ExecutionMessageContainer>
  );
};

export const IntentRoutingMessage = ({
  payload,
}: {
  payload: IntentRoutingPayload;
}) => {
  const theme = useTheme();

  return (
    <ExecutionMessageContainer>
      <Row>
        <IconInfoCircleFilled
          size={20}
          style={{
            color: theme.palette.colors.green[500],
            fill: theme.palette.colors.green[500],
          }}
        />
        <Typography color={theme.palette.colors.grey[100]} variant='font14'>
          Routing to workflow
        </Typography>
      </Row>

      <Typography color={theme.palette.colors.blue[300]} variant='font14'>
        {payload.intent_title}
      </Typography>
    </ExecutionMessageContainer>
  );
};

const ExecutionMessageContainer = styled('div')`
  background-color: ${props => props.theme.palette.colors.grey[900]};
  border: 1px solid ${props => props.theme.palette.colors.grey[700]};
  border-radius: 12px;
  font-family: Fira Code !important;
  padding: 10px 14px;

  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Row = styled('div')`
  display: flex;
  gap: 8px;
  word-break: break-word;
  align-items: flex-start;
`;
