import { useMemo, useState } from 'react';
import { MRT_Row } from 'material-react-table';
import { SelectChangeEvent, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  SelectDropdown,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  COLUMNS,
  DOCUMENT_TYPE_FILTER_OPTIONS,
  SEARCH_OPTIONS,
} from './constants';
import DocumentDetailsDrawer from './DocumentDetailsDrawer';
import DashboardTable from 'src/components/dashboard-table';
import { timeFilterOptions } from 'src/constants/discover';
import { useStateParams } from 'src/hooks/hooks';
import { SortDirection, StandardizedDocType } from 'src/services/apiInterfaces';
import { ConnectorDocument } from 'src/services/connector/types';
import { useGetDocumentListQuery } from 'src/services/dashboard-api';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { datePickerRangeOptions } from 'src/utils/timeRangeHelpers';

const DocumentsTab = () => {
  const [searchType, setSearchType] = useStateParams({
    deserialize: value => value,
    initialState: 'title',
    paramsName: 'documents-search-type',
    serialize: value => value,
  });
  const [searchText, setSearchText] = useStateParams({
    deserialize: value => value,
    initialState: '',
    paramsName: 'documents-search-text',
    serialize: value => value,
  });
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: datePickerRangeOptions[2].value,
    paramsName: 'documents-date-range',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator(timeFilterOptions),
  });
  const [standardizedDocumentType, setStandardizedDocumentType] =
    useState<StandardizedDocType>(StandardizedDocType.KNOWLEDGE_ARTICLE);
  const [drawerDetails, setDrawerDetails] = useState<{
    id: string;
    type: StandardizedDocType;
  } | null>(null);

  const [cursor, setCursor] = useState<string | null>(null);
  const [sorting, setSorting] = useState<
    {
      desc: boolean;
      id: string;
    }[]
  >([{ desc: true, id: 'created_date' }]);

  const theme = useTheme();
  const params = useMemo(
    () => ({
      cursor,
      endCreatedDate: dateRange.to
        ? Math.floor(new Date(dateRange.to).getTime() / 1000)
        : undefined,
      integration: searchType === 'integration' ? searchText : undefined,
      sortColumn: 'created_date',
      sortDirection: (sorting[0].desc ? 'desc' : 'asc') as SortDirection,
      sourceId: searchType === 'id' ? searchText : undefined,
      standardizedDocumentType,
      startCreatedDate: dateRange.from
        ? Math.floor(new Date(dateRange.from).getTime() / 1000)
        : undefined,
      title: searchType === 'title' ? searchText : undefined,
    }),
    [
      cursor,
      dateRange.from,
      dateRange.to,
      searchText,
      searchType,
      sorting,
      standardizedDocumentType,
    ],
  );
  const {
    data: documentListData,
    isFetching,
    isLoading,
    refetch,
  } = useGetDocumentListQuery(params);

  const handleLoadMore = () => {
    if (isFetching || isLoading) {
      return;
    }
    setCursor(documentListData?.cursor || null);
    refetch();
  };

  const handleSearchTypeChange = (value: string) => {
    setSearchType(value, () => setSearchText(''));
    setCursor(null);
  };

  const handleRowClick = (row: MRT_Row<ConnectorDocument>) => {
    setDrawerDetails({
      id: row.original.standardized_document_id,
      type: standardizedDocumentType,
    });
  };

  const handleCloseDrawer = () => {
    setDrawerDetails(null);
  };

  const handleDocumentTypeChange = (e: SelectChangeEvent) => {
    setStandardizedDocumentType(e.target.value as StandardizedDocType);
    setCursor(null);
  };

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <Typography color={theme.palette.colors.grey[600]} variant='font16'>
        Index documents extracted from your connectors to quickly locate
        content.
      </Typography>
      <DashboardTable<ConnectorDocument>
        columns={COLUMNS}
        data={documentListData?.documents || []}
        dateRangeFilter={{
          onChange: setDateRange,
          options: datePickerRangeOptions,
          value: dateRange,
        }}
        FilterComponent={
          <Box alignItems='center' display='flex' gap={1} maxHeight={36}>
            <Box
              sx={{
                '& .MuiInputBase-root': {
                  height: '36px',
                },
              }}
            >
              <SelectDropdown
                id='document-type-select'
                onChange={handleDocumentTypeChange}
                options={DOCUMENT_TYPE_FILTER_OPTIONS}
                placeholder='Select document type'
                value={standardizedDocumentType}
              />
            </Box>
          </Box>
        }
        hasNextPage={documentListData?.cursor !== null}
        initialSorting={sorting}
        isFetching={isFetching || isLoading}
        onClick={handleRowClick}
        onLoadMore={handleLoadMore}
        onSearch={setSearchText}
        onSearchTypeChange={handleSearchTypeChange}
        searchOptions={SEARCH_OPTIONS}
        searchText={searchText}
        searchType={searchType}
        tableOptions={{
          manualSorting: true,
          onSortingChange: setSorting,
          state: {
            sorting: sorting,
          },
        }}
      />
      {drawerDetails && (
        <DocumentDetailsDrawer
          onClose={handleCloseDrawer}
          open={!!drawerDetails}
          standardizedDocumentId={drawerDetails.id}
          standardizedDocumentType={drawerDetails.type}
        />
      )}
    </Box>
  );
};

export default DocumentsTab;
