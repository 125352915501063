import { formatReadableDate } from './helpers';
import capitalize from 'lodash/capitalize';
import { DashboardTableProps } from 'src/components/dashboard-table/DashboardTable';
import { ConnectorDocument } from 'src/services/connector/types';

export const COLUMNS: DashboardTableProps<ConnectorDocument>['columns'] = [
  {
    columnOptions: {
      enableSorting: false,
    },
    key: 'document_type',
    setValue: value => capitalize(value.document_type.replace(/_/g, ' ')),
    size: 200,
    title: 'Document Type',
  },
  {
    columnOptions: {
      enableSorting: false,
    },
    key: 'title',
    rows: 2,
    size: 300,
    title: 'Title',
  },
  {
    columnOptions: {
      enableSorting: false,
    },
    key: 'source_id',
    rows: 1,
    size: 300,
    title: 'Source ID',
  },
  {
    columnOptions: {
      enableSorting: false,
    },
    key: 'public',
    setValue: value => (value.public ? 'Public' : 'Private'),
    size: 200,
    title: 'Public',
  },
  {
    columnOptions: {
      enableSorting: false,
    },
    key: 'searchable',
    size: 200,
    title: 'Searchable',
    valueType: 'boolean_yn',
  },
  {
    columnOptions: {
      enableSorting: false,
    },
    key: 'integration',
    setValue: value => capitalize(value.integration || ''),
    size: 200,
    title: 'Integration',
  },
  {
    columnOptions: {
      enableSorting: true,
    },
    key: 'created_date',
    setValue: value => formatReadableDate(Number(value.created_date)),
    size: 300,
    title: 'Created Date',
    valueType: 'timestamp',
  },
];

export const DOC_TYPES = [
  'knowledge_article',
  'response_template',
  'standardized_ticket',
  'reply',
  'ticket_update_event',
];

export const DOCUMENT_TYPE_FILTER_OPTIONS = DOC_TYPES.map(docType => ({
  label: capitalize(docType.replace(/_/g, ' ')),
  value: docType,
}));

export const SEARCH_OPTIONS = [
  {
    text: 'Source ID',
    value: 'id',
  },
  {
    text: 'Title',
    value: 'title',
  },
];

export const MAX_BODY_LENGTH = 300;
