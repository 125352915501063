import { IndicatorStep } from './IndicatorStep';
import { ExecutionErrorTranscriptComponent } from './types';

export const ExecutionError = ({
  transcriptComponent,
}: {
  transcriptComponent: ExecutionErrorTranscriptComponent;
}) => {
  return <IndicatorStep message={transcriptComponent.message || ''} />;
};
