export const CHAT_TRENDS_PERIOD_OPTIONS = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
] as const;

export type InsightSortDirection = 'ASC' | 'DESC';

export type InsightPeriod = 'daily' | 'weekly' | 'monthly';

export type Scope =
  | 'main'
  | 'detail'
  | 'parent_detail'
  | 'overview'
  | 'workflow'
  | 'agent_main';

export type OverviewOvertimePeriod =
  | 'daily'
  | 'weekly'
  | 'monthly'
  | 'quarterly';

export interface TimestampRange {
  end_timestamp: number;
  start_timestamp: number;
}

export type InsightsTab =
  | 'workflow'
  | 'article'
  | 'topic'
  | 'chat'
  | 'overview';

export type ChatTrendsPeriodicalFilter =
  (typeof CHAT_TRENDS_PERIOD_OPTIONS)[number]['value'];

export type ProductMode = 'widget' | 'email' | 'voice';
export type Channel = 'widget' | 'slack' | 'api';
export type SearchQueryMode = 'conversation_id' | 'user_query' | 'chat';
export type EmailSearchQueryMode = 'conversation_id' | 'source_id';
