import { useState } from 'react';
import { Box, useTheme } from '@mui/material';

import {
  FilterButton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { CHAT_COMPARISON_PERIOD_OPTIONS } from '../../solve-insights/constants';
import { stringifyDateRange } from '../../solve-insights/helpers';
import { OverviewOvertimePeriod } from '../../solve-insights/types';
import ComparisonGraph from './ComparisonGraph';
import FilterButtonIcon from 'src/components/discover-filter-button-icon/FilterButtonIcon';
import ToggleButtonGroup from 'src/components/toggle-button-group';
import { DateRange } from 'src/types/types';
import { getPreviousDateRangeFromCurrentDateRange } from 'src/utils/dateUtils';

interface ComparisonSectionProps {
  dateRange: DateRange;
  multiSelected: string[];
}

const ComparisonSection = ({
  dateRange,
  multiSelected,
}: ComparisonSectionProps) => {
  const { palette } = useTheme();

  const [periodicalFilter, setPeriodicalFilter] =
    useState<OverviewOvertimePeriod>('weekly');
  const [graphType, setGraphType] = useState<string>('Ticket');

  const getPreviousDateRange =
    getPreviousDateRangeFromCurrentDateRange(dateRange);

  return (
    <Box display='flex' flexDirection='column' gap={3} paddingTop='44px'>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='font20'>Agent performance comparison</Typography>
        <Typography color={palette.grey[600]} variant='font12'>
          {`Compare to ${stringifyDateRange(getPreviousDateRange)}`}
        </Typography>
      </Box>
      <Box
        border={`1px solid ${palette.colors.slate[200]}`}
        borderRadius='8px'
        padding='24px'
      >
        <Box alignItems='center' display='flex' justifyContent='space-between'>
          <Typography variant='font16Bold'>Time series comparison</Typography>
          <Box display='flex' gap={1}>
            <ToggleButtonGroup
              alignment={graphType}
              isDisabled
              items={[
                { label: 'AI QA', value: 'AI QA' },
                { label: 'Ticket', value: 'Ticket' },
              ]}
              onChange={newAlignment => {
                if (!newAlignment) {
                  return;
                }
                setGraphType(newAlignment);
              }}
              toggleWidth='100%'
              width='240px'
            />
            <FilterButton
              aria-label='time period filter'
              disabledTooltipMessage='Time period not valid for selected time range'
              initialValue='monthly'
              onChange={value =>
                setPeriodicalFilter(value as OverviewOvertimePeriod)
              }
              options={CHAT_COMPARISON_PERIOD_OPTIONS.map(option => {
                return { ...option };
              })}
              startAdornment={<FilterButtonIcon dropdownType='time-period' />}
              value={periodicalFilter}
            />
          </Box>
        </Box>
        <ComparisonGraph
          dateRange={dateRange}
          multiSelected={multiSelected}
          periodicalFilter={periodicalFilter}
        />
      </Box>
    </Box>
  );
};

export default ComparisonSection;
