import { createElement } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import ArticleImage from './ArticleImage';

const parseHTML = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return convertNodesToReact(Array.from(doc.body.childNodes));
};

const convertNodesToReact = (nodes: Node[]) => {
  const elements: React.ReactNode[] = [];

  nodes.forEach((node, index) => {
    if (node.nodeType === Node.TEXT_NODE) {
      elements.push(node.textContent);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;
      if (element.tagName.toLowerCase() === 'img') {
        elements.push(
          <ArticleImage key={index} src={element.getAttribute('src') || ''} />,
        );
      } else {
        const children = convertNodesToReact(Array.from(node.childNodes));
        elements.push(
          createElement(
            (node as HTMLElement).tagName.toLowerCase(),
            { key: index },
            ...children,
          ),
        );
      }
    }
  });

  return elements;
};

const HTMLContent = ({ content }: { content: string }) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        img: {
          maxWidth: '100%',
          width: '100%',
        },
        NEW: {
          backgroundColor: palette.colors.yellow[100],
          borderRadius: '4px',
          display: 'block',
          padding: '10px',
        },
      }}
    >
      {parseHTML(content)}
    </Box>
  );
};

export default HTMLContent;
