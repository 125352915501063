import { EditCreateIntercomTicketTool } from './edit-action-drawer/EditCreateIntercomTicketTool';
import { EditCreateZendeskTicketTool } from './edit-action-drawer/EditCreateZendeskTicketTool';
import { EditSearchHelpCenterArticles } from './edit-action-drawer/EditSearchHelpCenterArticles';
import {
  AutoflowTool,
  ToolDefinitionResponse,
} from 'src/types/workflowBuilderAPITypes';

interface EditToolDrawerProps {
  intentWorkflowId: string;
  onClose: () => void;
  tool:
    | (AutoflowTool & { toolDefinition?: ToolDefinitionResponse | undefined })
    | undefined;
}

export const EditToolDrawer = ({
  intentWorkflowId,
  onClose,
  tool,
}: EditToolDrawerProps) => {
  if (tool?.tool_id === 'search_help_center_articles') {
    return (
      <EditSearchHelpCenterArticles
        intentWorkflowId={intentWorkflowId}
        onClose={onClose}
        tool={tool}
      />
    );
  } else if (tool?.tool_id === 'create_zendesk_ticket') {
    return (
      <EditCreateZendeskTicketTool
        intentWorkflowId={intentWorkflowId}
        onClose={onClose}
        tool={tool}
      />
    );
  } else if (tool?.tool_id === 'create_intercom_ticket') {
    return (
      <EditCreateIntercomTicketTool
        intentWorkflowId={intentWorkflowId}
        onClose={onClose}
        tool={tool}
      />
    );
  }

  return null;
};
