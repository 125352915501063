import { useMemo } from 'react';

import { MultiSelectFilter } from '@forethought-technologies/forethought-elements';
import { getSegmentedDataFilterOptions } from '../helpers';
interface SegmentedDataFilterProps {
  entries: (Record<string, string> | null | undefined)[] | undefined;
  onChange: (values: string[]) => void;
  values: string[];
}

const SegmentedDataFilter = ({
  entries,
  onChange,
  values,
}: SegmentedDataFilterProps) => {
  const options = useMemo(
    () => getSegmentedDataFilterOptions(entries || []),
    [entries],
  );

  if (options.length === 0) {
    return null;
  }

  return (
    <MultiSelectFilter
      onChange={onChange}
      options={options}
      placeholder='Filter by segment'
      value={values}
    />
  );
};

export default SegmentedDataFilter;
