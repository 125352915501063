import { useEffect, useMemo, useState } from 'react';
import { MRT_Row, MRT_RowData } from 'material-react-table';
import Box from '@mui/material/Box';

import EmptyTabState from '../components/EmptyTabState';
import SegmentedDataFilter from '../components/SegmentedDataFilter';
import {
  filterByBrand,
  filterBySegmentedData,
  getColumnsWithVisibility,
  handleBrandColumn,
  handleSegmentColumns,
} from '../helpers';
import BrandFilter from './BrandFilter';
import { POLICY_TABLE_COLUMNS } from './constants';
import { toPolicyTableData } from './helpers';
import { PolicyRecommendationTableData } from './types';
import DashboardTable from 'src/components/dashboard-table';
import { DashboardTableProps } from 'src/components/dashboard-table/DashboardTable';
import { PolicyRecommendation } from 'src/types/DiscoverTypes';

interface DiscoverPolicyRecommendationTableProps {
  data?: PolicyRecommendation[];
  hasBrands?: boolean;
  isError: boolean;
  isSegmented: boolean;
  loading: boolean;
  onMouseEnter: (
    row: MRT_Row<PolicyRecommendationTableData & MRT_RowData>,
  ) => void;
  onScroll: (scroll: number) => void;
  onSelect: (row: MRT_Row<PolicyRecommendationTableData>) => void;
}

const DiscoverPolicyRecommendationTable = ({
  data,
  hasBrands,
  isError,
  isSegmented,
  loading,
  onMouseEnter,
  onScroll,
  onSelect,
}: DiscoverPolicyRecommendationTableProps) => {
  const [columnVisibility, setColumnVisibility] = useState<
    Record<string, boolean>
  >({});
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState<string>('policy');
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [selectedSegmentedData, setSelectedSegmentedData] = useState<string[]>(
    [],
  );
  const rows = useMemo(() => {
    if (!searchText) {
      return data
        ?.filter(
          row =>
            filterByBrand(selectedBrands, row.api_data_filter) &&
            filterBySegmentedData(selectedSegmentedData, row),
        )
        .map(toPolicyTableData);
    }
    return data
      ?.filter(row =>
        searchType === 'policy'
          ? row.policy_description
              .toLowerCase()
              .includes(searchText.toLowerCase())
          : row.topic.topic_name
              .toLowerCase()
              .includes(searchText.toLowerCase()),
      )
      .filter(
        row =>
          filterByBrand(selectedBrands, row.api_data_filter) &&
          filterBySegmentedData(selectedSegmentedData, row),
      )
      .map(toPolicyTableData);
  }, [searchText, data, searchType, selectedBrands, selectedSegmentedData]);

  useEffect(() => {
    const segments = getColumnsWithVisibility<PolicyRecommendation>(
      data,
      isSegmented,
    );
    setColumnVisibility(segments || {});
  }, [data, isSegmented]);

  const columns = useMemo(() => {
    if (data === undefined) {
      return [];
    }
    if (isSegmented) {
      return handleSegmentColumns<PolicyRecommendationTableData>({
        columns: POLICY_TABLE_COLUMNS.filter(
          item => item.key !== 'api_data_filter',
        ),
        data: rows,
      });
    }

    return handleBrandColumn<PolicyRecommendationTableData>({
      columns: POLICY_TABLE_COLUMNS,
      data: data?.map(toPolicyTableData),
      hasBrands,
    });
  }, [hasBrands, data, rows, isSegmented]);

  return (
    <DashboardTable<PolicyRecommendationTableData>
      columns={
        columns as DashboardTableProps<PolicyRecommendationTableData>['columns']
      }
      data={rows || []}
      FilterComponent={
        <Box display='flex' gap={1}>
          {isSegmented && (
            <SegmentedDataFilter
              entries={data?.map(row => row.segmentation_api_data_filter) || []}
              onChange={setSelectedSegmentedData}
              values={selectedSegmentedData}
            />
          )}
          {hasBrands && (
            <BrandFilter
              data={data || []}
              onChange={setSelectedBrands}
              selectedTab='policies'
              value={selectedBrands}
            />
          )}
        </Box>
      }
      hasNextPage={false}
      initialSorting={[{ desc: true, id: 'cost' }]}
      isError={isError}
      isLoading={loading}
      onSearch={setSearchText}
      onSearchTypeChange={setSearchType}
      searchOptions={[
        {
          text: 'Policy',
          value: 'policy',
        },
        {
          text: 'Topic',
          value: 'topic',
        },
      ]}
      searchText={searchText}
      searchType={searchType}
      stickyHeaderHeight={0}
      tableOptions={{
        initialState: {
          columnPinning: {
            left: ['topic_name'],
          },
        },
        muiTableBodyRowProps: ({ row }) => ({
          onClick: () => onSelect(row),
          onMouseEnter: () => onMouseEnter(row),
          sx: {
            cursor: 'pointer',
          },
        }),
        muiTableContainerProps: () => ({
          onScroll: event => onScroll(event.currentTarget.scrollTop),
          sx: {
            height: '100%',
            minHeight: rows?.length ? undefined : 'calc(100vh - 200px)',
          },
        }),
        muiTablePaperProps: {
          sx: {
            boxShadow: 'none',
            display: 'grid',
          },
        },
        onColumnVisibilityChange: setColumnVisibility,
        renderEmptyRowsFallback: () => <EmptyTabState type='recommendation' />,
        state: {
          columnVisibility,
        },
      }}
    />
  );
};

export default DiscoverPolicyRecommendationTable;
