import { useCallback, useMemo, useState } from 'react';
import { DashStyleValue } from 'highcharts';
import { useTheme } from '@mui/material';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import { dateRangeToTimestamp } from '../../solve-insights/helpers';
import { OverviewOvertimePeriod } from '../../solve-insights/types';
import { useGetFilterForBackend } from '../hook/useGetFilterForBackend';
import { renderLineGraphUtils } from 'src/components/solve-insights-charts/line-graph/lineGraphUtils';
import { NA } from 'src/constants/solve';
import { useGetAgentsPerformanceChartQuery } from 'src/services/insights';
import {
  LineGraphLabelType,
  OverviewOvertimeGraphValueData,
} from 'src/services/insights/types';

interface ComparisonGraphProps {
  dateRange: DateRange;
  multiSelected: string[];
  periodicalFilter: OverviewOvertimePeriod;
}

const defaultVisibleSeries = [
  'Ticket assigned',
  'Solved ticket',
  'Time to first response',
] as const satisfies LineGraphLabelType[];

const ComparisonGraph = ({
  dateRange,
  multiSelected,
  periodicalFilter,
}: ComparisonGraphProps) => {
  const { palette } = useTheme();
  const filterForBackendQuery = useGetFilterForBackend(multiSelected);

  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);
  const { data, isFetching, isLoading } = useGetAgentsPerformanceChartQuery({
    end: backendReadyTimestamps.end_timestamp,
    period: periodicalFilter,
    start: backendReadyTimestamps.start_timestamp,
    ...filterForBackendQuery,
  });

  const [visibleSeries, setVisibleSeries] =
    useState<LineGraphLabelType[]>(defaultVisibleSeries);

  const handleToggleVisibility = useCallback((label: LineGraphLabelType) => {
    setVisibleSeries(prev =>
      prev.includes(label)
        ? prev.filter(existingLabel => existingLabel !== label)
        : [...prev, label],
    );
  }, []);

  const transformedData = useMemo(() => {
    if (!data || !data.line || data.line.x_axis.length === 0) {
      return undefined;
    }

    // list_time will be used to format on tooltip render
    const mapTooltipValues = (
      value: OverviewOvertimeGraphValueData,
      label: string,
    ) =>
      value.tooltip_values.map(item => ({
        ...item,
        type:
          label === 'Time to first response' || label === 'Full resolution time'
            ? 'list_time'
            : item.type,
      }));

    const mapValues = (
      value: OverviewOvertimeGraphValueData,
      index: number,
    ) => {
      const isComparisonData = index % 2 !== 0;
      const previousValue = data.line.values[index - 1];
      const nextValue = data.line.values[index + 1];

      const tooltipValues = isComparisonData
        ? [
            {
              category: previousValue.category,
              extended_labels: previousValue.extended_labels,
              index: index - 1,
              tooltip_values: mapTooltipValues(previousValue, value.label),
            },
            {
              category: value.category,
              extended_labels: value.extended_labels,
              index,
              tooltip_values: mapTooltipValues(value, value.label),
            },
          ]
        : [
            {
              category: value.category,
              extended_labels: value.extended_labels,
              index,
              tooltip_values: mapTooltipValues(value, value.label),
            },
            {
              category: nextValue.category,
              extended_labels: nextValue.extended_labels,
              index: index + 1,
              tooltip_values: mapTooltipValues(nextValue, value.label),
            },
          ];

      return {
        ...value,
        dashStyle: isComparisonData
          ? ('ShortDash' as DashStyleValue)
          : undefined,
        linkedTo: isComparisonData ? ':previous' : undefined,
        showInLegend: !isComparisonData,
        tooltip_values: tooltipValues,
        visible: visibleSeries.includes(value.label),
      };
    };

    return {
      ...data,
      line: {
        ...data.line,
        values: data.line.values.map(mapValues),
      },
    };
  }, [data, visibleSeries]);

  if (isLoading || isFetching) {
    return <Skeleton height='300px' width='100%' />;
  }

  if (!transformedData) {
    return (
      <Typography color={palette.colors.grey[600]} variant='font14'>
        {NA}
      </Typography>
    );
  }

  return renderLineGraphUtils(
    palette,
    transformedData,
    'agent_main',
    handleToggleVisibility,
  );
};

export default ComparisonGraph;
