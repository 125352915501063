import { useCallback, useEffect, useMemo, useState } from 'react';

import { DEBOUNCE_FILTER_DELAY } from '../constants';
import debounce from 'lodash/fp/debounce';
import { useStateParams } from 'src/hooks/hooks';
import { listDeserialize, listSerialize } from 'src/utils/discover/helpers';

export const useGetMultiSelect = (
  initialState: string[],
  paramsName: string,
) => {
  const [multiSelected, setMultiSelected] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState,
    paramsName,
    serialize: listSerialize,
  });

  const [localMultiSelected, setLocalMultiSelected] =
    useState<string[]>(multiSelected);

  const debouncedSetMultiSelected = useMemo(
    () =>
      debounce(DEBOUNCE_FILTER_DELAY, (selected: string[]) =>
        setMultiSelected(selected),
      ),
    [setMultiSelected],
  );

  const handleMultiSelectChange = useCallback(
    (selected: string[]) => {
      setLocalMultiSelected(selected);
      debouncedSetMultiSelected(selected);
    },
    [debouncedSetMultiSelected],
  );

  useEffect(() => {
    setLocalMultiSelected(multiSelected);
  }, [multiSelected]);

  return {
    handleMultiSelectChange,
    localMultiSelected,
    multiSelected,
    setLocalMultiSelected,
    setMultiSelected,
  };
};
