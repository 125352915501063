import { PropsWithChildren } from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { formatDateForTicketCard } from 'src/components/dashboard-pages/discover-topic-detail-page/helpers';

const TicketTimeStamp = ({
  children,
  dateCreated,
}: PropsWithChildren<{ dateCreated: string | number }>) => {
  const { palette } = useTheme();

  return (
    <Box
      alignItems='center'
      borderRadius={1}
      columnGap={1}
      display='flex'
      px='13px'
      py='8px'
      sx={{
        backgroundColor: 'rgba(242, 244, 247, 0.50)',
        border: `1px solid ${palette.colors.grey[100]}`,
      }}
    >
      {children}
      <Typography color={palette.colors.grey[700]} variant='font14'>
        {formatDateForTicketCard(new Date(dateCreated).getTime())}
      </Typography>
    </Box>
  );
};

export default TicketTimeStamp;
