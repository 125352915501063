import { useCallback, useMemo, useState } from 'react';
import { MRT_TableOptions } from 'material-react-table';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  DateRangeFilterButton,
  InfiniteTable,
  MultiSelectFilter,
  Order,
  SearchWithDropdown,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import InsightDownloadCsv from '../../solve-insights/common/InsightDownloadCsv';
import {
  dateRangeToTimestamp,
  sortDirectionToInsightSortDirection,
} from '../../solve-insights/helpers';
import { useGetSearchQuery } from '../../solve-insights/hooks/useGetSearchQuery';
import { InsightSortDirection } from '../../solve-insights/types';
import {
  AGENT_TICKET_SEARCH_OPTIONS,
  initialInsightParams,
} from '../constants';
import { useGetAgentOptionsFilter } from '../hook/useGetAgentOptionsFilter';
import { useGetFilterForBackend } from '../hook/useGetFilterForBackend';
import { InsightAgentTicketsSortColumns, SearchQueryMode } from '../types';
import { buildTableColumns } from './helpers';
import filterIcon from 'src/assets/images/filter-analytic-icon.svg';
import { useStateParams } from 'src/hooks/hooks';
import { useGetAgentTicketsTableQuery } from 'src/services/insights';
import { InsightAgentTicket } from 'src/services/insights/types';
import {
  dateRangeToTimeFilter,
  genericSerializeAndDeserialize,
} from 'src/utils/discover/helpers';
import { ExportableTableType } from 'src/utils/enums';
import {
  datePickerRangeOptionsRevamp,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';

interface AgentTicketsTableProps {
  dateRange: DateRange;
  handleFilterReset: () => void;
  handleMultiSelectChange: (state: string[]) => void;
  localMultiSelected: string[];
  multiSelected: string[];
  scrollToTop: (yOffset: number, smooth?: boolean) => void;
  setDateRange: (
    dateRange: DateRange,
    batchUpdater?: () => URLSearchParams,
  ) => URLSearchParams;
}

const AgentTicketsTable = ({
  dateRange,
  handleFilterReset,
  handleMultiSelectChange,
  localMultiSelected,
  multiSelected,
  scrollToTop,
  setDateRange,
}: AgentTicketsTableProps) => {
  const { palette } = useTheme();
  const { filters } = useGetAgentOptionsFilter();

  const [page, setPage] = useState(1);

  // state params
  const [sortDirection, setSortDirection] =
    useStateParams<InsightSortDirection>({
      deserialize: (param: string) => (param === 'ASC' ? 'ASC' : 'DESC'),
      initialState: 'DESC',
      paramsName: 'agents_sort_direction',
      serialize: String,
    });
  const [searchQuery, setSearchQuery] = useStateParams<string>({
    deserialize: (str: string) => (str === 'null' ? '' : str),
    initialState: '',
    paramsName: 'agents_search',
    serialize: genericSerializeAndDeserialize,
  });
  const [searchQueryMode, setSearchQueryMode] = useStateParams<SearchQueryMode>(
    {
      deserialize: genericSerializeAndDeserialize as (
        str: string,
      ) => SearchQueryMode,
      initialState: 'agent_name',
      paramsName: 'agents_search_mode',
      serialize: genericSerializeAndDeserialize,
    },
  );
  const [sortColumn, setSortColumn] =
    useStateParams<InsightAgentTicketsSortColumns>({
      deserialize: (param: string) => param as InsightAgentTicketsSortColumns,
      initialState: initialInsightParams.agentTicketColumnSort,
      paramsName: 'agents_sort_column',
      serialize: String,
    });

  const { setUiQuery, uiQuery } = useGetSearchQuery({
    searchQuery,
    setSearchQuery,
  });

  // end get conversation details once clicked

  const columns = useMemo(() => buildTableColumns(palette), [palette]);
  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);

  const handleResetAllFilter = useCallback(() => {
    // Reset the filter state
    handleFilterReset?.();

    // Clear the UI query
    setUiQuery('');

    // Use a batch updater to avoid conflicting state updates
    setSearchQueryMode('agent_name', () =>
      setSortColumn(initialInsightParams.agentTicketColumnSort),
    );
  }, [handleFilterReset, setUiQuery, setSearchQueryMode, setSortColumn]);

  const filtersForBackend = useGetFilterForBackend(multiSelected);

  const tableRequestParams = useMemo(
    () => ({
      agent_id: searchQueryMode === 'agent_id' ? searchQuery : '',
      agent_name: searchQueryMode === 'agent_name' ? searchQuery : '',
      data_export_type: ExportableTableType.INSIGHT_AGENT_TICKETS_TABLE,
      end: backendReadyTimestamps.end_timestamp,
      page,
      sort_column: sortColumn,
      sort_direction: sortDirection,
      start: backendReadyTimestamps.start_timestamp,
      ...filtersForBackend,
    }),
    [
      searchQueryMode,
      searchQuery,
      sortColumn,
      sortDirection,
      backendReadyTimestamps,
      page,
      filtersForBackend,
    ],
  );

  const {
    data: tableData,
    isError: isTableError,
    isFetching,
    isLoading,
  } = useGetAgentTicketsTableQuery({
    ...tableRequestParams,
  });

  const rows = useMemo(() => {
    if (!tableData?.data) {
      return [];
    }
    return tableData.data;
  }, [tableData]);

  const showLoadingSkeleton = isLoading || (page === 1 && isFetching);

  const timeFilter = dateRangeToTimeFilter(dateRange);
  const initialTimeFilter = dateRangeToTimeFilter(last30DaysTimeRange);

  const areFiltersUsed = Boolean(
    uiQuery.length ||
      timeFilter.key !== initialTimeFilter.key ||
      localMultiSelected.length,
  );

  const getAgentsTableDownloadUrl = useCallback(() => {
    const url = new URL(`${API_URL}data-export`);
    return url.href;
  }, []);

  const handleSortCallback = useCallback(
    (property: keyof InsightAgentTicket, order: Order) => {
      setPage(1);
      setSortDirection(sortDirectionToInsightSortDirection(order));
      setSortColumn(property as InsightAgentTicketsSortColumns);
      scrollToTop(72);
    },
    [scrollToTop, setSortColumn, setSortDirection],
  );

  const initialSorting = useMemo(
    () => [
      {
        desc: sortDirection === 'DESC',
        id: sortColumn,
      },
    ],
    [sortColumn, sortDirection],
  );

  const tableDataPage = tableData?.metadata.page ?? 0;

  const handleLoadMore = useCallback(() => {
    if (tableDataPage === page) {
      const nextPage = page + 1;
      setPage(nextPage);
    }
  }, [page, tableDataPage]);

  const tableOptions = useMemo<MRT_TableOptions<InsightAgentTicket>>(
    () => ({
      columns,
      data: rows,
      enableColumnActions: false,
      enableColumnFilters: false,
      enableExpanding: false,
      enableFullScreenToggle: false,
      enableGlobalFilter: false,
      enableRowActions: false,
      initialState: {
        columnPinning: {
          left: ['agent_name'],
        },
      },
      renderTopToolbarCustomActions: () => (
        <Box
          alignItems='center'
          display='flex'
          gap='8px'
          height='40px'
          width='100%'
        >
          <Box width='360px'>
            <SearchWithDropdown
              aria-label='Agent filter'
              fullWidth
              onChange={e => setUiQuery(e.target.value)}
              onClear={() => setUiQuery('')}
              options={AGENT_TICKET_SEARCH_OPTIONS}
              placeholder={`Enter ${AGENT_TICKET_SEARCH_OPTIONS.find(
                option => option.value === searchQueryMode,
              )?.text.toLowerCase()}`}
              selectChange={value => {
                setSearchQueryMode(value as SearchQueryMode);
                setUiQuery('');
              }}
              selectedOption={searchQueryMode}
              value={uiQuery}
            />
          </Box>
          <DateRangeFilterButton
            explicitLabel={true}
            initialValue={last30DaysTimeRange}
            onChange={value => setDateRange(value)}
            options={datePickerRangeOptionsRevamp}
            value={dateRange}
          />
          <MultiSelectFilter
            icon={<img src={filterIcon} />}
            maxHeight={400}
            onChange={handleMultiSelectChange}
            options={filters}
            placeholder='Filter by'
            value={localMultiSelected}
            variant='secondary'
          />
          {areFiltersUsed && (
            <Button
              onClick={handleResetAllFilter}
              size='medium'
              variant='ghost'
            >
              <Typography noWrap variant='font14Medium'>
                Reset filters
              </Typography>
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <InsightDownloadCsv
              filename='agents.csv'
              requestData={{ ...tableRequestParams }}
              url={getAgentsTableDownloadUrl()}
            />
          </Box>
        </Box>
      ),
    }),
    [
      columns,
      rows,
      searchQueryMode,
      uiQuery,
      areFiltersUsed,
      getAgentsTableDownloadUrl,
      setUiQuery,
      setSearchQueryMode,
      setDateRange,
      tableRequestParams,
      handleResetAllFilter,
      dateRange,
      filters,
      localMultiSelected,
      handleMultiSelectChange,
    ],
  );

  return (
    <Box maxWidth='100%' paddingTop='44px'>
      <InfiniteTable
        hasNextPage={typeof tableData?.metadata.next_page === 'number'}
        initialSorting={initialSorting}
        isError={isTableError}
        isLoadingFirstPage={showLoadingSkeleton}
        isLoadingNextPage={isFetching}
        onLoadMore={handleLoadMore}
        onSortCallback={handleSortCallback}
        stickyHeaderHeight={300}
        tableOptions={tableOptions}
      />
    </Box>
  );
};
export default AgentTicketsTable;
