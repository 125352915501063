import React from 'react';
import { Box, styled } from '@mui/material';

import {
  Badge,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { formatTimestamp } from '../helpers';
import { ArticleCardStep } from './ArticleCardStep';
import { ButtonSelectionStep } from './ButtonSelectionStep';
import { ChatHandoffStatus } from './ChatHandoffStatus';
import { CsatSubmissionStep } from './CsatSubmissionStep';
import { EmbedStep } from './EmbedStep';
import { ExecutionError } from './ExecutionError';
import { ExecutionIndicator } from './ExecutionIndicator';
import { GotoWorkflow } from './GotoWorkflow';
import { ImageStep } from './ImageStep';
import { IndicatorStep } from './IndicatorStep';
import { IntentTrigger } from './IntentTrigger';
import { QuickFeedbackStep } from './QuickFeedbackStep';
import { RelatedArticles } from './RelatedArticles';
import { TextStep } from './TextStep';
import {
  ArticleCardTranscriptComponent,
  ButtonSelectionTranscriptComponent,
  CsatSubmissionTranscriptComponent,
  DynamicCardSelectionTranscriptComponent,
  DynamicCardTranscriptComponent,
  EmbedTranscriptComponent,
  ExecutionTranscriptComponent,
  GotoWorkflowTranscriptComponent,
  ImageTranscriptComponent,
  IndicatorTranscriptComponent,
  IntentTriggeredTranscriptComponent,
  MessageBubblePosition,
  QuickFeedbackTranscriptComponent,
  RelatedArticlesTranscriptComponent,
  TextTranscriptComponent,
  TranscriptComponent,
} from './types';
import {
  useGetComponentTopPadding,
  useGetMessageBubblePosition,
} from './utils';
import { ApiDynamicCardStep } from 'src/pages/workflow-builder-preview/api-preview/ApiDynamicCardStep';

const mapTranscriptPropsToComponent = ({
  messageBubblePosition,
  nextTranscriptComponent,
  transcriptComponent,
}: {
  messageBubblePosition: MessageBubblePosition;
  nextTranscriptComponent?: TranscriptComponent;
  transcriptComponent: TranscriptComponent;
}) => {
  const messageType = transcriptComponent.message_type;
  switch (messageType) {
    case 'text':
      return (
        <TextStep
          messageBubblePosition={messageBubblePosition}
          transcriptComponent={transcriptComponent as TextTranscriptComponent}
        />
      );
    case 'intent_trigger':
      return (
        <IntentTrigger
          transcriptComponent={
            transcriptComponent as IntentTriggeredTranscriptComponent
          }
        />
      );
    case 'execution':
      return (
        <ExecutionIndicator
          message={
            (transcriptComponent as ExecutionTranscriptComponent).message
          }
          timestamp={formatTimestamp(transcriptComponent.timestamp + 'Z')}
        />
      );
    case 'indicator':
      return (
        <IndicatorStep
          message={
            (transcriptComponent as IndicatorTranscriptComponent).message
          }
        />
      );
    case 'image':
      return (
        <ImageStep
          transcriptComponent={transcriptComponent as ImageTranscriptComponent}
        />
      );
    case 'embed':
      return (
        <EmbedStep
          transcriptComponent={transcriptComponent as EmbedTranscriptComponent}
        />
      );
    case 'button_selection':
      return (
        <ButtonSelectionStep
          transcriptComponent={
            transcriptComponent as ButtonSelectionTranscriptComponent
          }
        />
      );
    case 'article_card':
      return (
        <ArticleCardStep
          transcriptComponent={
            transcriptComponent as ArticleCardTranscriptComponent
          }
        />
      );
    case 'csat_submission':
      return (
        <CsatSubmissionStep
          transcriptComponent={
            transcriptComponent as CsatSubmissionTranscriptComponent
          }
        />
      );
    case 'goto_workflow':
      return (
        <GotoWorkflow
          transcriptComponent={
            transcriptComponent as GotoWorkflowTranscriptComponent
          }
        />
      );
    case 'related_articles':
      return (
        <RelatedArticles
          transcriptComponent={
            transcriptComponent as RelatedArticlesTranscriptComponent
          }
        />
      );
    case 'quick_feedback':
      return (
        <QuickFeedbackStep
          transcriptComponent={
            transcriptComponent as QuickFeedbackTranscriptComponent
          }
        />
      );
    case 'chat_handoff_status_message':
      return (
        <ChatHandoffStatus
          transcriptComponent={
            transcriptComponent as ExecutionTranscriptComponent
          }
        />
      );
    case 'dynamic_card_selection':
      return <></>;
    case 'dynamic_card':
      if (nextTranscriptComponent?.message_type === 'dynamic_card_selection') {
        return (
          <ApiDynamicCardStep
            isTheLatestStep
            isTranscript
            selectedCardValue={
              (
                nextTranscriptComponent as DynamicCardSelectionTranscriptComponent
              ).fields?.value
            }
            step={transcriptComponent as DynamicCardTranscriptComponent}
          />
        );
      }
      return (
        <ApiDynamicCardStep
          isTheLatestStep
          isTranscript
          step={transcriptComponent as DynamicCardTranscriptComponent}
        />
      );
    case 'execution_error':
      return <ExecutionError transcriptComponent={transcriptComponent} />;
    case 'sentiment_analysis':
      return (
        <ExecutionIndicator
          message={
            <>
              User response identified as{' '}
              <Badge
                label={<Typography variant='font11'>Negative</Typography>}
                variant='error'
              />
            </>
          }
          timestamp={formatTimestamp(transcriptComponent.timestamp + 'Z')}
        />
      );
    default:
      return (
        <Box sx={{ overflowWrap: 'anywhere' }}>
          {JSON.stringify(transcriptComponent)}
        </Box>
      );
  }
};

const GenericTranscriptComponent = ({
  messageBubblePosition,
  nextTranscriptComponent,
  topPadding,
  transcriptComponent,
}: {
  messageBubblePosition: MessageBubblePosition;
  nextTranscriptComponent?: TranscriptComponent;
  topPadding: string;
  transcriptComponent: TranscriptComponent;
}) => {
  const containerAlignment =
    transcriptComponent.message_type === 'quick_feedback'
      ? 'flex-start'
      : transcriptComponent.component_type === 'input'
      ? 'flex-end'
      : 'flex-start';

  const tooltipAlignment =
    transcriptComponent.component_type === 'input'
      ? 'flex-end'
      : transcriptComponent.component_type === 'execution'
      ? 'flex-center'
      : 'flex-start';

  const tooltipWidth =
    transcriptComponent.component_type === 'execution' ||
    transcriptComponent.message_type === 'quick_feedback'
      ? '100%'
      : null;

  const toolTipMaxWidth =
    transcriptComponent.component_type === 'execution' ||
    transcriptComponent.message_type === 'quick_feedback'
      ? '100%'
      : 'calc(100% - 30px)';

  return (
    <Box
      sx={{
        alignItems: 'flex-end',
        display: 'flex',
        gap: '6px',
        justifyContent: containerAlignment,
        paddingTop: topPadding,
      }}
    >
      <Tooltip
        placement='top'
        sx={{
          alignSelf: tooltipAlignment,
          maxWidth: toolTipMaxWidth,
          width: tooltipWidth,
        }}
        tooltipContent={formatTimestamp(transcriptComponent.timestamp + 'Z')}
      >
        {mapTranscriptPropsToComponent({
          messageBubblePosition: messageBubblePosition,
          nextTranscriptComponent: nextTranscriptComponent,
          transcriptComponent: transcriptComponent,
        })}
      </Tooltip>
    </Box>
  );
};

export const RevampConversationTranscript = ({
  transcriptComponents,
}: {
  transcriptComponents: TranscriptComponent[];
}) => {
  const getMessageBubblePosition =
    useGetMessageBubblePosition(transcriptComponents);
  const getComponentTopPadding =
    useGetComponentTopPadding(transcriptComponents);

  // indicates the start of the chat, shares the same timestamp as the first transcript component
  const chatStartedComp: ExecutionTranscriptComponent | null =
    transcriptComponents.length == 0
      ? null
      : {
          component_type: 'execution',
          message: 'Chat started',
          message_type: 'execution',
          timestamp: transcriptComponents[0].timestamp,
        };

  return (
    <Box display='flex' flexDirection='column' gap='10px'>
      <Typography variant='font11'>Transcript</Typography>
      <TranscriptContainer>
        {chatStartedComp && (
          <GenericTranscriptComponent
            messageBubblePosition='lone'
            topPadding='0px'
            transcriptComponent={chatStartedComp}
          />
        )}

        {transcriptComponents.map((comp, index) => {
          const messageBubblePosition = getMessageBubblePosition(index);
          const topPadding = getComponentTopPadding(index);
          const quickFeedbackComponent =
            comp.message_type === 'related_articles' ||
            comp.message_type === 'article_card'
              ? (
                  comp as
                    | RelatedArticlesTranscriptComponent
                    | ArticleCardTranscriptComponent
                ).quick_feedback
              : undefined;

          return (
            <React.Fragment key={index}>
              <GenericTranscriptComponent
                messageBubblePosition={messageBubblePosition}
                nextTranscriptComponent={transcriptComponents[index + 1]}
                topPadding={topPadding}
                transcriptComponent={comp}
              />
              {quickFeedbackComponent && (
                <GenericTranscriptComponent
                  messageBubblePosition={messageBubblePosition}
                  topPadding={topPadding}
                  transcriptComponent={quickFeedbackComponent}
                />
              )}
            </React.Fragment>
          );
        })}
      </TranscriptContainer>
    </Box>
  );
};

export const TranscriptContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.colors.slate[200]};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
