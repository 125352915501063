import { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { IconButton } from '@forethought-technologies/forethought-elements';

export const CollapsibleBox = ({ body }: { body: string }) => {
  const { palette } = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      display='grid'
      gap={2}
      gridTemplateColumns='auto 32px'
      p={2}
      sx={{
        backgroundColor: isExpanded ? '#F2F8FF' : undefined,
      }}
    >
      <Box
        dangerouslySetInnerHTML={{ __html: body }}
        sx={{
          img: { maxWidth: '100%' },
          maxHeight: isExpanded ? undefined : '100px',
          minHeight: isExpanded ? '100px' : undefined,
          overflow: 'hidden',
        }}
      />
      <Box display='flex'>
        <IconButton
          aria-label={isExpanded ? 'Collapse content' : 'Expand content'}
          onClick={handleToggle}
          variant='ghost'
        >
          {isExpanded ? (
            <IconChevronUp color={palette.colors.grey[800]} size={24} />
          ) : (
            <IconChevronDown color={palette.colors.grey[800]} size={24} />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};
