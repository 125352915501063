import { Box, Divider, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import {
  Drawer,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { CollapsibleBox } from './CollapsibleBox';
import IndexInfo from './IndexInfo';
import IndexingNotesSection from './IndexingNotesSection';
import { SummaryCard } from './styles';
import SummaryDetailItem from './SummaryDetailItem';
import { StandardizedDocType } from 'src/services/apiInterfaces';
import { useGetDocumentDetailsQuery } from 'src/services/dashboard-api/dashboardApi';

interface DocumentDetailsDrawerProps {
  onClose: () => void;
  open: boolean;
  standardizedDocumentId: string;
  standardizedDocumentType: StandardizedDocType;
}

const DocumentDetailsDrawer = ({
  onClose,
  open,
  standardizedDocumentId,
  standardizedDocumentType,
}: DocumentDetailsDrawerProps) => {
  const theme = useTheme();

  // Use RTK Query hook
  const {
    data: documentDetails,
    isError,
    isFetching,
    isLoading,
  } = useGetDocumentDetailsQuery(
    { standardizedDocumentId, standardizedDocumentType },
    { skip: !open }, // Skip query if the drawer is not open
  );

  return (
    <Drawer isOpen={open} onClose={onClose}>
      {isError && (
        <Box
          alignItems='center'
          display='flex'
          height='100%'
          justifyContent='center'
        >
          <Typography color={theme.palette.colors.grey[700]}>
            Unable to fetch document details
          </Typography>
        </Box>
      )}
      {isLoading || isFetching ? (
        <Box
          alignItems='center'
          display='flex'
          height='100%'
          justifyContent='center'
        >
          <CircularProgress />
        </Box>
      ) : (
        documentDetails && (
          <Box display='flex' flexDirection='column' gap={2} p={4}>
            <Typography variant='font24'>Index Details</Typography>
            <IndexInfo documentDetails={documentDetails} />

            <Box display='flex' flexDirection='column'>
              <Box mb={1}>
                <Typography variant='font11'>Summary</Typography>
              </Box>
              <SummaryCard>
                <Box display='flex' flexDirection='column' gap={2}>
                  <SummaryDetailItem
                    title='Source doc'
                    value={documentDetails.document_type}
                  />
                  <SummaryDetailItem
                    title='Title'
                    value={documentDetails.title}
                  />
                  <Box display='flex' flexDirection='column' gap={1}>
                    <Typography
                      color={theme.palette.colors.grey[600]}
                      variant='font12'
                    >
                      Body:
                    </Typography>
                    <CollapsibleBox body={documentDetails.html_body} />
                  </Box>
                  <Divider />
                  <SummaryDetailItem
                    title='Status'
                    value={documentDetails.public ? 'Public' : 'Private'}
                  />
                  <SummaryDetailItem
                    title='Integration'
                    value={documentDetails.integration}
                  />
                  <IndexingNotesSection documentDetails={documentDetails} />
                  <SummaryDetailItem
                    title='Not searchable reason'
                    value={documentDetails.not_searchable_reason}
                  />
                </Box>
              </SummaryCard>
            </Box>
          </Box>
        )
      )}
    </Drawer>
  );
};

export default DocumentDetailsDrawer;
