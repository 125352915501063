import { useSelector } from 'react-redux';
import { Handle, NodeProps, Position } from 'reactflow';
import { Box, styled, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';

import {
  Chip,
  elevations,
  Typography,
} from '@forethought-technologies/forethought-elements';
import AlertTooltip from '../AlertTooltip';
import ValidationErrorModal from '../validation-error-modal/ValidationErrorModal';
import { ZendeskTicketUpdateFields } from './zendesk/ZendeskTicketUpdateFields';
import { Helpdesk } from 'src/components/app/types';
import { NODE_WIDTH } from 'src/components/forethought-flow/constants';
import {
  EmailWorkflowValidationError,
  TicketUpdateStepFields,
} from 'src/pages/workflow-builder-edit/types';
import { selectEmailTargetStepId } from 'src/slices/email-workflow/emailWorkflowSlice';

interface TerminalNodeProps {
  automatedValue: string;
  helpdesk: Helpdesk;
  stepErrors?: EmailWorkflowValidationError[];
  stepFields?: TicketUpdateStepFields;
}

export default function TerminalNode({
  data,
  selected,
  ...rest
}: NodeProps<TerminalNodeProps>) {
  const { palette } = useTheme();
  const { stepErrors } = data;
  const { id } = rest;
  const targetStepId = useSelector(selectEmailTargetStepId);

  const automatedValue = data.automatedValue;
  const title = `Path completed with ${
    data.helpdesk === 'salesforce' ? 'custom field' : 'tags'
  }:`;

  return (
    <>
      <Positioning>
        <Container isHighlighted={targetStepId === id} selected={selected}>
          <Box
            bgcolor={palette.colors.grey[100]}
            borderBottom={`1px solid ${palette.colors.grey[300]}`}
            display='flex'
            justifyContent='center'
            p={1}
            textAlign='center'
          >
            <Typography color={palette.colors.grey[700]} variant='font11'>
              {title}
            </Typography>
          </Box>
          <Box display='flex' justifyContent='center' p={1}>
            {stepErrors && stepErrors.length > 0 && (
              <Box position='absolute' right='12px'>
                <AlertTooltip
                  tooltipProps={{
                    tooltipContent: (
                      <ValidationErrorModal stepValidationErrors={stepErrors} />
                    ),
                  }}
                />
              </Box>
            )}
            {data.helpdesk === 'zendesk' ? (
              <ZendeskTicketUpdateFields
                automatedValue={automatedValue}
                stepFields={data.stepFields}
              />
            ) : (
              <Chip
                backgroundColor={palette.colors.grey[100]}
                fontSize={11}
                label={automatedValue}
                size='small'
                variant='filled'
              />
            )}
          </Box>
        </Container>
      </Positioning>
      <StyledHandle
        isConnectable={false}
        position={Position.Top}
        type='target'
      />
      <StyledHandle
        isConnectable={false}
        position={Position.Bottom}
        type='source'
      />
    </>
  );
}

const Positioning = styled(Box)`
  text-align: center;
  min-width: ${NODE_WIDTH - 40}px;
`;

const Container = styled(Box, {
  shouldForwardProp: prop => !['selected'].includes(prop as string),
})<{ isHighlighted: boolean; selected: boolean }>`
  background-color: ${({ isHighlighted, theme }) =>
    isHighlighted
      ? theme.palette.colors.blue[200]
      : theme.palette.colors.white};
  border: 1px solid
    ${props =>
      props.selected
        ? props.theme.palette.colors.purple[500]
        : alpha(props.theme.palette.colors.black, 0.3)};
  border-radius: 8px;
  box-shadow: ${elevations.z1};
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
  width: fit-content;
  max-width: ${NODE_WIDTH}px;
`;

const StyledHandle = styled(Handle)`
  visibility: hidden;
`;
