import { useCallback, useRef, useState } from 'react';
import type { XYCoord } from 'dnd-core';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { Box, styled, Typography, useTheme } from '@mui/material';
import { IconEqual, IconLink, IconTrash } from '@tabler/icons-react';

import {
  Button,
  Checkbox,
  Dialog,
  IconButton,
  TextField,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { isUrlValid } from 'src/components/app/helpers';
import { useIsFeatureFlagEnabled } from 'src/hooks/hooks';
import {
  selectWidgetConfiguration,
  swapManuallySelectedTopIntents,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';

interface DragItem {
  id: string;
  index: number;
  type: string;
}

export default function ManuallySelectedIntent({
  handleManualIntentButtonConfigurationChange,
  handleManualIntentChange,
  index,
  intentId,
  intentName,
}: {
  handleManualIntentButtonConfigurationChange: (
    intentId: string,
    href: string | null,
    callback: () => void,
  ) => void;
  handleManualIntentChange: (intents: string[]) => void;
  index: number;
  intentId: string;
  intentName: string;
}): JSX.Element {
  const [isInsertUrlDialogOpen, setIsInsertUrlDialogOpen] = useState(false);
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const isAddURLEnabled = useIsFeatureFlagEnabled(
    'intent_button_option_url_configuration_enabled',
  );

  const { manually_selected_top_intents: manuallySelectedTopIntents } =
    widgetConfiguration;

  const ref = useRef<HTMLDivElement>(null);

  const move = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      dispatch(swapManuallySelectedTopIntents({ dragIndex, hoverIndex }));
    },
    [dispatch],
  );

  const save = useCallback(() => {
    handleManualIntentChange(manuallySelectedTopIntents);
  }, [handleManualIntentChange, manuallySelectedTopIntents]);

  const [{ handlerId }, drop] = useDrop({
    accept: 'intent',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop: save,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      move(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => {
      return { id: intentId, index };
    },
    type: 'intent',
  });

  drag(drop(ref));

  const isSelected = manuallySelectedTopIntents.includes(intentId);

  const IntentCheckbox = () => (
    <Checkbox
      checked={isSelected}
      label={intentName}
      onChange={e => {
        handleManualIntentChange(
          e.target.checked
            ? [...manuallySelectedTopIntents, intentId]
            : manuallySelectedTopIntents.filter(id => id !== intentId),
        );
      }}
    />
  );

  const existingIntentButtonConfigurationUrl =
    widgetConfiguration.manually_selected_intent_button_configuration[intentId]
      ?.href;

  const IntentRightSection = ({
    isDraggable = false,
  }: {
    isDraggable?: boolean;
  }) => (
    <Box alignItems='center' display='flex' gap={0.5}>
      {existingIntentButtonConfigurationUrl ? (
        <Tooltip tooltipContent='Edit or remove URL'>
          <Button
            onClick={() => setIsInsertUrlDialogOpen(true)}
            variant='ghost'
          >
            <IconLink size={20} style={{ marginRight: '4px' }} />
            Edit URL
          </Button>
        </Tooltip>
      ) : (
        <Tooltip tooltipContent='Insert url'>
          <IconButton
            aria-label='Add url'
            onClick={() => setIsInsertUrlDialogOpen(true)}
            variant='ghost'
          >
            <IconLink size={20} />
          </IconButton>
        </Tooltip>
      )}

      {isDraggable && <DragIcon color={palette.colors.grey[500]} />}
      <InsertUrlDialog
        defaultUrl={existingIntentButtonConfigurationUrl}
        isOpen={isInsertUrlDialogOpen}
        onAccept={(href, callback) =>
          handleManualIntentButtonConfigurationChange(intentId, href, callback)
        }
        onClose={() => setIsInsertUrlDialogOpen(false)}
      />
    </Box>
  );

  return manuallySelectedTopIntents.length > 1 && isSelected ? (
    // Selected, draggable intent:
    <IntentContainer
      data-handler-id={handlerId}
      doesConfigurationUrlExist={Boolean(existingIntentButtonConfigurationUrl)}
      isDragging={isDragging}
      ref={ref}
    >
      <IntentCheckbox />
      {isAddURLEnabled ? (
        <IntentRightSection isDraggable />
      ) : (
        <DragIcon color={palette.colors.grey[500]} />
      )}
    </IntentContainer>
  ) : (
    // Unselected, non-draggable intent:
    <IntentContainer
      doesConfigurationUrlExist={Boolean(existingIntentButtonConfigurationUrl)}
      isDragging={false}
    >
      <IntentCheckbox />
      {isAddURLEnabled && <IntentRightSection />}
    </IntentContainer>
  );
}

const InsertUrlDialog = ({
  defaultUrl = '',
  isOpen,
  onAccept,
  onClose,
}: {
  defaultUrl?: string;
  isOpen: boolean;
  onAccept: (url: string | null, callback: () => void) => void;
  onClose: () => void;
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [url, setUrl] = useState(defaultUrl);
  const [touched, setTouched] = useState(false);
  const errorMessage = isUrlValid(url) ? '' : 'Invalid url';

  const handleSubmit = (url: string | null) => {
    setIsSaving(true);
    onAccept(url, () => {
      setIsSaving(false);
      onClose();
    });
  };

  return (
    <Dialog
      footer={
        <>
          {defaultUrl && (
            <Box marginRight='auto'>
              <Button
                disabled={isSaving}
                onClick={() => {
                  handleSubmit(null);
                }}
                variant='danger'
              >
                <IconTrash size={20} style={{ marginRight: '4px' }} />
                Remove
              </Button>
            </Box>
          )}
          <Button disabled={isSaving} onClick={onClose} variant='ghost'>
            Cancel
          </Button>
          <Button
            disabled={Boolean(errorMessage) || isSaving}
            isLoading={isSaving}
            onClick={() => {
              handleSubmit(url);
            }}
          >
            Apply
          </Button>
        </>
      }
      hideCloseButton
      onClose={onClose}
      open={isOpen}
      title='URL'
    >
      {/* This line is needed to show the complete focused outline for the text field component */}
      <Box mt='1px' />
      <TextField
        aria-label='URL'
        error={touched && errorMessage}
        fullWidth
        onChange={e => {
          setUrl(e.target.value);
          setTouched(true);
        }}
        placeholder='http://example.com'
        value={url}
      />
      <Typography component='div' minWidth='400px' mt={1} variant='font14'>
        This will open in new tab.
      </Typography>
    </Dialog>
  );
};

const DragIcon = styled(IconEqual)`
  cursor: move;
`;

const IntentContainer = styled('div')<{
  doesConfigurationUrlExist: boolean;
  isDragging: boolean;
}>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  opacity: ${props => (props.isDragging ? 0 : 1)};
  padding-left: 20px;
  padding-right: 20px;

  button {
    display: ${props => (props.doesConfigurationUrlExist ? 'flex' : 'none')};
    color: ${props => props.theme.palette.colors.grey[700]};
  }

  &:hover {
    background-color: ${props => props.theme.palette.colors.blue[100]};
    button {
      display: flex;
      color: ${props => props.theme.palette.colors.purple[500]};
    }
  }

  &:not(:last-of-type) {
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
  }
`;
