import { JSONSchema7, JSONSchema7TypeName } from 'json-schema';

import { ConnectorDocument } from './connector/types';
import { TaxonomyVersion } from './discover/types';
import { ChatEvent } from 'src/pages/intent-conversation-analytics/ConversationDrawer/types';
import { TranscriptComponent } from 'src/pages/intent-conversation-analytics/ConversationTranscript/types';
import { VoiceTranscript } from 'src/pages/intent-conversation-analytics/voice-conversation-drawer/types';
import { Treatments } from 'src/slices/experiments/types';
import { FredeshdeskGroup } from 'src/types/workflowBuilderAPITypes';

export interface TableDataResInterface {
  aggregate: [];
  breakdown: [];
}

export interface ChannelsInterface {
  channels: string[];
}

export interface ChartDataDict {
  breakdown_values: Array<string>;
  data: Array<{
    x: {
      [index: string]: number;
    };
    y: {
      [index: string]: {
        [index: string]: number;
      };
    };
  }>;
  data_types: {
    [key: string]: string;
  };
  mean: {
    [index: string]: {
      [key: string]: number;
    };
  };
  totals: ChartTotals;
  x: string;
  y: string[];
}

export interface TableDataDict {
  aggregate: {
    [key: string]: number;
  };
  breakdown: Array<{
    [index: string]: number | string;
  }>;
  totals: {
    [key: string]: number;
  };
}

export interface MacroTableDataDict {
  macros: (MacroDataDict | [])[];
}

export interface MacroDataDict {
  [key: string]: string | number | boolean | Array<string>;
  coverage: number;
  is_active: boolean;
  is_trained: boolean;
  is_whitelist_emails_enabled: boolean;
  last_modified_at: number;
  macro_id: string;
  name: string;
  resolutions: number;
  url: string;
  usage: number;
  whitelisted_emails: Array<string>;
}

export interface TableTotals {
  [index: string]: number;
}

export interface ChartTotals {
  [index: string]: {
    aggregate: number;
  };
}

export interface Fields {
  agent: Field[];
  ticket: Field[];
}
export interface ReportsResponseDict {
  acknowledged: boolean;
}

export interface Report {
  description: string;
  is_default: boolean;
  name: string;
  query: {
    query_type: string;
    time_window_unit: string;
    visualization_type: string;
  };
  report_id?: number;
  selected_y_axis: string;
  user_id: string;
}

export interface GetAllReportsDict {
  reports: Array<Report>;
  user_bookmarked_report_ids: number[];
  user_owned_report_ids: number[];
}

export interface Field {
  field_category?: string;
  field_id: string;
  field_name: string;
  field_type: string;
  isSelected?: boolean;
}

export interface Value {
  field_name: string;
  isSelected: boolean;
}

export interface FilterValueResponse {
  values: string[];
}

export interface Filters {
  field: {
    field_category?: string;
    field_id?: string;
    field_name?: string;
    field_type?: string;
  };
  negate: boolean | string;
  operator: string;
  values: Array<string | boolean | number | Date>;
}

export interface FiltersQuery {
  field: {
    field_category: string;
    field_id: string;
  };
  negate: boolean;
  operator: string;
  values: Array<string | boolean | number | Date>;
}

export type GetAnswersQuery = {
  query: QueryType;
  time_zone: string;
};

export type QueryType = {
  breakdown?: {
    field: {
      field_category?: string;
      field_id: string;
    };
  };
  filters?: Filters[];
  query_type: string;
  time_range: {
    end_time: number;
    start_time: number;
  };
  time_window_unit: string;
  visualization_type: string;
};

export type OrgDict = {
  organizations: OrgsDict[];
};

export interface OrgsDict {
  org_id: number | string;
  org_name: string;
}

export interface SolveTableDict {
  aggregate: SolveAggregate | SolveWorkflowsAggregate;
  breakdown: Array<Breakdown>;
  data_types: {
    [key: string]: string;
  };
  is_final?: boolean;
}

// Type of `aggregate` key for Solve V1
export interface SolveWorkflowsAggregate {
  [key: string]: number | string;
}
// Type of `aggregate` key for Solve V0 (Will become deprecated)
export interface SolveAggregate {
  [index: string]: {
    [key: string]: number;
  };
}

export interface Breakdown {
  [key: string]: number | string;
}

export interface SolveOverviewDataDict {
  bar_chart: Array<{
    data: Array<{
      x: number;
      y: number;
    }>;
    id: string;
  }>;
  cards: {
    [key: string]: number | boolean | null;
  };
  data_types: {
    [key: string]: string;
  };
  pie_chart: Array<{
    id: string;
    y: number;
  }>;
}

export enum GoToType {
  GO_TO_STEP = 'go_to_step',
  GO_TO_WORKFLOW = 'go_to_workflow',
}

export enum STEP_TYPES {
  ADD_STEP = 'add_step',
  FORETHOUGHT_NODE = 'forethoughtNode',
}

export interface Step {
  go_to?: {
    go_to_id?: string;
    go_to_type?: GoToType;
    parent_id?: string;
  };
  is_entry_step?: boolean;
  // Extra React Flow attrs
  level?: number;
  parent_step_id?: string;
  seen?: boolean;
  step_fields: {};

  step_id: string;
  step_name: string;
  step_type: string;
  transitioned_into?: boolean;
  transitions: Array<Transition>;

  workflow_id: string;
}

export interface WorkflowConfigDict {
  context_variables: {
    [key: string]: {
      context_variable_type: string;
      created_by_step: string;
    };
  };
  created_date: string;
  entry_step_id: string;
  modified_date: string;
  org_id: number;
  preview_api_token: string;
  step_map: {
    [key: string]: Step;
  };
  version: number;
  workflow_parameters: Array<string>;
  workflows: {
    [key: string]: WorkflowsList;
  };
}

export type WorkflowsList = {
  [index: string]: string;
  entry_step_id: string;
  workflow_name: string;
  workflow_type: string;
};

export interface ConditionExpressions {
  expression_type: string;
  expressions?: Array<ConditionExpressions>;
  field?: string;
  negate: boolean;
  operator?: string;
  values?: Array<string | boolean | number>;
}

export interface Transition {
  condition?:
    | {
        properties: {
          [index: string]: { enum: Array<string>; type: string };
        };
        required: Array<string>;
        type: string;
      }
    | {};
  condition_expression?: ConditionExpressions | null;
  hide_add_step?: boolean;
  step_id?: string;
}

//todo remove any when all steps are moved to the side panel
export type StepFields = {
  [key: string]: string | any;
};

export type ContextVariables = {
  context_variables: Array<string>;
};

export interface Helpdesk {
  helpdesk: string;
  helpdesk_link: string;
}

export interface ContextParameters {
  key: string;
  value: string;
}

export interface FormField {
  input_type: string;
  output_variable: string;
  placeholder: string;
}

export interface FormStepFieldsType {
  form_fields: Array<FormField>;
  prompt: string;
}

export interface ContextVariableListItems {
  displayName: string;
  id: string;
  type?: string;
}

export interface AddStepParameters {
  isEntryStep: boolean;
  isLeafStep: boolean;
  parentStepId: string;
}

export interface SqueezeStepParameters {
  isNewWorkflow?: boolean;
  stepId?: string;
  workflowId: string;
}

export interface EditConditionParameters {
  conditionExpressions?: ConditionExpressions;
  currentTransitionIndex: number;
  stepId?: string;
}

export interface SqueezeStepParametersWithStepId extends SqueezeStepParameters {
  stepId: string;
}

export interface Node {
  data: Step;
  height: number;
  id: string;
  position: { x: number; y: number };
  type: string;
}

export interface Edge {
  animated: false;
  data: {
    isFirstCondition: boolean;
    isHidden: boolean;
    isLastCondition: boolean;
    numberOfTransitions: number;
    step: Step;
    transition: Transition;
    transitionIndex: number;
  };
  id: string;
  points: { x: number; y: number }[];
  source: string;
  style: Object;
  target: string;
  type: string;
}

export interface StepMap {
  [key: string]: Step;
}

export interface ActionBuilderAction {
  actions: Array<{
    action_fields: ActionBuilderAPIActionFields;
    action_id: string;
    action_type: string;
    active: boolean;
    created_date: string;
    description: string;
    input_context_variables: {
      [key: string]: {
        context_variable_type: string;
        created_by_step: string;
      };
    };
    modified_date: string;
    name: string;
    org_id: number;
    output_context_variables: {
      [key: string]: {
        context_variable_type: string;
        created_by_step: string;
      };
    };
  }>;
}

interface ActionBuilderAPIActionFields {
  authorization_config?: {
    [key: string]: string;
  };
  body_parameters?: {
    [key: string]: string;
  };
  headers?: {
    [key: string]: string;
  };
  method: string;
  output_context_variables?: Record<string, string>;
  query_parameters?: {
    [key: string]: string;
  };
  url: string;
}

export type ConnectorTypes = Array<'data' | 'action'>;

export interface ESDocumentOverview {
  doc_count: number | null | undefined;
  doc_type: string;
  last_modified_at: string | null | undefined;
  paragraph_count: number | null | undefined;
}

export interface ESDocumentOverviewResponse {
  es_document_overview: ESDocumentOverview[];
}

export interface ConnectorDefinition {
  avatar: string;
  connector_fields_schema: JSONSchema7 | null;
  connector_settings_schema?: JSONSchema7 | null;
  /**
   * Whether this connector comes from Tray or legacy definitions
   */
  connector_source: 'LEGACY' | 'THIRD_PARTY_TRAY';
  /**
   * List of possible types
   * can be either one or both
   */
  connector_types: ConnectorTypes;
  description: string;
  integration_type: string;
  is_helpdesk: boolean;
  /**
   * Whether or not this connector is for a helpdesk. Because orgs can only have one helpdesk, this helps us hide others.
   */
  name: string;
  /**
   * JSON form schema for oauth credentials. The fields to show for oauth connector types before connecting.
   */
  oauth_credential_schema: JSONSchema7 | null;
  /**
   * Optional long description
   */
  overview?: string;
  /**
   * Optional setup instructions to show in drawer - this is stringified html
   */
  setup_instructions?: string;
  /**
   * Method of connector setup
   */
  setup_type: 'FORM' | 'OAUTH' | 'THIRD_PARTY_TRAY_WIZARD' | 'MANUAL_LOAD';
  /**
   * Primary identifier for connector definitions
   */
  slug: string;
}

export type Credentials = Record<string, unknown>;

export interface Connector {
  actions?: Array<ConnectorAction>;
  connector_definition: ConnectorDefinition;
  connector_fields: Credentials;
  connector_id: string;
  connector_settings?: Credentials | undefined;
  connector_type?: string;
  connector_types: ConnectorTypes;
  /**
   * These are the existing credentials for connected connectors
   * This field is only used for OAUTH connectors.
   */
  credentials?: Credentials;
  display_name?: string;
  is_active: boolean;
  /**
   * ISO date string of last update to connector model
   */
  last_modified_at: string | null;
  /**
   * Email of user who last modified connector model (either credentials or active state)
   */
  last_modified_by: string | null;
  /**
   * ISO date string of last processed to connector model
   */
  last_processed_at: string | null;
  reindex_interval_sec: number;
}

export interface UpdateConnectorRequest {
  connector_fields?: Credentials;
  connector_settings?: Credentials;
  connector_types: ConnectorTypes;
  is_active?: boolean;
}

export interface UpdateConnectorDisplaySettings {
  display_name: string;
}

export interface EmbeddableDashboard {
  metabase_dashboard_id: number;
  title: string;
}

export interface EmbeddableLookerDashboard {
  looker_dashboard_id: number;
  title: string;
}

export interface EmbeddableSettings {
  embeddable_dashboards: EmbeddableDashboard[];
}

export interface ConnectorJob {
  doc_type_display_names: string[];
  integration_name: string;
  job_end_time?: string;
  job_id: string;
  job_start_time: string;
  public_stage: 'errored' | 'pending' | 'success';
}

export interface ConnectorJobListResponse {
  jobs: ConnectorJob[];
}

export type CognitoUserRole = 'ADMIN' | 'SUPER_ADMIN' | 'USER' | 'AGENT';

type CognitoUserState =
  | 'UNCONFIRMED'
  | 'CONFIRMED'
  | 'ARCHIVED'
  | 'COMPROMISED'
  | 'UNKNOWN'
  | 'RESET_REQUIRED'
  | 'FORCE_CHANGE_PASSWORD'
  | 'EXTERNAL_PROVIDER';

export interface UserNotificationChannels {
  email: boolean;
}
export interface UserNotificationPreferences {
  solve_widget_error: UserNotificationChannels;
}
export interface User {
  created_date?: number;
  email: string;
  enabled: boolean;
  first_name: string;
  last_name: string;
  permission_level: CognitoUserRole;
  status?: CognitoUserState;
  updated_date?: number;
  user_id: string;
  user_notification_preferences: UserNotificationPreferences;
}

export type UserRequestBody = Omit<User, 'user_id'>;

export interface Organization {
  display_name: string | null;
  email_domains: string[];
  org_helpdesk: string;
  org_type: 'demo' | 'test' | 'customer_sandbox' | 'customer_production';
  org_type_display_name: 'Demo' | 'Test' | 'Sandbox' | 'Production';
}

export interface GetCurrentUserResponse {
  current_user: User;
  organization: Organization;
}

export interface UpdateOrganizationResponse {
  organization: Organization;
}

export interface GetUsersResponse {
  users: User[];
}

export interface SalesforceContactResponse {
  success: boolean;
}

interface ConnectorInput extends JSONSchema7 {
  properties?: { [key: string]: JSONSchema7 };
}
export interface ConnectorAction {
  action_id: string;
  description: string;
  has_dynamic_output: boolean;
  input_schema: ConnectorInput;
  output_schema: JSONSchema7;
  title: string;
}

export interface AutomationInputAction
  extends Record<string, string | boolean | JSONSchema7TypeName[] | string[]> {
  is_input_field: boolean;
}

export interface AutomationOutputAction
  extends Record<string, string | boolean> {
  use_as_input: boolean;
}

interface AutomationInputField {
  context_variable: string;
  input_text: string;
}

export interface Automation {
  action_list: Array<Record<string, string>>;
  actions_input_formatter: Record<string, Array<AutomationInputAction>>;
  actions_output_formatter: Record<string, Array<AutomationOutputAction>>;
  automation_id: string;
  created_on: string;
  description: string;
  emblem_urls: Array<string>;
  external_text_field?: string;
  input_description?: string;
  input_fields: Array<AutomationInputField>;
  internal_text_field?: string;
  org_id: number;
  published: boolean;
  title: string;
}

export interface FreshChatGroupsResponse {
  groups: Array<FredeshdeskGroup>;
}

export interface FeatureFlagsResponse {
  feature_flags: Array<string>;
}

/* Response type for startTrayFlow */
export interface TraySettingsModel {
  slug: string;
  solution_instance_id: string;
  webhook_url: string;
  wizard_url?: string;
}

/* Response type for GET Org Config */
export interface OrgConfigResponse {
  article_generation_version: 'v1_full_articles' | 'v2_snippet_articles';
  discover_status: 'enabled' | 'disabled';
  is_assist_gpt_enabled: boolean;
  is_content_generation_enabled: boolean;
  is_discover_api_data_filter_enabled: boolean;
  is_discover_content_multi_segmentation_enabled: boolean;
  is_document_index_page_enabled: boolean;
  is_prefilled_automation_template_enabled: boolean;
  is_ticket_keyword_search_enabled: boolean;
  taxonomy_version: TaxonomyVersion;
}

export interface IdPSettings {
  /**
   * Stringified XML file to be used for SSO configuration
   */
  metadata_file?: string;
  /**
   * URL used for SSO configuration
   */
  metadata_url?: string;
}

export interface SSOConfig {
  /**
   * Whether or not SSO is enabled for the org
   */
  is_sso_enabled: boolean;
}

export interface SSOSettings extends SSOConfig {
  /**
   * Settings for SAML IdP
   */
  idp_settings: IdPSettings | null;
  /**
   * Whether or not SCIM is allowed for the org
   */
  is_scim_allowed: boolean;
  /**
   * Whether or not SCIM is enabled for the org
   */
  is_scim_enabled: boolean;
}

export interface IdPDetails {
  identity_provider: string;
  pool_client_id: string;
}

export interface GetOrgDiscoveryResponse {
  cognito_network_error_fix_enabled: boolean;
  idp_details: IdPDetails;
  is_sso_enabled: boolean;
  is_sso_enforced: boolean;
}

export interface ExperimentResponse {
  treatments: Partial<Treatments>;
}

export interface FeedbackBreakdown {
  negative: number;
  positive: number;
  unanswered: number;
}

export interface DropoffBreakdown {
  dropoff_count: number;
  no_dropoff_count: number;
}

export interface RelevanceBreakdown {
  irrelevant_count: number;
  relevant_count: number;
  somewhat_relevant_count: number;
}

export interface ConversationAggregateMetricsResponse {
  avg_conversation_duration: number;
  avg_csat_score: number;
  dropoff_breakdown?: DropoffBreakdown;
  irrelevant_count: number | null;
  last_modified_time: string | null;
  num_conversations: number;
  num_deflected: number;
  relevance_breakdown?: RelevanceBreakdown;
}

export type RelevanceRating = 'relevant' | 'somewhat relevant' | 'irrelevant';

export interface QuickFeedbackRecord {
  additional_feedback: string;
  document_id: string;
  is_useful: boolean | null;
  link: string;
  style: string;
  title: string;
}

export interface Conversation {
  additional_feedback: string | null;
  autonomous_agent_used: boolean;
  channel?: string;
  chat_events?: ChatEvent[];
  context_variables?: Record<string, string>;
  conversation_id: string;
  csat_feedback: string[];
  csat_resolve: boolean | null;
  csat_score: number;
  deflected: boolean;
  dropoff_rating?: boolean | null;
  dropoff_reasoning?: string | null;
  last_executed_intent_definition_id: string;
  last_executed_intent_title: string;
  last_executed_intent_user_query: string | null;
  modified_date: string;
  not_useful_count?: number;
  quick_feedback_responses?: Array<QuickFeedbackRecord> | null;
  relevance_rating?: RelevanceRating | null;
  relevance_reasoning?: string | null;
  start_datetime: string;
  tagged_zendesk_ticket_source_id?: string | null;
  tagged_zendesk_ticket_source_link?: string | null;
  time_spent_in_widget: number;
  transcript: string[];
  transcript_components?: TranscriptComponent[];
  unanswered_count?: number;
  useful_count?: number;
  user_interactions?: number;
  user_queries?: string[];
  voice_metrics?: voice_metrics;
  voice_transcripts?: VoiceTranscript[];
  workflow_tag?: string;
}

interface voice_metrics {
  avg_response_time?: number;
  call_duration?: number;
  call_end_time?: string;
  call_start_time?: string;
  sentiment?: 'positive' | 'neutral' | 'negative';
}

export interface ConversationMetadata {
  end: number;
  links: {
    next: string | null;
    previous: string | null;
    self: string;
  };
  page: number;
  page_size: number;
  sort_column: 'start_datetime' | 'csat_score';
  sort_direction: 'asc' | 'desc';
  start: number;
}

export interface ConversationsResponse {
  conversations: Conversation[];
  metadata: ConversationMetadata;
  nextPage: boolean;
}

export interface ArticleSuggestionRecord {
  link: string;
  source_doc_id: string;
  source_doc_type: string;
  title: string;
}

type WorkflowExecutionEventType =
  | 'dynamic_article_suggestion'
  | 'action'
  | 'ticket_update'
  | 'automation_triggered';

interface DynamicArticleSuggestionEventData {
  articles: ArticleSuggestionRecord[];
  paraphrased_answer: string | null;
}

interface ActionEventData {
  action_id: string;
  action_name: string;
  response_status_code: number;
}

interface TicketUpdateEventData {
  step_id: string;
}

type AutomationActionType = 'no-response' | 'response-delay' | 'thread-parsing';

export interface AutomationEventData {
  automation_action_type: AutomationActionType;
  automation_id: string;
  query_expression: ConditionExpressions;
}

type WorkflowEventData =
  | DynamicArticleSuggestionEventData
  | ActionEventData
  | TicketUpdateEventData
  | AutomationEventData;

interface WorkflowExecutionEvent {
  event_data: WorkflowEventData;
  event_type: WorkflowExecutionEventType;
}

export interface EmailConversationTableRow {
  autopilot_used: boolean | null;
  conversation_id: string;
  created_date: string;
  deflected: number;
  dynamic_article_suggestion_events: DynamicArticleSuggestionEventData[];
  email_workflow_execution_events: WorkflowExecutionEvent[];
  email_workflow_execution_status: string | null;
  intent_definition_id: string | null;
  intent_title: string | null;
  interactive_email_deflection_status:
    | 'pending'
    | 'deflected'
    | 'not_deflected'
    | null;
  potential_attempt: number;
  response_sent: string;
  response_sent_date: string | null;
  skip_reason: string | null;
  source_body: string | null;
  source_doc_type: string | null;
  source_id: string | null;
  source_title: string | null;
  ticket_summarizer_output: string | null;
  ticket_translated_output: string | null;
}

interface EmailConversationMetadata
  extends Omit<ConversationMetadata, 'links' | 'sort_column'> {
  next_page: number;
  sort_column: 'created_date';
  total: number;
}

export interface EmailConversationMetricsResponse {
  conversations: EmailConversationTableRow[];
  metadata: EmailConversationMetadata;
}

export type Product =
  | 'assist-extension'
  | 'assist-sidebar'
  | 'solve-api'
  | 'solve-email'
  | 'solve-widget'
  | 'solve-chat'
  | 'triage'
  | 'dashboard'
  | 'workflow'
  | 'workflow-builder-preview'
  | 'zendesk-ingest'
  | 'zendesk-chat'
  | 'salesforce-sidebar'
  | 'zendesk-sidebar'
  | 'discover';

export interface FeedbackBreakdown {
  negative: number;
  positive: number;
  unanswered: number;
}

export type SortDirection = 'asc' | 'desc';

export interface GetDocumentListParams {
  cursor: string | null;
  endCreatedDate?: number;
  integration?: string;
  isPublic?: boolean;
  sortColumn?: string;
  sortDirection?: SortDirection;
  sourceId?: string;
  standardizedDocumentType: StandardizedDocType;
  startCreatedDate?: number;
  title?: string;
}

export interface GetDocumentListResponse {
  cursor: string | null;
  documents: ConnectorDocument[];
}

export interface DocumentDetails extends ConnectorDocument {
  body: string;
  html_body: string;
  indexing_notes: string[] | null;
  not_searchable_reason: string | null;
}

export enum StandardizedDocType {
  KNOWLEDGE_ARTICLE = 'knowledge_article',
  REPLY = 'reply',
  RESPONSE_TEMPLATE = 'response_template',
  TICKET = 'standardized_ticket',
  TICKET_UPDATE_EVENT = 'ticket_update_event',
}
