import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SummaryCard = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.colors.slate[200]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
  overflow-wrap: break-word;
  word-break: break-word;
`;
