import { useState } from 'react';
import { Box, Typography } from '@mui/material';

import {
  Button,
  SelectDropdown,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import {
  useAddAutoflowToolMutation,
  useUpdateAutoflowToolConfigurationFieldsMutation,
} from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import {
  AutoflowTool,
  IntercomTicketCreationSettings,
  ToolDefinitionResponse,
} from 'src/types/workflowBuilderAPITypes';

interface EditCreateZendeskTicketToolProps {
  intentWorkflowId: string;
  onClose: () => void;
  tool:
    | (AutoflowTool & { toolDefinition?: ToolDefinitionResponse | undefined })
    | undefined;
}

export const EditCreateIntercomTicketTool = ({
  intentWorkflowId,
  onClose,
  tool,
}: EditCreateZendeskTicketToolProps) => {
  const [mutate, { isLoading: isUpdatingFields }] =
    useUpdateAutoflowToolConfigurationFieldsMutation();
  const [addAutoflowTool, { isLoading: isAddingTool }] =
    useAddAutoflowToolMutation();

  const [ticketCreationSettings, setTicketCreationSettings] =
    useState<IntercomTicketCreationSettings>(
      tool?.toolDefinition?.tool_fields?.intercom_ticket_creation_settings ?? {
        ticket_id_output_variable: null,
        ticket_type: null,
        visitor_email_variable: null,
        visitor_name_variable: null,
      },
    );

  const hasUnfilledFields =
    !ticketCreationSettings.ticket_id_output_variable ||
    !ticketCreationSettings.visitor_email_variable ||
    !ticketCreationSettings.visitor_name_variable ||
    !ticketCreationSettings.ticket_type;

  const availableTicketTypes =
    tool?.configuration_fields.available_ticket_types || [];

  const isLoading = isAddingTool || isUpdatingFields;

  const onSave = async (
    ticketCreationFields: IntercomTicketCreationSettings,
  ) => {
    try {
      if (!tool?.toolDefinition) {
        const toolData = {
          body: {
            tool_id: tool?.tool_id ?? '',
            tool_type: tool?.tool_type ?? 'system_built_in',
          },
          intentWorkflowId,
        };
        await addAutoflowTool(toolData).unwrap();
      }
      await mutate({
        body: {
          configuration_fields: {
            intercom_ticket_creation_settings: ticketCreationFields,
          },
          tool_id: tool?.tool_id || '',
        },
        intentWorkflowId,
      }).unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      alignItems='space-between'
      bgcolor={theme => theme.palette.colors.white}
      display='flex'
      flexDirection='column'
      gap={3}
      p={3}
      width='650px'
    >
      <Box>
        <Typography variant='font20'>Intercom ticket creation</Typography>
      </Box>

      <Box
        display='flex'
        flex={1}
        flexDirection='column'
        gap={3}
        overflow='scroll'
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography variant='font14Medium'>Name</Typography>
          <ContextVariableSelectDropdown
            aria-label='Ticket requester name'
            isClearable
            onChange={value => {
              setTicketCreationSettings(ticketCreationSettings => ({
                ...ticketCreationSettings,
                visitor_name_variable: value,
              }));
            }}
            shouldIncludeSystemContextVariables
            value={ticketCreationSettings.visitor_name_variable || ''}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography variant='font14Medium'>Email</Typography>
          <ContextVariableSelectDropdown
            aria-label='Ticket requester email'
            isClearable
            onChange={value => {
              setTicketCreationSettings(ticketCreationSettings => ({
                ...ticketCreationSettings,
                visitor_email_variable: value,
              }));
            }}
            shouldIncludeSystemContextVariables
            value={ticketCreationSettings.visitor_email_variable || ''}
          />
        </Box>

        <ContextVariableSelectDropdown
          isClearable
          label='Ticket ID Output Context Variable'
          onChange={value => {
            setTicketCreationSettings(ticketCreationSettings => ({
              ...ticketCreationSettings,
              ticket_id_output_variable: value,
            }));
          }}
          value={ticketCreationSettings.ticket_id_output_variable || ''}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography variant='font14Medium'>Ticket Type</Typography>
          <SelectDropdown
            id='ticket-type-select'
            inputProps={{ 'aria-label': 'Ticket Type' }}
            onChange={e => {
              setTicketCreationSettings(ticketCreationSettings => ({
                ...ticketCreationSettings,
                ticket_type: e.target.value,
              }));
            }}
            onClear={() => {
              setTicketCreationSettings(ticketCreationSettings => ({
                ...ticketCreationSettings,
                ticket_type: '',
              }));
            }}
            options={availableTicketTypes.map(ticketType => ({
              label: ticketType.name,
              value: ticketType.id,
            }))}
            placeholder='Select a status'
            value={ticketCreationSettings.ticket_type || ''}
          />
        </Box>

        <Box display='flex' gap={2} mt='auto'>
          <Button onClick={onClose} size='large' variant='secondary'>
            Cancel
          </Button>
          <Tooltip
            placement='top'
            sx={{ width: '100%' }}
            tooltipContent={hasUnfilledFields && 'Please fill out the fields'}
          >
            <Button
              disabled={isLoading || hasUnfilledFields}
              fullWidth
              isLoading={isLoading}
              onClick={() => onSave(ticketCreationSettings)}
              size='large'
              variant='main'
            >
              Save
            </Button>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};
