import { ReactNode } from 'react';
import { Box } from '@mui/material';

import { DeepLink } from './DeepLink';
import { BaseErrorPayload, ErrorQueryEvent } from 'src/types/ErrorEventTypes';
import { AllConversationChannel } from 'src/types/workflowBuilderAPITypes';

export const HowToFixSection = ({
  channel,
  children,
  error_payload,
  showDeepLink = true,
}: {
  channel: ErrorQueryEvent['channel'];
  children: ReactNode;
  error_payload: BaseErrorPayload;
  showDeepLink?: boolean;
}) => {
  const errorChannelToConversationChannelMap: Record<
    string,
    AllConversationChannel
  > = {
    live: 'email',
    widget: 'widget',
  };

  const conversationChannel =
    errorChannelToConversationChannelMap[channel || ''];

  return (
    <>
      {children}
      {showDeepLink && (
        <Box mt={1}>
          <DeepLink
            channel={conversationChannel}
            intentDefinitionId={error_payload.intent_definition_id}
            stepId={error_payload.step_id}
            workflowId={error_payload.workflow_id}
          />
        </Box>
      )}
    </>
  );
};
