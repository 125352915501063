export const textStyles = {
  color: 'black',
  fontSize: '14px',
  img: { display: 'block', maxWidth: '100%' },
  pre: {
    whiteSpace: 'pre-wrap',
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
  },
  wordWrap: 'break-word',
};
