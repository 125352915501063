import { useCallback, useMemo, useState } from 'react';
import { MRT_TableOptions } from 'material-react-table';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, FormControl, useTheme } from '@mui/material';

import {
  Button,
  DateRangeFilterButton,
  InfiniteTable,
  Order,
  SearchWithDropdown,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import InsightDownloadCsv from '../common/InsightDownloadCsv';
import { initialInsightParams, INTENT_FILTER_PARAM_NAME } from '../constants';
import {
  dateRangeToTimestamp,
  getIntentFilterOptions,
  isUiQueryError,
  sortDirectionToInsightSortDirection,
} from '../helpers';
import { useGetCurrentTab } from '../hooks/useGetCurrentTab';
import { useGetFilterCount } from '../hooks/useGetFilterCount';
import { useGetSearchQuery } from '../hooks/useGetSearchQuery';
import { EmailSearchQueryMode, InsightSortDirection, Scope } from '../types';
import ChatsTableLayout from './ChatsTableLayout';
import { buildEmailConversationColumns, getTableHeight } from './helpers';
import UnansweredDialog from './UnansweredDialog';
import GlobalFilterDrawer from 'src/components/global-filter-drawer';
import {
  listCVObjectDeserialize,
  listCVObjectSerialize,
} from 'src/components/global-filter-drawer/helper';
import { ContextVariableFilterItemValue } from 'src/components/global-filter-drawer/types';
import {
  useGetIntentsQueryWithProduct,
  useGetWorkflowTags,
  useStateParams,
} from 'src/hooks/hooks';
import {
  ALLTIME_END,
  ALLTIME_START,
  getEmailInsightsFilterOptions,
} from 'src/pages/intent-conversation-analytics/constants';
import { EmailConversationDrawer } from 'src/pages/intent-conversation-analytics/EmailConversationDrawer';
import { buildQueryFromMultiSelected } from 'src/pages/intent-conversation-analytics/hooks/useConversations';
import { useGetEmailConversationTableQuery } from 'src/services/insights';
import {
  InsightEmailConversation,
  InsightEmailConversationSortColumns,
} from 'src/services/insights/types';
import { useGetEmailConversationsQuery } from 'src/services/solve-conversations/emailConversationsApi';
import {
  dateRangeToTimeFilter,
  genericSerializeAndDeserialize,
  listDeserialize,
  listSerialize,
} from 'src/utils/discover/helpers';
import { ExportableTableType } from 'src/utils/enums';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

interface EmailChatsTableProps {
  dateRange: DateRange;
  scope: Scope;
  setDateRange: (dateRange: DateRange) => void;
}

const EmailChatsTable = ({
  dateRange,
  scope,
  setDateRange,
}: EmailChatsTableProps) => {
  const navigate = useNavigate();
  const { articleId = undefined } = useParams<'articleId'>();
  const [page, setPage] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isUnansweredCausesDialogOpen, setIsUnansweredCausesDialogOpen] =
    useState(false);
  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >(null);
  const { palette } = useTheme();

  const currentTab = useGetCurrentTab({
    articleId,
  });

  const [multiSelected, setMultiSelected] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_${currentTab}`,
    serialize: listSerialize,
  });
  const [cvFilters, setCvFilters] = useStateParams<
    ContextVariableFilterItemValue[]
  >({
    deserialize: listCVObjectDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_cv_${currentTab}`,
    serialize: listCVObjectSerialize,
  });

  const deserializeSortDirection = useCallback(
    (param: string) => (param === 'ASC' ? 'ASC' : 'DESC'),
    [],
  );

  const deserializeSortColumn = useCallback(
    (param: string) => param as InsightEmailConversationSortColumns,
    [],
  );

  // state params
  const [sortDirection, setSortDirection] =
    useStateParams<InsightSortDirection>({
      deserialize: deserializeSortDirection,
      initialState: 'DESC',
      paramsName: 'chats_sort_direction',
      serialize: String,
    });
  const [searchQuery, setSearchQuery] = useStateParams<string>({
    deserialize: (str: string) => (str === 'null' ? '' : str),
    initialState: '',
    paramsName: 'chats_search',
    serialize: genericSerializeAndDeserialize,
  });
  const [searchQueryMode, setSearchQueryMode] =
    useStateParams<EmailSearchQueryMode>({
      deserialize: genericSerializeAndDeserialize as (
        str: string,
      ) => EmailSearchQueryMode,
      initialState: 'conversation_id',
      paramsName: 'chats_search_mode',
      serialize: genericSerializeAndDeserialize,
    });
  const [sortColumn, setSortColumn] =
    useStateParams<InsightEmailConversationSortColumns>({
      deserialize: deserializeSortColumn,
      initialState: initialInsightParams.emailChatColumnSort,
      paramsName: 'chats_sort_column',
      serialize: String,
    });
  // end state params

  // Hooks
  const workflowTags = useGetWorkflowTags();
  /**
   * Since both design version exist with the feature flag value
   * TODO - clean up the prop drilling once only the new design is used
   * The debouncing is causing issues when using with conjunction with useStateParams
   */
  const { setUiQuery, uiQuery } = useGetSearchQuery({
    searchQuery,
    setSearchQuery,
  });

  const { data: conversationDetailData } = useGetEmailConversationsQuery(
    {
      conversationId: selectedConversationId,
      end: ALLTIME_END,
      intentId: null,
      page: 1,
      sortColumn: 'created_date',
      sortDirection: 'desc',
      start: ALLTIME_START,
    },
    { skip: !selectedConversationId },
  );

  const columns = useMemo(
    () =>
      buildEmailConversationColumns(
        palette,
        scope,
        setIsUnansweredCausesDialogOpen,
        navigate,
      ),
    [palette, scope, navigate],
  );
  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);

  const end = useMemo(() => Math.floor(Date.now() / 1000), []);

  const filterCount = useGetFilterCount({
    channels: [],
    cvFilters,
    multiSelected,
    workflowTags,
  });

  const handleResetAllFilter = useCallback(() => {
    setUiQuery('');
    setDateRange(initialInsightParams.date);

    setSortColumn(initialInsightParams.emailChatColumnSort, () =>
      setMultiSelected([], () => setCvFilters([])),
    );
  }, [setUiQuery, setSortColumn, setDateRange, setMultiSelected, setCvFilters]);

  const uiQueryError = isUiQueryError({ searchQueryMode, uiQuery });
  const isSearchingByChatId =
    searchQueryMode === 'conversation_id' && uiQuery && !uiQueryError;

  const tableRequestParams = useMemo(
    () => ({
      article_id: articleId,
      conversation_id:
        searchQueryMode === 'conversation_id' && !uiQueryError
          ? searchQuery
          : '',
      cv_filters: cvFilters,
      data_export_type: ExportableTableType.INSIGHT_EMAIL_CHATS_TABLE,
      sort_column: sortColumn,
      sort_direction: sortDirection,
      source_id: searchQueryMode === 'source_id' ? searchQuery : '',
      ...(isSearchingByChatId
        ? // Use maximum date range:
          {
            end: end,
            page: 1,
            start: Number(ALLTIME_START),
          }
        : {
            end: backendReadyTimestamps.end_timestamp,
            page,
            start: backendReadyTimestamps.start_timestamp,
            ...buildQueryFromMultiSelected(multiSelected, true),
          }),
    }),
    [
      articleId,
      searchQuery,
      sortColumn,
      sortDirection,
      backendReadyTimestamps,
      page,
      end,
      multiSelected,
      isSearchingByChatId,
      searchQueryMode,
      uiQueryError,
      cvFilters,
    ],
  );

  const {
    data: conversationTableData,
    isError: isChatsTableError,
    isFetching: isConversationTableFetching,
    isLoading: isConversationTableLoading,
  } = useGetEmailConversationTableQuery({
    ...tableRequestParams,
  });

  const rows = useMemo(() => {
    if (!conversationTableData?.data) {
      return [];
    }
    return conversationTableData.data;
  }, [conversationTableData]);

  const showLoadingSkeleton =
    isConversationTableLoading || (page === 1 && isConversationTableFetching);

  const timeFilter = dateRangeToTimeFilter(dateRange);
  const initialTimeFilter = dateRangeToTimeFilter(initialInsightParams.date);

  const selectedConversation = useMemo(() => {
    if (!selectedConversationId || !conversationDetailData) {
      return;
    }
    return conversationDetailData.conversations.find(
      conversation => conversation.conversation_id === selectedConversationId,
    );
  }, [conversationDetailData, selectedConversationId]);

  const areFiltersUsed = Boolean(
    uiQuery.length ||
      timeFilter.key !== initialTimeFilter.key ||
      multiSelected.length ||
      cvFilters.length,
  );

  const getChatsTableDownloadUrl = useCallback(() => {
    const url = new URL(`${API_URL}data-export`);
    return url.href;
  }, []);

  const handleSortCallback = useCallback(
    (property: keyof InsightEmailConversation, order: Order) => {
      setPage(1);
      setSortDirection(sortDirectionToInsightSortDirection(order));
      setSortColumn(property as InsightEmailConversationSortColumns);
    },
    [setSortColumn, setSortDirection],
  );

  const initialSorting = useMemo(
    () => [
      {
        desc: sortDirection === 'DESC',
        id: sortColumn,
      },
    ],
    [sortColumn, sortDirection],
  );

  const tableDataPage = conversationTableData?.metadata.page ?? 0;

  const handleLoadMore = useCallback(() => {
    if (tableDataPage === page) {
      const nextPage = page + 1;
      setPage(nextPage);
    }
  }, [page, tableDataPage]);

  const { data: intentsData, isLoading: intentsLoading } =
    useGetIntentsQueryWithProduct();
  const { intents = [] } = intentsData ?? {};

  const filterOptions = useMemo(() => {
    const extraFilters = [];

    if (!intentsLoading) {
      extraFilters.push({
        label: 'All workflows',
        options: getIntentFilterOptions(intents),
        value: 'intent_id',
      });
    }

    return getEmailInsightsFilterOptions({
      extraFilters,
      type: 'chat',
    });
  }, [intentsLoading, intents]);

  const searchOptions = useMemo(
    () => [
      { text: 'Conversation ID', value: 'conversation_id' },
      { text: 'Ticket ID', value: 'source_id' },
    ],
    [],
  );

  const tableOptions = useMemo<MRT_TableOptions<InsightEmailConversation>>(
    () => ({
      columns,
      data: rows,
      enableColumnActions: false,
      enableColumnFilters: false,
      enableExpanding: false,
      enableFullScreenToggle: false,
      enableGlobalFilter: false,
      enableRowActions: false,
      initialState: {
        columnPinning: {
          left: ['timestamp'],
        },
      },
      muiTableBodyRowProps: ({ row }) => ({
        onClick: () => {
          setSelectedConversationId(row.original.conversation_id);
        },
      }),
      renderTopToolbarCustomActions: () => (
        <Box
          alignItems='center'
          display='flex'
          gap='8px'
          height='40px'
          width='100%'
        >
          <Box width='360px'>
            <FormControl error={Boolean(uiQueryError)} fullWidth>
              <SearchWithDropdown
                aria-label='Chat ID filter'
                fullWidth
                onChange={e => setUiQuery(e.target.value)}
                onClear={() => setUiQuery('')}
                options={[...searchOptions]}
                placeholder={`Enter ${searchOptions
                  .find(option => option.value === searchQueryMode)
                  ?.text.toLowerCase()}`}
                selectChange={value => {
                  setSearchQueryMode(value as EmailSearchQueryMode);
                  setUiQuery('');
                }}
                selectedOption={searchQueryMode}
                value={uiQuery}
              />
            </FormControl>
          </Box>
          <DateRangeFilterButton
            explicitLabel={true}
            initialValue={initialInsightParams.date}
            onChange={value => setDateRange(value)}
            options={datePickerRangeOptionsRevamp}
            value={dateRange}
          />
          <Button
            onClick={() => setIsDrawerOpen(true)}
            size='medium'
            startIcon={<TuneIcon />}
            variant='secondary'
          >
            <Typography noWrap variant='font14Medium'>
              {filterCount ? `All filters (${filterCount})` : 'All filters'}
            </Typography>
          </Button>
          {areFiltersUsed && (
            <Button
              onClick={handleResetAllFilter}
              size='medium'
              variant='ghost'
            >
              <Typography noWrap variant='font14Medium'>
                Reset filters
              </Typography>
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <InsightDownloadCsv
              filename='chats.csv'
              requestData={{ ...tableRequestParams }}
              url={getChatsTableDownloadUrl()}
            />
          </Box>
        </Box>
      ),
    }),
    [
      columns,
      rows,
      uiQuery,
      dateRange,
      areFiltersUsed,
      getChatsTableDownloadUrl,
      setUiQuery,
      setDateRange,
      tableRequestParams,
      filterCount,
      handleResetAllFilter,
      searchOptions,
      searchQueryMode,
      setSearchQueryMode,
      uiQueryError,
    ],
  );

  return (
    <>
      <EmailConversationDrawer
        onClose={() => setSelectedConversationId(null)}
        selectedConversation={selectedConversation}
      />
      {isDrawerOpen && (
        <GlobalFilterDrawer
          filterType='chat'
          handleReset={handleResetAllFilter}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          otherFiltersOptions={filterOptions}
          product='email'
          searchOptions={searchOptions}
          setUiQuery={setUiQuery}
          tab={currentTab}
          uiQuery={uiQuery}
        />
      )}
      <UnansweredDialog
        isOpen={isUnansweredCausesDialogOpen}
        onClose={() => setIsUnansweredCausesDialogOpen(false)}
      />
      <ChatsTableLayout
        table={
          <InfiniteTable
            hasNextPage={
              typeof conversationTableData?.metadata.next_page === 'number'
            }
            initialSorting={initialSorting}
            isError={isChatsTableError}
            isLoadingFirstPage={showLoadingSkeleton}
            isLoadingNextPage={isConversationTableFetching}
            onLoadMore={handleLoadMore}
            onSortCallback={handleSortCallback}
            stickyHeaderHeight={getTableHeight('articles')}
            tableOptions={tableOptions}
          />
        }
      />
    </>
  );
};
export default EmailChatsTable;
