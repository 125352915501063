/* eslint-disable react/prop-types -- false warnings for column definitions */

import { createMRTColumnHelper } from 'material-react-table';
import { Box, Palette } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { numbersToStringWithCommas } from '../../solve-insights/helpers';
import {
  AGENT_TICKETS_TOOLTIP_COPY,
  TABLE_COLUMN_TO_LABEL_MAP,
} from '../constants';
import { formatSecondsToReadableHrMinTime } from '../utils';
import { NA } from 'src/constants/solve';
import { InsightAgentTicket } from 'src/services/insights/types';

export const buildTableColumns = (palette: Palette) => {
  const columnHelper = createMRTColumnHelper<InsightAgentTicket>();

  const columns = [
    columnHelper.accessor('agent_name', {
      Cell: ({ row }) => {
        return (
          <Box alignItems='center' columnGap={1} display='flex'>
            <Typography variant='font14'>{row.original.agent_name}</Typography>
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.agent_ticket.agent_name,
      Header: ({ column }) => (
        <Box display='flex' gap='4px'>
          {column.columnDef.header}
        </Box>
      ),
      size: 225,
    }),
    columnHelper.accessor('ticket_replied_count', {
      Cell: ({ row }) => {
        const count = numbersToStringWithCommas({
          number: row.original.ticket_replied_count,
        });

        return (
          <Box alignItems='center' columnGap={1} display='flex'>
            <Typography variant='font14'>{count}</Typography>
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.agent_ticket.ticket_replied_count,
      Header: ({ column }) => (
        <Box display='flex' gap='4px'>
          {column.columnDef.header}
          <Box display='flex'>
            <Tooltip
              tooltipContent={AGENT_TICKETS_TOOLTIP_COPY.ticket_replied_count}
            >
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        </Box>
      ),
      size: 230,
    }),
    columnHelper.accessor('ticket_assigned_count', {
      Cell: ({ row }) => {
        const count = numbersToStringWithCommas({
          number: row.original.ticket_assigned_count,
        });

        return (
          <Box alignItems='center' columnGap={1} display='flex'>
            <Typography variant='font14'>{count}</Typography>
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.agent_ticket.ticket_assigned_count,
      Header: ({ column }) => (
        <Box display='flex' gap='4px'>
          {column.columnDef.header}
          <Box display='flex'>
            <Tooltip
              tooltipContent={AGENT_TICKETS_TOOLTIP_COPY.ticket_assigned_count}
            >
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        </Box>
      ),
      size: 200,
    }),
    columnHelper.accessor('ticket_solved_count', {
      Cell: ({ row }) => {
        const count = numbersToStringWithCommas({
          number: row.original.ticket_solved_count,
        });
        const rate = numbersToStringWithCommas({
          number: row.original.ticket_solved_rate,
          style: 'percent',
        });

        return (
          <Box alignItems='center' columnGap={1} display='flex'>
            <Typography variant='font14'>{`${count} (${rate})`}</Typography>
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.agent_ticket.ticket_solved_count,
      size: 175,
    }),
    columnHelper.accessor('first_contact_resolution_count', {
      Cell: ({ row }) => {
        const count = numbersToStringWithCommas({
          number: row.original.first_contact_resolution_count,
        });
        const rate = numbersToStringWithCommas({
          number: row.original.first_contact_resolution_rate,
          style: 'percent',
        });

        return (
          <Box alignItems='center' columnGap={1} display='flex'>
            <Typography variant='font14'>{`${count} (${rate})`}</Typography>
          </Box>
        );
      },
      header:
        TABLE_COLUMN_TO_LABEL_MAP.agent_ticket.first_contact_resolution_count,
      size: 225,
    }),
    columnHelper.accessor('avg_full_resolution_time_secs', {
      Cell: ({ row }) => {
        const data = row.original.avg_full_resolution_time_secs;

        if (data === null) {
          return (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          );
        }

        const value = formatSecondsToReadableHrMinTime(data);

        return (
          <Box alignItems='center' columnGap={1} display='flex'>
            <Typography variant='font14'>{value}</Typography>
          </Box>
        );
      },
      header:
        TABLE_COLUMN_TO_LABEL_MAP.agent_ticket.avg_full_resolution_time_secs,
      size: 225,
    }),
    columnHelper.accessor('avg_time_to_first_reply_secs', {
      Cell: ({ row }) => {
        const data = row.original.avg_time_to_first_reply_secs;

        if (data === null) {
          return (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          );
        }

        const value = formatSecondsToReadableHrMinTime(data);

        return (
          <Box alignItems='center' columnGap={1} display='flex'>
            <Typography variant='font14'>{value}</Typography>
          </Box>
        );
      },
      header:
        TABLE_COLUMN_TO_LABEL_MAP.agent_ticket.avg_time_to_first_reply_secs,
      size: 250,
    }),
  ];

  return columns;
};
