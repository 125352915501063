import ReactMarkdown, { Components } from 'react-markdown';

import ArticleImage from './ArticleImage';
import { handleMarkdownNewTag, textIsHTML } from './helpers';
import HighlightedArticle from './HighlightedArticle';
import HTMLContent from './HTMLContent';

const ArticleContent = ({ content }: { content: string }) => {
  const isHTML = textIsHTML(content);
  if (isHTML) {
    return <HTMLContent content={content} />;
  }
  return (
    <ReactMarkdown
      components={
        {
          code: ({
            children,
            className,
            node,
          }: {
            children: string;
            className: string;
            node?: { data: { meta: string } };
          }) => {
            if (className === 'language-ftScript') {
              return (
                <HighlightedArticle
                  content={children || node?.data?.meta || ''}
                />
              );
            }
          },
          img: ({ src }: { src: string }) => {
            return <ArticleImage src={src} />;
          },
          pre: ({ children }: { children: string }) => {
            return <>{children}</>;
          },
        } as Partial<Components>
      }
    >
      {handleMarkdownNewTag(content)}
    </ReactMarkdown>
  );
};

export default ArticleContent;
