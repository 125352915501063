import { Divider, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import TicketDetailInsightLabels from './TicketDetailInsightLabels';
import TicketResponseList from './TicketResponseList';
import TicketId from 'src/components/global-discover/article/article-drawer/TicketId';
import TicketRequestItem from 'src/components/global-discover/article/article-drawer/TicketRequestItem';
import TicketTimeStamp from 'src/components/global-discover/article/article-drawer/TicketTimeStamp';
import Spinner from 'src/components/spinner';
import TicketMetrics from 'src/components/ticket-metrics';
import { TextDivider } from 'src/pages/intent-conversation-analytics/ConversationDrawer';
import { useGetTriageInsightTicketDetailQuery } from 'src/services/triage/triageApi';
interface TicketDrawerDetailsProps {
  ticket: {
    body: string | null;
    id: string;
    timestamp: number;
    title: string | null;
  } | null;
}
const TicketDrawerDetails = ({ ticket }: TicketDrawerDetailsProps) => {
  const { palette } = useTheme();
  const { data, isFetching, isLoading } = useGetTriageInsightTicketDetailQuery(
    ticket?.id || '',
    { skip: !ticket?.id },
  );

  if (isLoading || isFetching) {
    return (
      <Box display='flex' justifyContent='center'>
        <Spinner />
      </Box>
    );
  }

  if (!data || !ticket) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography>Ticket details not found.</Typography>
      </Box>
    );
  }

  return (
    <Box display='flex' flexDirection='column' rowGap={4}>
      <TicketId link={data?.link || ''} ticketId={ticket?.id || ''} />
      <Box
        borderRadius={1}
        display='flex'
        flexDirection='column'
        p={2}
        rowGap={2}
        sx={{ border: `1px solid ${palette.colors.slate[200]}` }}
      >
        <TicketMetrics
          metrics={{
            ...data,
            date_created: ticket.timestamp * 1000,
          }}
        />
        <Divider />
        <TicketDetailInsightLabels predictions={data.predictions} />
      </Box>
      <Box display='flex' flexDirection='column' gap={1}>
        <Box py={1}>
          <TextDivider size='large' text='Customer request' />
        </Box>
        <TicketTimeStamp dateCreated={ticket.timestamp * 1000} />
        <TicketRequestItem content={ticket.title} title='Subject' />
        <TicketRequestItem content={data.ticket_html_body} title='Body' />
      </Box>
      <TicketResponseList replies={data.replies} />
    </Box>
  );
};

export default TicketDrawerDetails;
