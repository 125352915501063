import { useMemo } from 'react';

import DocumentIndexTab from './document-index-tab/DocumentIndexTab';
import CollectionsPage from './CollectionsPage';
import ConnectionsPage from './ConnectionsPage';
import DashboardLayout from 'src/components/dashboard-layout/DashboardLayout';
import DashboardTabs from 'src/components/dashboard-tabs';

const IntegrationsTabPage = ({
  multiConnectorsEnabled,
}: {
  multiConnectorsEnabled: boolean;
}) => {
  const tabs = useMemo(
    () => [
      {
        Component: <ConnectionsPage multiConnectorsEnabled />,
        label: 'Connections',
        tabKey: 'connections',
      },
      {
        Component: <DocumentIndexTab />,
        label: 'Document index',
        tabKey: 'document-index',
      },
      {
        Component: (
          <CollectionsPage
            multiConnectorsEnabled={Boolean(multiConnectorsEnabled)}
          />
        ),
        label: 'Collections',
        tabKey: 'collections',
      },
    ],
    [multiConnectorsEnabled],
  );

  return (
    <DashboardLayout>
      <DashboardTabs
        enableTabKey
        name='integrations'
        tabParamsName='integrations-tab'
        tabs={tabs}
      />
    </DashboardLayout>
  );
};

export default IntegrationsTabPage;
