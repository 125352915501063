import { PolicyRecommendation } from 'src/types/DiscoverTypes';

export const verifyHasBrands = (policies?: PolicyRecommendation[]) =>
  policies?.some(policy => policy.api_data_filter);

export const toPolicyTableData = (policy: PolicyRecommendation) => ({
  api_data_filter: policy.api_data_filter,
  cost: policy.actionable_value?.cost || 0,
  created_date: policy.created_date,
  policy_description: policy.policy_description,
  row: policy,
  segmentation_api_data_filter: policy.segmentation_api_data_filter,
  topic_name: policy.topic.topic_name,
  volume: policy.actionable_value?.volume || 0,
});
