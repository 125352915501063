import { SOLVE_EMAIL_BASE_URL, SOLVE_V2_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import {
  ConversationDetailResponse,
  ConversationRequest,
} from '../insights/types';
import isNil from 'lodash/fp/isNil';
import omitBy from 'lodash/fp/omitBy';
import { WorkflowTypes } from 'src/pages/workflow-builder-edit/types';
import { SolveWidgetProduct } from 'src/types/types';
import {
  CanvasIntentsResponse,
  DecommissionWorkflowResponse,
  EmailConversationMetricBreakdown,
  GoToIntentUsagesResponse,
  HandoffConfigurationsResponse,
  Intent,
  IntentsResponse,
  IntentWorkflowMetadataResponse,
  MetricAggregateResponse,
  MetricBreakdownResponse,
  SolveEmailMetricsBase,
  SolveMetricsRequestQueryParams,
  WorkflowBuilderSettingsResponse,
} from 'src/types/workflowBuilderAPITypes';

const workflowBuilderMetricsApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    activateIntentWorkflow: builder.mutation<
      void,
      { intentWorkflowId: string; workflowTypes: Array<WorkflowTypes> }
    >({
      invalidatesTags: ['Intents', 'Tools'],
      query: ({ intentWorkflowId, workflowTypes }) => ({
        body: { workflow_types: workflowTypes },
        method: 'PUT',
        url:
          SOLVE_V2_BASE_URL +
          `/workflow-builder/workflow/${intentWorkflowId}/activate`,
      }),
    }),
    createWorkflowTags: builder.mutation<
      void,
      { brandId: string; tags: string[] }
    >({
      invalidatesTags: ['WorkflowTags', 'Brands'],
      query: ({ brandId, tags }) => ({
        body: tags,
        method: 'POST',
        url:
          SOLVE_V2_BASE_URL +
          `/workflow-builder/metrics/brand/${brandId}/workflow-tags`,
      }),
    }),
    deactivateIntentWorkflow: builder.mutation<
      DecommissionWorkflowResponse,
      { intentWorkflowId: string; workflowTypes: Array<WorkflowTypes> }
    >({
      invalidatesTags: ['Intents', 'Tools'],
      query: ({ intentWorkflowId, workflowTypes }) => ({
        body: { workflow_types: workflowTypes },
        method: 'PUT',
        url:
          SOLVE_V2_BASE_URL +
          `/workflow-builder/workflow/${intentWorkflowId}/deactivate`,
      }),
    }),
    deleteWorkflowTag: builder.mutation<void, { tagName: string }>({
      invalidatesTags: ['WorkflowTags', 'Brands', 'Intents'],
      query: ({ tagName }) => ({
        method: 'DELETE',
        url:
          SOLVE_V2_BASE_URL +
          '/workflow-builder/metrics/workflow-tags/' +
          tagName,
      }),
    }),
    getConnectableIntents: builder.query<
      CanvasIntentsResponse,
      { product?: SolveWidgetProduct }
    >({
      providesTags: ['Intents'],
      query: ({ product }) => ({
        params: { product },
        url: SOLVE_V2_BASE_URL + '/workflow-builder/intents',
      }),
    }),
    getEmailAggregateMetrics: builder.query<
      SolveEmailMetricsBase,
      SolveMetricsRequestQueryParams
    >({
      query: ({ from, to }) => ({
        params: { end_timestamp: to, start_timestamp: from },
        url: SOLVE_EMAIL_BASE_URL + '/conversation/metrics/aggregate',
      }),
    }),
    getEmailBreakdownMetrics: builder.query<
      EmailConversationMetricBreakdown,
      SolveMetricsRequestQueryParams
    >({
      query: ({ from, to }) => ({
        params: { end_timestamp: to, start_timestamp: from },
        url: SOLVE_EMAIL_BASE_URL + '/conversation/metrics/breakdown',
      }),
    }),
    getEmailConversation: builder.query<
      ConversationDetailResponse,
      ConversationRequest
    >({
      query: ({ conversation_id }) => ({
        url:
          SOLVE_EMAIL_BASE_URL +
          `/conversation/${conversation_id}/interactive-email-conversation`,
      }),
    }),
    getGoToIntentUsages: builder.query<GoToIntentUsagesResponse, void>({
      providesTags: ['IntentUsages'],
      query: () => ({
        url: SOLVE_V2_BASE_URL + '/go-to-intent-usages',
      }),
    }),
    getHandoffConfigurations: builder.query<
      HandoffConfigurationsResponse,
      void
    >({
      providesTags: ['HandoffConfigurations'],
      query: () => SOLVE_V2_BASE_URL + '/intent-handoff-configuration',
    }),
    getIntents: builder.query<
      IntentsResponse,
      {
        end_timestamp?: number | null;
        include_inquiry_counts?: boolean;
        product: SolveWidgetProduct;
        start_timestamp?: number | null;
      }
    >({
      providesTags: ['Intents'],
      query: ({
        end_timestamp = null,
        include_inquiry_counts = false,
        product,
        start_timestamp = null,
      }) => {
        const params = {
          end_timestamp,
          include_inquiry_counts,
          product,
          start_timestamp,
        };
        return {
          params: omitBy(isNil, params),
          url: SOLVE_V2_BASE_URL + '/intents',
        };
      },
    }),
    getIntentsForBrand: builder.query<
      Intent[],
      {
        brand_id: string;
        solve_widget_product: SolveWidgetProduct;
      }
    >({
      providesTags: ['BrandIntents'],
      query: ({ brand_id, solve_widget_product }) => {
        const params = {
          solve_widget_product,
        };
        return {
          params: omitBy(isNil, params),
          url: SOLVE_V2_BASE_URL + `/brand/${brand_id}/intents`,
        };
      },
    }),
    getIntentWorkflowMetadata: builder.query<
      IntentWorkflowMetadataResponse,
      void
    >({
      query: () => SOLVE_V2_BASE_URL + '/intent-workflows/metadata',
    }),
    getWidgetAggregateMetrics: builder.query<
      MetricAggregateResponse,
      SolveMetricsRequestQueryParams
    >({
      query: ({ channel, from, to }) => ({
        params: {
          channel: channel || 'widget',
          end_timestamp: to,
          start_timestamp: from,
        },
        url: SOLVE_V2_BASE_URL + '/workflow-builder/metrics/aggregate',
      }),
    }),
    getWidgetBreakdownMetrics: builder.query<
      MetricBreakdownResponse,
      SolveMetricsRequestQueryParams
    >({
      providesTags: ['WidgetBreakdownMetrics'],
      query: ({ channel, from, tags, to }) => ({
        params: {
          channel: channel || 'widget',
          end_timestamp: to,
          start_timestamp: from,
          tags: tags?.join(','),
        },
        url: SOLVE_V2_BASE_URL + '/workflow-builder/metrics/breakdown',
      }),
    }),
    getWorkflowTagsV2: builder.query<string[], void>({
      providesTags: ['WorkflowTags'],
      query: () =>
        SOLVE_V2_BASE_URL + '/workflow-builder/metrics/workflow-tags/v2',
    }),
    initWorkflowBuilderSettings: builder.query<
      WorkflowBuilderSettingsResponse,
      void
    >({
      query: () => ({
        body: {},
        method: 'PUT',
        url: SOLVE_V2_BASE_URL + '/workflow-builder/settings/workflow-builder',
      }),
    }),
    swapWorkflowTags: builder.mutation<void, { brandId: string; tag: string }>({
      invalidatesTags: ['WorkflowTags', 'Brands'],
      query: ({ brandId, tag }) => ({
        method: 'POST',
        url:
          SOLVE_V2_BASE_URL +
          `/workflow-builder/metrics/brand/${brandId}/workflow-tags/${tag}/swap`,
      }),
    }),
  }),
});

export const {
  useActivateIntentWorkflowMutation,
  useCreateWorkflowTagsMutation,
  useDeactivateIntentWorkflowMutation,
  useDeleteWorkflowTagMutation,
  useGetConnectableIntentsQuery,
  useGetContextVariablesQuery,
  useGetEmailAggregateMetricsQuery,
  useGetEmailBreakdownMetricsQuery,
  useGetEmailConversationQuery,
  useGetGoToIntentUsagesQuery,
  useGetHandoffConfigurationsQuery,
  useGetIntentsForBrandQuery,
  useGetIntentsQuery,
  useGetIntentWorkflowMetadataQuery,
  useGetWidgetAggregateMetricsQuery,
  useGetWidgetBreakdownMetricsQuery,
  useGetWorkflowTagsV2Query,
  useInitWorkflowBuilderSettingsQuery,
  useSwapWorkflowTagsMutation,
} = workflowBuilderMetricsApi;
