import moment from 'moment';

import { ConnectorDocumentParams } from 'src/services/connector/types';
import { DateRange } from 'src/types/types';

export const createDocumentsQuery = ({
  dateRange,
}: {
  dateRange: DateRange;
}): ConnectorDocumentParams => {
  return {
    cursor: '',
    documentType: 'knowledge_article',
    end_created_date: dateRange.to?.getSeconds() || 0,
    integration: '',
    public: true,
    sort_column: 'created_date',
    sort_direction: 'desc',
    source_id: '',
    start_created_date: dateRange.from?.getSeconds() || 0,
    title: '',
  };
};

export const formatReadableDate = (timestamp: number): string => {
  const milliseconds = timestamp < 1e12 ? timestamp * 1000 : timestamp;
  return moment(milliseconds).format('MMM D, YYYY, h:mm a');
};
