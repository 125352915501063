import {
  DiscoverArticle,
  KnowledgeGapArticleRecommendation,
} from 'src/reducers/discoverReducer/types';

export const verifyValues = (article: KnowledgeGapArticleRecommendation) =>
  Boolean(article && (article.summary || article.relevant_articles?.length));

export const createHrsText = (hrs: string) => {
  if (hrs === '1.0') {
    return '1.0 hr';
  }
  return `${hrs} hrs`;
};

export const removeNewTag = (content?: string | null) => {
  if (!content) {
    return '';
  }
  return content
    .replace('<NEW>,', '')
    .replace('<NEW>', '')
    .replace(',</NEW>', '')
    .replace('</NEW>', '');
};

export const getArticleContent = (
  isV2: boolean | undefined,
  article: KnowledgeGapArticleRecommendation | DiscoverArticle,
): { generated_body: string; generated_title: string } | null => {
  if (!isV2) {
    return article;
  }
  if ('updated_article_body' in article && 'updated_article_title' in article) {
    return {
      generated_body: removeNewTag(article.updated_article_body) || '',
      generated_title: article.updated_article_title || '',
    };
  }
  return null;
};

export const getArticleBody = (
  article: KnowledgeGapArticleRecommendation,
  generatedBody: string,
  isV2: boolean | undefined,
) => {
  if (isV2) {
    return article.updated_article_body || `<NEW> ${generatedBody} </NEW>`;
  }
  return generatedBody;
};

export const getArticleTitle = (
  article: KnowledgeGapArticleRecommendation,
  generatedTitle: string,
  isV2: boolean | undefined,
) => {
  if (isV2 && article.updated_article_body) {
    return article.updated_article_title;
  }
  return generatedTitle;
};

export const handleMarkdownNewTag = (content?: string | null) => {
  if (!content) {
    return '';
  }
  if (content.includes('<NEW></NEW>')) {
    return content.replace('<NEW></NEW>', '');
  }
  return content.replace('<NEW>', '\n```ftScript ').replace('</NEW>', '\n```');
};

export const textIsHTML = (textContent: string) => {
  const text = textContent.trim();
  if (text.indexOf('<NEW>') === 0) {
    return false;
  }
  if (text.indexOf('<') === 0 && text[text.length - 1] === '>') {
    return true;
  }
  return false;
};
