import moment from 'moment';

import {
  AddActionData,
  ArticleStepData,
  AttachmentAnalysisUploadStepData,
  ButtonsStepData,
  ConditionStepData,
  CsatTriggerPointStepData,
  EmbedStepData,
  FormStepData,
  GoToIntentData,
  GroupInsertionData,
  HandoffConfiguration,
  ImageStepData,
  RestartStepData,
  StepUpdateData,
  TextMessageData,
} from '../../slices/canvas-workflow-builder/types/dataTypes';
import { sendAuthRequest } from '../api';
import { getLandingPageDataWithTopics } from './utils';
import { WorkflowTypes } from 'src/pages/workflow-builder-edit/types';
import store from 'src/store/store';
import { SolveWidgetProduct } from 'src/types/types';
import {
  ActionSettingsCustomization,
  ArticlesResponse,
  BranchingStepMetricsResponse,
  CanvasResponse,
  ChannelsResponse,
  ConfigurationTranslation,
  ContextVariablesStep,
  ConversationChannel,
  CreateAssetLinkResponse,
  CreateCustomHandoffRequest,
  CreateWidgetConfigAssetBody,
  DecommissionWorkflowResponse,
  GetConfigurationTranslationsForOrgRequest,
  GetConfigurationTranslationsForOrgResponse,
  GetStepTranslationsForOrgRequest,
  GetStepTranslationsForOrgResponse,
  GetStepTranslationsForStepRequest,
  HandoffConfigurationsResponse,
  Intent,
  LandingPageHelperAPIResponse,
  LanguagesEnabledRequest,
  LanguagesEnabledResponse,
  LanguagesResponse,
  MacroSearchResponse,
  MetricAggregateResponse,
  MetricBreakdownResponse,
  MetricDataTypesResponse,
  ModelTrainingResponse,
  OnboardingFlagsResponse,
  OverrideConfigurationTranslationRequest,
  OverrideTranslationRequest,
  Phrase,
  SolveEmbeddedDashboardResponse,
  StepMetricsResponse,
  StepTranslation,
  SuggestedIntentsResponse,
  UpdateOnboardingFlagsRequest,
  WhitelistedDomainsResponse,
  WidgetApiKeyResponse,
  WidgetConfigResponse,
  WorkflowBuilderLandingPageData,
  WorkflowVersionsResponse,
  ZendeskHandoffCustomization,
  ZendeskTicketCreationCustomization,
} from 'src/types/workflowBuilderAPITypes';
import {
  ActionBuilderActionTypes,
  TranslationsTableMode,
} from 'src/utils/enums';
import { normalizeResponseErrors } from 'src/utils/normalizeResponse';

export const getChannels = (): Promise<ChannelsResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/channels`,
    null,
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getMetricDataTypes = (): Promise<MetricDataTypesResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/metrics/data-types`,
    null,
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

const buildMetricQueryString = (): string => {
  const state = store.getState();
  const { pageSettings, workflowBuilder } = state;
  const { timeRange } = pageSettings;
  const { selectedChannel } = workflowBuilder;

  const start_timestamp = timeRange.from ? moment(timeRange.from).unix() : 0;
  const end_timestamp = timeRange.to ? moment(timeRange.to).unix() : 9999999999;

  let queryString = `start_timestamp=${start_timestamp}&end_timestamp=${end_timestamp}`;
  if (!!selectedChannel) {
    queryString = `${queryString}&channel=${selectedChannel}`;
  }

  return queryString;
};

export const getMetricAggregate = (): Promise<MetricAggregateResponse> => {
  const queryString = buildMetricQueryString();
  return getMetricAggregateFromMongo(queryString);
};

const getMetricAggregateFromMongo = (
  queryString: string,
): Promise<MetricAggregateResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/metrics/aggregate?${queryString}`,
    null,
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getMetricBreakdown = (): Promise<MetricBreakdownResponse> => {
  const queryString = buildMetricQueryString();

  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/metrics/breakdown?${queryString}`,
    null,
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

const combineWorkflowBuilderLandingPageResponses = (
  responses: Array<LandingPageHelperAPIResponse>,
): Promise<WorkflowBuilderLandingPageData> | WorkflowBuilderLandingPageData => {
  const userState = store.getState().user;

  // @ts-expect-error TODO: looks like this will always be false
  const isDiscoverEnabled = userState.paidPlan?.enablement_info.discover;

  const combinedResponse: WorkflowBuilderLandingPageData = Object.assign(
    {
      aggregate: {},
      breakdown: [],
      channels: [],
      data_types: {},
    },
    ...responses,
  );

  if (isDiscoverEnabled) {
    return getLandingPageDataWithTopics(combinedResponse);
  }
  return combinedResponse;
};

export const getWorkflowBuilderLandingDataAPI =
  async (): Promise<WorkflowBuilderLandingPageData> => {
    const responses = await Promise.all([
      getChannels(),
      getMetricDataTypes(),
      getMetricAggregate(),
      getMetricBreakdown(),
    ]);

    return combineWorkflowBuilderLandingPageResponses(responses);
  };

export const createIntentWorkflowAPI: (
  name: string,
  phrases: Phrase[],
  description?: string,
  suggested_intent_id?: string | null,
  handoff_configuration?: CreateCustomHandoffRequest,
  workflow_tags?: string[],
  is_all_tags?: boolean,
) => Promise<CanvasResponse> = async (
  name,
  phrases,
  description,
  suggested_intent_id,
  handoff_configuration,
  workflow_tags,
  is_all_tags,
) => {
  const data: {
    handoff_configuration?: CreateCustomHandoffRequest;
    intent_description?: string;
    is_all_tags?: boolean;
    name: string;
    phrases: Phrase[];
    suggested_intent_id?: string | null;
    workflow_tags?: string[];
  } = {
    intent_description: description,
    name,
    phrases,
  };

  if (suggested_intent_id) {
    data.suggested_intent_id = suggested_intent_id;
  }
  if (handoff_configuration) {
    data.handoff_configuration = handoff_configuration;
  }
  if (workflow_tags) {
    data.workflow_tags = workflow_tags;
  }

  if (is_all_tags) {
    data.is_all_tags = is_all_tags;
  }

  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/workflow`,
    data,
    'POST',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateIntentAPI: ({
  description,
  intent_id,
  name,
  phrases,
}: {
  description?: string;
  intent_id: string;
  name: string;
  phrases: Phrase[];
}) => Promise<Intent> = async ({ description, intent_id, name, phrases }) => {
  const data = {
    description,
    name,
    phrases,
  };

  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/intent/${intent_id}`,
    data,
    'PUT',
    { workflow_id: intent_id },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const duplicateIntentWorkflowAPI = (
  workflowId: string,
): Promise<CanvasResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/workflow/${workflowId}/duplicate`,
    {},
    'POST',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const duplicateToProductAPI = (
  intentId: string,
  product: SolveWidgetProduct,
): Promise<CanvasResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/intent/${intentId}/${product}/duplicate`,
    {},
    'POST',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const acceptProductWorkflowSuggestionAPI = ({
  intentId,
  product,
  shouldStartFromScratch,
}: {
  intentId: string;
  product: SolveWidgetProduct;
  shouldStartFromScratch: boolean;
}): Promise<CanvasResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/intent/${intentId}/${product}/accept-suggestion?start_from_scratch=${shouldStartFromScratch}`,
    {},
    'POST',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const activateIntentWorkflowAPI = (
  intentWorkflowId: string,
  workflowTypes: Array<WorkflowTypes>,
): Promise<void> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/workflow/${intentWorkflowId}/activate`,
    { workflow_types: workflowTypes },
    'PUT',
    { workflow_id: intentWorkflowId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const deactivateIntentWorkflowAPI = (
  intentWorkflowId: string,
  workflowTypes: Array<WorkflowTypes>,
): Promise<DecommissionWorkflowResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/workflow/${intentWorkflowId}/deactivate`,
    { workflow_types: workflowTypes },
    'PUT',
    { workflow_id: intentWorkflowId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getWorkflowBuilderWidgetConfigAPI: () => Promise<WidgetConfigResponse> =
  async () => {
    return sendAuthRequest(
      `${API_URL}dashboard-controls/solve/v2/workflow-builder/widget-config`,
      null,
      'GET',
    )
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const getWorkflowBuilderLanguagesAPI: () => Promise<LanguagesResponse> =
  async () => {
    return sendAuthRequest(
      `${API_URL}dashboard-controls/solve/v2/workflow-builder/languages`,
      null,
      'GET',
    )
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const getWorkflowBuilderLanguagesEnabledAPI = (
  channel: ConversationChannel,
): Promise<LanguagesEnabledResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/languages-enabled?channel=${channel}`,
    null,
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateWorkflowBuilderLanguagesEnabledAPI = (
  data: LanguagesEnabledRequest,
): Promise<LanguagesEnabledResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/languages-enabled`,
    data,
    'PUT',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const overrideTranslationsAPI = (
  overrideRequests: OverrideTranslationRequest[],
  selectedLanguage: string,
  mode: TranslationsTableMode,
): Promise<GetStepTranslationsForOrgResponse> => {
  const data = {
    language_code: selectedLanguage,
    mode: mode,
    override_requests: overrideRequests,
  };
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/translations/override`,
    data,
    'PUT',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const overrideTranslationsForStepAPI = (
  overrideRequests: OverrideTranslationRequest[],
  selectedLanguage: string,
  intentWorkflowId: string,
  stepId: string,
): Promise<GetStepTranslationsForOrgResponse> => {
  const data = {
    intent_workflow_id: intentWorkflowId,
    language_code: selectedLanguage,
    override_requests: overrideRequests,
    step_id: stepId,
  };
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/translations/override`,
    data,
    'PUT',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const restoreTranslationAPI = (
  mode: TranslationsTableMode,
  intent_workflow_id: string,
  step_id: string,
  element_path: string,
  language_code: string,
): Promise<StepTranslation> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/translations/override/${mode}/${intent_workflow_id}/${step_id}/${encodeURIComponent(
      element_path,
    )}/${language_code}`,
    null,
    'DELETE',
    {
      element_path: element_path,
      intent_workflow_id: intent_workflow_id,
      language_code: language_code,
      step_id: step_id,
    },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getStepTranslationsForOrgAPI = (
  data: GetStepTranslationsForOrgRequest,
): Promise<GetStepTranslationsForOrgResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/step-element-translations/${data.mode}/${data.language_code}`,
    data,
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getStepTranslationsForStepAPI = (
  data: GetStepTranslationsForStepRequest,
): Promise<GetStepTranslationsForOrgResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/step-element-translations/${data.language_code}/${data.intent_workflow_id}/${data.step_id}`,
    data,
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const overrideConfigurationTranslationsAPI = (
  overrideRequests: OverrideConfigurationTranslationRequest[],
  selectedLanguage: string,
): Promise<GetConfigurationTranslationsForOrgResponse> => {
  const data = {
    language_code: selectedLanguage,
    override_requests: overrideRequests,
  };
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/configuration-translations/override`,
    data,
    'PUT',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const restoreConfigurationTranslationAPI = (
  configuration_id: string,
  language_code: string,
  source_text: string | null,
  intent_id: string | null,
  configuration_path: string | null,
  brandId: string | null,
): Promise<ConfigurationTranslation> => {
  const url = new URL(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/translations/override/${configuration_id}/${language_code}`,
  );
  if (brandId) {
    url.searchParams.append('brand_id', brandId);
  }

  if (source_text) {
    url.searchParams.append('source_text', source_text);
  } else if (intent_id) {
    url.searchParams.append('intent_id', intent_id);
  } else if (configuration_path) {
    url.searchParams.append('configuration_path', configuration_path);
  }

  return sendAuthRequest(url.toString(), null, 'DELETE', {
    configuration_id: configuration_id,
    configuration_path: configuration_path,
    intent_id: intent_id,
    language_code: language_code,
    source_text: source_text,
  })
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getConfigurationTranslationsForOrgAPI = (
  data: GetConfigurationTranslationsForOrgRequest,
): Promise<GetConfigurationTranslationsForOrgResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/configuration-translations/${data.language_code}`,
    data,
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const createAssetUploadLinkAPI = (
  data: CreateWidgetConfigAssetBody,
): Promise<CreateAssetLinkResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/widget-config/asset-upload-link`,
    data,
    'POST',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const deleteAssetAPI = async (fileName: string): Promise<Response> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/widget-config/asset-delete?image_url=${fileName}`,
    {},
    'DELETE',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const confirmAssetUploadComplete = (asset_id: string): Promise<void> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/widget-config/confirm-asset/${asset_id}`,
    null,
    'PUT',
  ).then((res: Response | { error: string }) => normalizeResponseErrors(res));
};

export const getWorkflowBuilderSuggestedIntentsAPI: () => Promise<SuggestedIntentsResponse> =
  async () => {
    return sendAuthRequest(
      `${API_URL}dashboard-controls/solve/v2/workflow-builder/suggested-improvements`,
      null,
      'GET',
    )
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const getDraftWorkflow = (
  intentWorkflowId: string,
): Promise<CanvasResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${intentWorkflowId}`,
    {},
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getLatestWorkflowVersionsAPI = (
  intentWorkflowId: string,
): Promise<WorkflowVersionsResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${intentWorkflowId}/versions/latest`,
    {},
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getWorkflowVersionAPI = (
  intentWorkflowId: string,
  version: number,
): Promise<CanvasResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${intentWorkflowId}/${version}`,
    {},
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const addStepToWorkflow = (
  intentWorkflowId: string,
  version: number,
  data:
    | ArticleStepData
    | TextMessageData
    | ButtonsStepData
    | FormStepData
    | GoToIntentData
    | RestartStepData
    | ConditionStepData
    | ImageStepData
    | EmbedStepData
    | CsatTriggerPointStepData
    | AttachmentAnalysisUploadStepData,
): Promise<CanvasResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${intentWorkflowId}/${version}/step`,
    data,
    'POST',
    { workflow_id: intentWorkflowId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const insertGroupOfStepsAPI = ({
  data,
  intentWorkflowId,
  version,
}: {
  data: GroupInsertionData;
  intentWorkflowId: string;
  version: number;
}): Promise<CanvasResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${intentWorkflowId}/${version}/group`,
    data,
    'POST',
    { ...data },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateWorkflowStepAPI = ({
  data,
  intentWorkflowId,
  stepId,
  version,
}: {
  data: StepUpdateData;
  intentWorkflowId: string;
  stepId: string;
  version: number;
}): Promise<CanvasResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${intentWorkflowId}/${version}/step/${stepId}`,
    {
      ...data,

      // If the value is an empty string, we have to send `null`:
      condition_name: data.condition_name || null,
    },
    'PUT',
    { step_id: stepId, workflow_id: intentWorkflowId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const deleteWorkflowStepAPI = (
  intentWorkflowId: string,
  version: number,
  stepId: string,
): Promise<CanvasResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${intentWorkflowId}/${version}/step/${stepId}`,
    null,
    'DELETE',
    { step_id: stepId, workflow_id: intentWorkflowId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const deleteWorkflowStepAndBelowAPI = (
  intentWorkflowId: string,
  version: number,
  stepId?: string,
  canvasActionId?: string,
): Promise<CanvasResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${intentWorkflowId}/${version}/delete-step-and-subsequent-steps`,
    { canvas_action_id: canvasActionId, step_id: stepId },
    'PUT',
    {
      canvas_action_id: canvasActionId,
      step_id: stepId,
      workflow_id: intentWorkflowId,
    },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const deleteWorkflowActionAPI = (
  intentWorkflowId: string,
  version: number,
  actionId: string,
): Promise<CanvasResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${intentWorkflowId}/${version}/action/${actionId}`,
    null,
    'DELETE',
    { action_id: actionId, workflow_id: intentWorkflowId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const saveWorkflowVersionApi = ({
  intentWorkflowId,
  version,
}: {
  intentWorkflowId: string;
  version: number;
}): Promise<CanvasResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${intentWorkflowId}/${version}/save`,
    {},
    'POST',
    { version, workflow_id: intentWorkflowId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const discardDraftWorkflowAPI = (
  intentWorkflowId: string,
  version: number,
): Promise<CanvasResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${intentWorkflowId}/${version}/discard`,
    {},
    'PUT',
    { version, workflow_id: intentWorkflowId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getContextVariablesStepApi = (
  intentWorkflowId: string,
  version: number,
  stepId: string,
): Promise<ContextVariablesStep> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${intentWorkflowId}/${version}/context-variables?step_id=${stepId}`,
    {},
    'GET',
    { step_id: stepId, workflow_id: intentWorkflowId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const addActionApi = (
  intentWorkflowId: string,
  version: number,
  data: AddActionData,
): Promise<CanvasResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${intentWorkflowId}/${version}/action`,
    data,
    'POST',
    { workflow_id: intentWorkflowId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getIntentAPI = (intentId: string): Promise<Intent> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/intent/${intentId}`,
    {},
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const setDefaultHandoffAPI = (
  intentId: string,
  product: SolveWidgetProduct,
): Promise<HandoffConfiguration> => {
  // we don't allow setting default handoff to false, because in that case the org would have no default handoff
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/intent/${intentId}/handoff-configuration`,
    { is_default_handoff: true, product },
    'PUT',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getHandoffCustomizationAPI = (
  workflowId: string,
  actionType: ActionBuilderActionTypes,
): Promise<ZendeskHandoffCustomization> => {
  // Set `integration` parameter only for Salesforce to keep backwards
  // compatibility (can be set unconditionally once
  // `granularized_salesforce_actions` becomes generally available):
  const queryString = `?workflow_action_type=${actionType}`;

  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/actions/default${queryString}`,
    {},
    'GET',
    { workflow_id: workflowId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateHandoffCustomizationAPI = (
  handoffData: ZendeskHandoffCustomization | ZendeskTicketCreationCustomization,
  workflowId: string,
): Promise<ZendeskHandoffCustomization> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/actions/handoff/default`,
    handoffData,
    'PUT',
    { workflow_id: workflowId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getActionSettingsAPI = (
  workflowId: string,
  canvasActionId: string,
  version: number,
): Promise<ActionSettingsCustomization> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${workflowId}/${version}/action/${canvasActionId}`,
    {},
    'GET',
    { workflow_id: workflowId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateActionSettingsAPI = (
  workflowId: string,
  canvasActionId: string,
  handoffData: ActionSettingsCustomization,
  version: number,
  isEntryStep: boolean,
  parentStepId: string,
): Promise<CanvasResponse> => {
  const data = {
    is_entry_step: isEntryStep,
    parent_step_id: parentStepId,
    ...handoffData,
  };
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${workflowId}/${version}/action/${canvasActionId}`,
    data,
    'PUT',
    { canvas_action_id: canvasActionId, workflow_id: workflowId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getHandoffConfigurationsAPI =
  (): Promise<HandoffConfigurationsResponse> => {
    return sendAuthRequest(
      `${API_URL}dashboard-controls/solve/v2/intent-handoff-configuration`,
      {},
      'GET',
    )
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const getWidgetApiKeyAPI = (): Promise<WidgetApiKeyResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/widget-api-key`,
    {},
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const refreshWidgetApiKeyAPI = (): Promise<WidgetApiKeyResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/widget-api-key`,
    {},
    'POST',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getSolveEmbeddedDashboardURL =
  (): Promise<SolveEmbeddedDashboardResponse> => {
    return sendAuthRequest(
      `${API_URL}dashboard-controls/solve/v2/workflow-builder/analytics/embed`,
      {},
      'GET',
    )
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const getModelTrainingAPI = (): Promise<ModelTrainingResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/model-training`,
    {},
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getArticlesAPI = (
  query: string | null,
  page: number,
): Promise<ArticlesResponse> => {
  const url = new URL(`${API_URL}dashboard-controls/solve/v2/articles`);
  if (query != null) {
    url.searchParams.append('query', query);
  }
  if (page != null) {
    url.searchParams.append('page', page.toString());
  }

  return sendAuthRequest(url.toString(), {}, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getMacrosAPI = (
  query: string | null,
  page: number,
): Promise<MacroSearchResponse> => {
  const url = new URL(`${API_URL}dashboard-controls/solve/v2/macros`);
  if (query != null) {
    url.searchParams.append('query', query);
  }
  if (page != null) {
    url.searchParams.append('page', page.toString());
  }

  return sendAuthRequest(url.toString(), {}, 'GET')
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const getWhitelistedDomainsAPI =
  (): Promise<WhitelistedDomainsResponse> => {
    const url = new URL(
      `${API_URL}dashboard-controls/solve/v2/whitelisted-domains`,
    );

    return sendAuthRequest(url.toString(), {}, 'GET')
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const updateWhitelistedDomainsAPI = (
  domains: string[],
): Promise<WhitelistedDomainsResponse> => {
  const url = new URL(
    `${API_URL}dashboard-controls/solve/v2/whitelisted-domains`,
  );
  return sendAuthRequest(url.toString(), { whitelist: domains }, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getStepMetricsAPI = async (
  workflowId: string,
  version: number,
  stepId: string,
  timePeriod: string,
): Promise<StepMetricsResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${workflowId}/${version}/step/${stepId}/metrics/${timePeriod}`,
    {},
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getBranchingStepMetricsAPI = async (
  workflowId: string,
  version: number,
  stepId: string,
  timePeriod: string,
): Promise<BranchingStepMetricsResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/${workflowId}/${version}/step/${stepId}/buttons/metrics/${timePeriod}`,
    {},
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getOnboardingFlagsAPI = (): Promise<OnboardingFlagsResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/onboarding-flags`,
    {},
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateOnboardingFlagsAPI = (
  onboardingFlags: UpdateOnboardingFlagsRequest,
): Promise<OnboardingFlagsResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/onboarding-flags`,
    onboardingFlags,
    'PATCH',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};
