import { useEffect } from 'react';
import { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

import IntegrationsPage from './IntegrationsPage';
import IntegrationsTabPage from './IntegrationsTabPage';
import useOrgConfig from 'src/hooks/discover/useOrgConfig';
import * as API from 'src/services/apiV1';
import { getUserRole, UserRoles } from 'src/utils/enums';

const IntegrationsMain = () => {
  const role = getUserRole();
  const orgConfig = useOrgConfig();

  const [multiConnectorsEnabled, setMultiConnectorsEnabled] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    const fetchMultiConnectorsStatus = async () => {
      try {
        const enabled = await API.getIsMultiConnectorsEnabled();
        setMultiConnectorsEnabled(enabled);
      } catch (error) {
        console.error('Error fetching multi-connectors status:', error);
        setMultiConnectorsEnabled(false);
      }
    };

    fetchMultiConnectorsStatus();
  }, []);

  if (multiConnectorsEnabled === null) {
    return (
      <Box
        alignItems='center'
        display='flex'
        height='100%'
        justifyContent='center'
      >
        <CircularProgress />
      </Box>
    );
  }
  if (
    role === UserRoles.ROLE_SUPER_ADMIN &&
    orgConfig.is_document_index_page_enabled
  ) {
    return (
      <IntegrationsTabPage multiConnectorsEnabled={multiConnectorsEnabled} />
    );
  }

  return <IntegrationsPage multiConnectorsEnabled={multiConnectorsEnabled} />;
};

export default IntegrationsMain;
