import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import {
  DocumentDetails,
  FeatureFlagsResponse,
  FreshChatGroupsResponse,
  GetDocumentListParams,
  GetDocumentListResponse,
  StandardizedDocType,
} from '../apiInterfaces';
import { SOLVE_V2_BASE_URL } from '../constants';
import defaultHeaders from '../defaultHeaders';
import {
  DashboardPermissions,
  DataExportRequest,
  DataExportResponse,
} from './types';
import _omit from 'lodash/fp/omit';
import { Helpdesk } from 'src/components/app/types';
import { PaidPlanResponse } from 'src/reducers/userReducer/types';
import { SolveWidgetProduct } from 'src/types/types';
import {
  ContextVariablesResponse,
  FredeshdeskGroup,
  FreshChatField,
  FreshdeskFieldsResponse,
  IntercomFieldsResponse,
  ProductMentionField,
  WidgetConfigResponse,
} from 'src/types/workflowBuilderAPITypes';
import { getCognitoToken } from 'src/utils/authUtils';
import {
  getSelectedOrganizationForSuperAdmin,
  getUserRole,
  UserRoles,
} from 'src/utils/enums';

const MAX_RETRIES = 1;

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: async headers => {
    // Add Authorization header with aws-amplify idToken
    try {
      const bearerToken = await getCognitoToken();
      headers.set('Authorization', `Bearer ${bearerToken}`);
    } catch (e) {
      console.error(`Error getting idToken: ${e}`);
    }

    // For superadmin users, add the X-ORG-ID header
    if (getUserRole() === UserRoles.ROLE_SUPER_ADMIN) {
      const selectedOrgId =
        getSelectedOrganizationForSuperAdmin()?.org_id || '';
      headers.set('X-ORG-ID', selectedOrgId);
    }

    const editUuid = defaultHeaders.get('edit-session-uuid');
    if (!!editUuid) {
      headers.set('edit-session-uuid', editUuid);
    }

    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: MAX_RETRIES });

export const dashboardApi = createApi({
  baseQuery: baseQueryWithRetry,
  endpoints: builder => ({
    dataExport: builder.mutation<DataExportResponse, DataExportRequest>({
      query: ({ body }) => {
        return {
          body,
          method: 'POST',
          url: '/data-export',
        };
      },
    }),
    getContextVariables: builder.query<ContextVariablesResponse, void>({
      providesTags: ['ContextVariables'],
      query: () => SOLVE_V2_BASE_URL + '/workflow-builder/context-variables',
    }),
    getContextVariablesById: builder.query<
      { data: string[] },
      { cv_id?: string; end: number; start: number }
    >({
      query: params => ({
        params,
        url: SOLVE_V2_BASE_URL + '/context-variables',
      }),
    }),
    getDocumentDetails: builder.query<
      DocumentDetails,
      {
        standardizedDocumentId: string;
        standardizedDocumentType: StandardizedDocType;
      }
    >({
      query: ({ standardizedDocumentId, standardizedDocumentType }) => ({
        method: 'GET',
        url: `ssel/connectors/documents/${standardizedDocumentType}/${standardizedDocumentId}`,
      }),
    }),
    getDocumentList: builder.query<
      GetDocumentListResponse,
      GetDocumentListParams
    >({
      forceRefetch: ({ currentArg, previousArg }) => {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCacheData, responseData, otherArgs) => {
        if (currentCacheData.cursor === responseData.cursor) {
          return;
        }
        if (otherArgs.arg.cursor) {
          currentCacheData.documents = currentCacheData.documents.concat(
            responseData.documents,
          );
          currentCacheData.cursor = responseData.cursor;
        } else {
          currentCacheData.documents = responseData.documents;
          currentCacheData.cursor = responseData.cursor;
        }
      },
      query: ({
        cursor,
        endCreatedDate,
        integration,
        isPublic,
        sortColumn = 'created_date',
        sortDirection = 'desc',
        sourceId,
        standardizedDocumentType,
        startCreatedDate,
        title,
      }) => {
        const entries = {
          cursor,
          end_created_date: endCreatedDate,
          integration,
          public: isPublic,
          sort_column: sortColumn,
          sort_direction: sortDirection,
          source_id: sourceId,
          start_created_date: startCreatedDate,
          title,
        };
        const params = Object.entries(entries).reduce((prev, current) => {
          const [key, value] = current;
          if (!value) {
            return prev;
          }
          return prev.concat(`${key}=${value}&`);
        }, '');

        return {
          method: 'GET',
          url: `ssel/connectors/documents/${standardizedDocumentType}?${params.slice(
            0,
            -1,
          )}`,
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return _omit(['cursor'], queryArgs);
      },
    }),
    getFeatureFlags: builder.query<FeatureFlagsResponse, void>({
      query: () => SOLVE_V2_BASE_URL + '/feature-flags',
    }),
    getFreshchatGroups: builder.query<Array<FredeshdeskGroup>, string>({
      query: (api_url: string) =>
        SOLVE_V2_BASE_URL +
        `/freshchat-groups?api_url=${encodeURIComponent(api_url)}`,
      transformResponse: (response: FreshChatGroupsResponse) => response.groups,
    }),
    getFreshdeskFields: builder.query<Array<FreshChatField>, string>({
      query: (apiUrl: string) =>
        SOLVE_V2_BASE_URL +
        `/freshchat-fields?api_url=${encodeURIComponent(apiUrl)}`,
      transformResponse: (response: FreshdeskFieldsResponse) => response.fields,
    }),
    getHelpdesk: builder.query<{ helpdesk: Helpdesk }, void>({
      query: () => ({ method: 'POST', url: '/helpdesk' }),
    }),
    getIntercomFields: builder.query<IntercomFieldsResponse, void>({
      query: () => SOLVE_V2_BASE_URL + '/intercom-ticket-fields',
    }),
    getPaidPlanDetails: builder.query<PaidPlanResponse, void>({
      query: () => ({ method: 'GET', url: 'get_paid_plan_details' }),
    }),
    getPermissions: builder.query<Record<string, boolean>, void>({
      query: () => ({ method: 'POST', url: 'dashboard-applications' }),
    }),
    getPermissionsByRole: builder.query<DashboardPermissions, void>({
      query: () => 'dashboard-permissions',
    }),
    getProductMentionFields: builder.query<
      ProductMentionField[],
      { product: SolveWidgetProduct }
    >({
      query: ({ product }) =>
        SOLVE_V2_BASE_URL + `/workflow-builder/${product}/mention-fields`,
    }),
    getWidgetConfig: builder.query<WidgetConfigResponse, void>({
      query: () => SOLVE_V2_BASE_URL + '/workflow-builder/widget-config',
    }),
    requestPlanTierUpgrade: builder.mutation<void, { message: string }>({
      query: ({ message }) => ({
        body: { message },
        method: 'POST',
        url: 'plan-tier-upgrade-request',
      }),
    }),
  }),
  reducerPath: 'dashboardApi',
  tagTypes: [
    'AutopilotWorkflow',
    'ContextVariables',
    'Automations',
    'Intents',
    'IntentTests',
    'BrandIntents',
    'IntentTestUsage',
    'HandoffConfigurations',
    'TriageModelDetail',
    'WorkflowTags',
    'WidgetBreakdownMetrics',
    'Actions',
    'AutoflowWorkflow',
    'Tools',
    'AuthConfigs',
    'IntentTests',
    'IntentUsages',
    'RedactionProductBlacklist',
    'IRReport',
    'SolveApiToken',
    'SolveApiConfig',
    'ContextVariableUsages',
    'ActionUsages',
    'Simulation',
    'EmailSettings',
    'Brands',
  ],
});

export const {
  useDataExportMutation,
  useGetContextVariablesByIdQuery,
  useGetContextVariablesQuery,
  useGetDocumentDetailsQuery,
  useGetDocumentListQuery,
  useGetFeatureFlagsQuery,
  useGetFreshdeskFieldsQuery,
  useGetHelpdeskQuery,
  useGetIntercomFieldsQuery,
  useGetPaidPlanDetailsQuery,
  useGetPermissionsByRoleQuery,
  useGetPermissionsQuery,
  useGetProductMentionFieldsQuery,
  useGetWidgetConfigQuery,
  useLazyGetDocumentListQuery,
  useLazyGetFreshchatGroupsQuery,
  useRequestPlanTierUpgradeMutation,
} = dashboardApi;
