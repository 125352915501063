import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { ArticleDrawer } from './article-drawer';
import { ArticleFeedbackModal } from './article-feedback';
import ArticlePublishModal from './article-publish-modal';
import { useGetArticleIntegrations } from 'src/hooks/discover/useGetArticleIntegrations';
import { selectDiscoverArticlesByPath } from 'src/reducers/discoverReducer/discoverReducer';
import { selectGeneratedArticles } from 'src/slices/solve-insights/solveInsightsSlice';
import {
  selectGlobalDiscoverArticleOptions,
  setGlobalDiscoverArticleOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

const Article = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const solveInsightGeneratedArticles = useSelector(selectGeneratedArticles);
  const articles = useSelector(selectDiscoverArticlesByPath(pathname));
  const currentArticles = pathname.includes(Routes.SOLVE_INSIGHTS_MAIN)
    ? solveInsightGeneratedArticles
    : articles;

  const {
    feedbackArticleModalId,
    isBrandsEnabled,
    isV2,
    segments,
    selectedArticleId,
    shouldShowArticlePublishModal,
  } = useSelector(selectGlobalDiscoverArticleOptions);
  const { data: integrations } = useGetArticleIntegrations();
  const [selectedArticleIndex, setSelectedArticleIndex] = useState<
    number | null
  >(null);

  return (
    <>
      <ArticleDrawer
        articleId={selectedArticleId}
        articles={currentArticles}
        integrations={integrations}
        isBrandsEnabled={isBrandsEnabled}
        isV2={isV2}
        onClickArticlePublishButton={articleIndex => {
          setSelectedArticleIndex(articleIndex);
          dispatch(
            setGlobalDiscoverArticleOptions({
              shouldShowArticlePublishModal: true,
            }),
          );
        }}
        segments={segments}
        setFeedBackArticleModalId={feedbackArticleModalId => {
          dispatch(
            setGlobalDiscoverArticleOptions({
              feedbackArticleModalId,
            }),
          );
        }}
        setSelectedArticleId={selectedArticleId => {
          dispatch(setGlobalDiscoverArticleOptions({ selectedArticleId }));
        }}
      />
      <ArticlePublishModal
        article={
          selectedArticleIndex !== null ? articles[selectedArticleIndex] : null
        }
        articleId={selectedArticleId}
        integrations={integrations}
        isOpen={shouldShowArticlePublishModal}
        onClose={() => {
          dispatch(
            setGlobalDiscoverArticleOptions({
              shouldShowArticlePublishModal: false,
            }),
          );
          setSelectedArticleIndex(null);
        }}
      />
      <ArticleFeedbackModal
        articleId={feedbackArticleModalId}
        articles={currentArticles}
        isOpen={Boolean(feedbackArticleModalId)}
        onClose={() => {
          dispatch(
            setGlobalDiscoverArticleOptions({
              feedbackArticleModalId: '',
            }),
          );
        }}
      />
    </>
  );
};

export default Article;
