import { useMemo } from 'react';

import { FilterOptions } from '../types';
import {
  useGetHelpdeskGroupOptionsQuery,
  useGetTicketChannelOptionsQuery,
} from 'src/services/insights';
import { AgentFilterOptionsResponse } from 'src/services/insights/types';

// Utility to get label and value based on type
const getLabelAndValue = (type: 'group' | 'channel') => {
  switch (type) {
    case 'group':
      return { label: 'Agent groups', value: 'agent_group_id' };
    case 'channel':
      return { label: 'Channels', value: 'ticket_channel' };
    default:
      return { label: '', value: '' };
  }
};

// Process options based on type
export const processOptions = (
  options: AgentFilterOptionsResponse,
  type: 'group' | 'channel',
): FilterOptions => {
  const { label, value } = getLabelAndValue(type);

  const mappedOptions = options.data.map(option => ({
    ...option,
    label:
      // Sentence case for channel option label
      type === 'channel'
        ? option.value
            .split('_')
            .join(' ')
            .replace(/^./, char => char.toUpperCase())
        : option.label,
  }));

  return {
    label,
    options: mappedOptions,
    value,
  };
};

export const useGetAgentOptionsFilter = (): {
  filters: FilterOptions[];
  isLoading: boolean;
} => {
  const { data: groupOptions, isLoading: isLoadingGroupOptions } =
    useGetHelpdeskGroupOptionsQuery();
  const {
    data: ticketChannelOptions,
    isLoading: isLoadingTicketChannelOptions,
  } = useGetTicketChannelOptionsQuery();

  const isLoading = isLoadingGroupOptions || isLoadingTicketChannelOptions;

  // Process groupOptions and ticketChannelOptions
  const processedGroupOptions = useMemo(
    () => (groupOptions ? [processOptions(groupOptions, 'group')] : []),
    [groupOptions],
  );

  const processedTicketChannelOptions = useMemo(
    () =>
      ticketChannelOptions
        ? [processOptions(ticketChannelOptions, 'channel')]
        : [],
    [ticketChannelOptions],
  );

  // Merge processed options
  const queryFilterOptions = useMemo(
    () => [...processedGroupOptions, ...processedTicketChannelOptions],
    [processedGroupOptions, processedTicketChannelOptions],
  );

  return { filters: queryFilterOptions, isLoading };
};
