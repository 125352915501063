import { Divider, styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconArrowUp } from '@tabler/icons-react';

import {
  Badge,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { HEADLINE_REGEX, INSTRUCTION_REGEX } from '../../constants';
import SegmentSection from '../../SegmentSection';
import ArticleContent from './ArticleContent';
import GeneratedKnowledgeContents from './GeneratedKnowledgeContents';
import { getArticleBody, getArticleTitle, verifyValues } from './helpers';
import RelatedArticlesSection from './RelatedArticlesSection';
import SummarySection from './SummarySection';
import TargetArticleSection from './TargetArticleSection';
import TopicSection from './TopicSection';
import { formatDateForTicketCard } from 'src/components/dashboard-pages/discover-topic-detail-page/helpers';
import { NA } from 'src/constants/solve';
import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';

interface GeneratedArticleProps {
  article: KnowledgeGapArticleRecommendation;
  isBrandsEnabled?: boolean;
  isV2?: boolean;
  onDrawerClose?: () => void;
  segments?: Record<string, string> | null | undefined;
}

const GeneratedArticle = ({
  article,
  isBrandsEnabled,
  isV2,
  onDrawerClose,
  segments,
}: GeneratedArticleProps) => {
  const { palette } = useTheme();

  const isArticlePublished = article?.state.includes('published');

  const articleBody = article
    ? article.generated_body.trim().replace(INSTRUCTION_REGEX, '')
    : '';

  const generatedArticleBody = getArticleBody(article, articleBody, isV2);

  const generatedTitle = article
    ? article.generated_title.replace(HEADLINE_REGEX, '')
    : '';

  const hasSummaryAndArticles = verifyValues(article);
  const articleTitleText = isV2 ? 'Content block title' : 'Article title';
  const generatedArticleTitleText = isV2
    ? 'Generated draft for target article'
    : 'Generated article';
  const articleTitle = getArticleTitle(article, generatedTitle, isV2);

  return (
    <Box
      display='flex'
      flexDirection='column'
      rowGap={4}
      sx={{ hr: { borderColor: palette.colors.slate[200] } }}
    >
      <Box display='flex' flexDirection='column' rowGap={1}>
        <Box columnGap={1} display='flex' gap={0.5}>
          <Typography color={palette.colors.grey[600]} noWrap variant='font12'>
            {articleTitleText}:
          </Typography>
          <Typography variant='font12Medium'>
            {article.generated_title}
          </Typography>
        </Box>
        {isBrandsEnabled && (
          <Box columnGap={1} display='flex' gap={0.5}>
            <Typography
              color={palette.colors.grey[600]}
              noWrap
              variant='font12'
            >
              Brand:
            </Typography>
            <Typography
              color={
                !article.api_data_filter ? palette.colors.grey[600] : undefined
              }
              variant='font12Medium'
            >
              {article.api_data_filter || NA}
            </Typography>
          </Box>
        )}
        <Box alignItems='center' columnGap={1} display='flex' gap={0.5}>
          <Typography color={palette.colors.grey[600]} variant='font12'>
            Generated:
          </Typography>
          <Typography variant='font12Medium'>
            {formatDateForTicketCard(
              new Date(article.article_generated_at).getTime(),
            )}
          </Typography>
        </Box>
        <SegmentSection segments={segments} />
      </Box>
      {hasSummaryAndArticles && (
        <Box display='flex' flexDirection='column' rowGap={1}>
          <SummarySection article={article} />
          <Box
            border={`1px solid ${palette.colors.slate[200]}`}
            borderRadius={1}
            p={2}
          >
            {isV2 && (
              <Box display='flex' flexDirection='column'>
                <GeneratedKnowledgeContents article={article} />
                <Divider sx={{ my: 2 }} />
                <TopicSection article={article} onDrawerClose={onDrawerClose} />
                <TargetArticleSection article={article} />
              </Box>
            )}
            {!isV2 && (
              <TopicSection article={article} onDrawerClose={onDrawerClose} />
            )}
            <RelatedArticlesSection
              articles={
                (article as KnowledgeGapArticleRecommendation).relevant_articles
              }
            />
          </Box>
        </Box>
      )}
      <Box
        display='flex'
        flexDirection='column'
        rowGap={1}
        sx={{ wordBreak: 'break-word' }}
      >
        <Box
          alignItems='center'
          display='flex'
          height='40px'
          justifyContent='space-between'
        >
          <Typography variant='font11'>{generatedArticleTitleText}</Typography>
        </Box>
        {isArticlePublished ? (
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
          >
            <Badge
              label={
                <Box alignItems='center' display='flex'>
                  <IconArrowUp size={12} />
                  Uploaded
                </Box>
              }
              variant='success'
            />
          </Box>
        ) : null}
        <Typography variant='font32'>{articleTitle}</Typography>
        <ArticleDisplay>
          <ArticleContent content={generatedArticleBody} />
        </ArticleDisplay>
      </Box>
    </Box>
  );
};

const ArticleDisplay = styled('div')`
  > ol:first-child {
    margin-top: 0;
    margin-block-start: 0;
  }
  > ol {
    padding-inline-start: 20px;
  }
  h1 {
    line-height: 36px;
  }
  h2 {
    line-height: 32px;
  }
  h3 {
    line-height: 28px;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }
  td,
  th {
    padding: 8px;
  }
  td {
    border: 1px solid ${({ theme }) => theme.palette.colors.slate[300]};
  }
`;

export default GeneratedArticle;
