import Box from '@mui/material/Box';

import { formatReadableDate } from './helpers';
import InfoItem from './InfoItem';
import { DocumentDetails } from 'src/services/apiInterfaces';

const IndexInfo = ({
  documentDetails,
}: {
  documentDetails: DocumentDetails;
}) => {
  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <InfoItem label='Source ID' value={documentDetails.source_id} />
      <InfoItem
        label='Last Modified'
        value={formatReadableDate(Number(documentDetails.modified_date))}
      />
      <InfoItem
        label='Date Created'
        value={formatReadableDate(Number(documentDetails.created_date))}
      />
    </Box>
  );
};

export default IndexInfo;
