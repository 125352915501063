import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import { NA } from 'src/constants/solve';
import { DocumentDetails } from 'src/services/apiInterfaces';

interface IndexingNotesSectionProps {
  documentDetails: DocumentDetails;
}

const IndexingNotesSection = ({
  documentDetails,
}: IndexingNotesSectionProps) => {
  const { palette } = useTheme();
  const hasIndexingNotes = Boolean(documentDetails.indexing_notes?.length);
  return (
    <Box display='flex' flexDirection='column' gap={0.5}>
      <Typography color={palette.colors.grey[600]} variant='font12'>
        Indexing Notes
      </Typography>
      {!hasIndexingNotes && (
        <Typography color={palette.colors.grey[600]} variant='font12'>
          {NA}
        </Typography>
      )}
      <Box display='flex' flexDirection='column'>
        {documentDetails.indexing_notes?.map((note, index) => (
          <Typography key={index} variant='font12'>
            {note}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default IndexingNotesSection;
