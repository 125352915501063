import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import { NA } from 'src/constants/solve';

interface DocumentDetailItemProps {
  title: string;
  value: string | null;
}

const DocumentDetailItem = ({ title, value }: DocumentDetailItemProps) => {
  const { palette } = useTheme();
  const valueColor = !value ? palette.colors.grey[600] : undefined;
  return (
    <Box display='flex' flexDirection='column'>
      <Typography color={palette.colors.grey[600]} variant='font12'>
        {title}
      </Typography>
      <Typography color={valueColor} variant='font16'>
        {value || NA}
      </Typography>
    </Box>
  );
};

export default DocumentDetailItem;
