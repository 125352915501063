import { useCallback, useEffect, useMemo, useState } from 'react';

import { SEARCH_QUERY_FILTER_DEBOUNCE_DELAY } from '../constants';
import debounce from 'lodash/fp/debounce';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';

export const useGetSearchQuery = ({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}) => {
  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const [uiQuery, setUiQuery] = useState(searchQuery);

  const handleOnChange = useCallback(
    (value: string) => {
      setSearchQuery(value);
    },
    [setSearchQuery],
  );

  // Optimizations on query search
  const debouncedOnChangeHandler = useMemo(
    () =>
      debounce(SEARCH_QUERY_FILTER_DEBOUNCE_DELAY, (value: string) =>
        handleOnChange(value),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emitTrackingEventCallback, handleOnChange],
  );

  useEffect(() => {
    if (uiQuery !== searchQuery) {
      debouncedOnChangeHandler(uiQuery);
    }
  }, [debouncedOnChangeHandler, searchQuery, uiQuery]);

  return { setUiQuery, uiQuery };
};
