import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import capitalize from 'lodash/capitalize';
import useOrgConfig from 'src/hooks/discover/useOrgConfig';
import { getUserRole, UserRoles } from 'src/utils/enums';

const SegmentSection = ({
  segments,
}: {
  segments: Record<string, string> | null | undefined;
}) => {
  const orgConfig = useOrgConfig();
  const userRole = getUserRole();
  const isSegmented =
    orgConfig?.is_discover_content_multi_segmentation_enabled &&
    userRole === UserRoles.ROLE_SUPER_ADMIN;
  const { palette } = useTheme();

  const isEmpty = !segments || Object.values(segments).length === 0;

  if (!isSegmented || isEmpty) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' gap={0.5}>
      <Typography variant='font12Medium'>Segments:</Typography>
      <Box display='flex' flexDirection='column'>
        {Object.entries(segments).map(([key, value]) => (
          <Box alignItems='center' display='flex' gap={1} key={key}>
            <Typography color={palette.colors.grey[600]} variant='font12Medium'>
              {capitalize(key)}:
            </Typography>
            <Typography variant='font12Medium'>{value}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SegmentSection;
