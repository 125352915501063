import { InsightSortDirection } from '../solve-insights/types';
import { InsightAgentTicketsSortColumns } from './types';
import { InsightAgentTicket } from 'src/services/insights/types';

export const DEBOUNCE_FILTER_DELAY = 2000;

export const AGENTS_HEADER_TOOLTIP_COPY = {
  solved_ticket_count:
    'Total number of tickets marked as resolved.  The % of resolved tickets is calculated by dividing the number of resolved tickets by the total number of assigned tickets.',
  ticket_assigned_count: 'Total number of tickets assigned to agents.',
  total_ticket_count:
    'Total tickets received in the integrated help desk in the selected period.',
};

export const AGENT_TICKETS_TOOLTIP_COPY = {
  ticket_assigned_count: 'Number of tickets currently assigned to this agent',
  ticket_replied_count:
    'Number of tickets the agent added responses in the selected period',
};

export const initialInsightParams: {
  agentTicketColumns: (keyof InsightAgentTicket)[];
  agentTicketColumnSort: InsightAgentTicketsSortColumns;
  agentTicketColumnSortDirection: InsightSortDirection;
} = {
  agentTicketColumns: [
    'agent_name',
    'avg_full_resolution_time_secs',
    'avg_time_to_first_reply_secs',
    'first_contact_resolution_count',
    'ticket_replied_count',
    'ticket_solved_count',
  ],
  agentTicketColumnSort: 'ticket_replied_count',
  agentTicketColumnSortDirection: 'DESC',
};

export const TABLE_COLUMN_TO_LABEL_MAP: {
  agent_ticket: Record<
    keyof Omit<
      InsightAgentTicket,
      | 'agent_email'
      | 'agent_group_id'
      | 'helpdesk_agent_id'
      | 'ticket_solved_rate'
      | 'first_contact_resolution_rate'
    >,
    string
  >;
} = {
  agent_ticket: {
    agent_name: 'Agents',
    avg_full_resolution_time_secs: 'Avg. full resolution time',
    avg_time_to_first_reply_secs: 'Avg. time to first response',
    first_contact_resolution_count: 'First contact resolution',
    ticket_assigned_count: 'Tickets assigned',
    ticket_replied_count: 'Tickets agent replied',
    ticket_solved_count: 'Solved tickets',
  },
};

export const AGENT_TICKET_SEARCH_OPTIONS = [
  { text: 'Agent Name', value: 'agent_name' },
  { text: 'Agent ID', value: 'agent_id' },
];
