import { DashboardTab } from './DashboardTabs';

export const serializeTab = ({
  enableTabKey,
  tab,
  tabs,
}: {
  enableTabKey?: boolean;
  tab: number | string | undefined;
  tabs: DashboardTab[];
}) => {
  if (tab === undefined) {
    return '';
  }
  if (enableTabKey) {
    return tabs[tab as number].tabKey as string;
  }
  return String(tab);
};

export const deserializeTab = ({
  enableTabKey,
  tab,
  tabs,
}: {
  enableTabKey?: boolean;
  tab: string;
  tabs: DashboardTab[];
}) => {
  if (!Number.isNaN(Number(tab))) {
    return Number(tab);
  }
  if (enableTabKey) {
    return Number(tabs.findIndex(t => t.tabKey === tab));
  }
  return Number(tab);
};
