import { useCallback, useRef } from 'react';
import { Box, useTheme } from '@mui/material';

import { useGetMultiSelect } from './hook/useGetMultiSelect';
import AgentTicketsTable from './main/AgentTicketsTable';
import ComparisonSection from './main/ComparisonSection';
import Header from './main/Header';
import HeaderAnalytics from './main/HeaderAnalytics';
import { useStateParams } from 'src/hooks/hooks';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers';

const AgentsInsights = () => {
  const { palette } = useTheme();
  // States
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: last30DaysTimeRange,
    paramsName: 'date',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator([]),
  });

  const {
    handleMultiSelectChange,
    localMultiSelected,
    multiSelected,
    setLocalMultiSelected,
    setMultiSelected,
  } = useGetMultiSelect([], 'agent_filter');

  const handleFilterReset = () => {
    setMultiSelected([], () => setDateRange(last30DaysTimeRange));
    setLocalMultiSelected([]);
  };

  // Scroll logic - for table
  const containerRef = useRef<HTMLDivElement>();
  const scrollToTop = useCallback((yOffset: number, smooth = false) => {
    if (!containerRef.current) {
      return;
    }
    if (containerRef.current.scrollTop > yOffset) {
      containerRef.current.scrollTo({
        behavior: smooth ? 'smooth' : 'auto',
        top: yOffset,
      });
    }
  }, []);

  return (
    <Box bgcolor={palette.colors.white} height='100%'>
      <Box
        bgcolor={palette.colors.white}
        display='flex'
        flexDirection='column'
        padding='24px 40px'
        ref={containerRef}
      >
        <Header
          dateRange={dateRange}
          handleMultiSelectChange={handleMultiSelectChange}
          localMultiSelected={localMultiSelected}
          setDateRange={setDateRange}
          title='Agents'
        />
        <HeaderAnalytics dateRange={dateRange} multiSelected={multiSelected} />
        <ComparisonSection
          dateRange={dateRange}
          multiSelected={multiSelected}
        />
        <AgentTicketsTable
          dateRange={dateRange}
          handleFilterReset={handleFilterReset}
          handleMultiSelectChange={handleMultiSelectChange}
          localMultiSelected={localMultiSelected}
          multiSelected={multiSelected}
          scrollToTop={scrollToTop}
          setDateRange={setDateRange}
        />
      </Box>
    </Box>
  );
};

export default AgentsInsights;
