import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';

const InfoItem = ({ label, value }: { label: string; value: string }) => {
  const { palette } = useTheme();
  return (
    <Box display='flex' gap={1}>
      <Typography color={palette.colors.grey[600]} variant='font12'>
        {label}
      </Typography>
      <Typography variant='font12Medium'>{value}</Typography>
    </Box>
  );
};

export default InfoItem;
