import { useCallback, useMemo, useState } from 'react';
import { MRT_TableOptions, MRT_VisibilityState } from 'material-react-table';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import TuneIcon from '@mui/icons-material/Tune';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Button,
  DateRangeFilterButton,
  InfiniteTable,
  Order,
  SearchBar,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import InsightDownloadCsv from '../common/InsightDownloadCsv';
import { initialInsightParams, INTENT_FILTER_PARAM_NAME } from '../constants';
import {
  dateRangeToTimestamp,
  getIntentFilterOptions,
  sortDirectionToInsightSortDirection,
} from '../helpers';
import { useGetFilterCount } from '../hooks/useGetFilterCount';
import { useGetSearchQuery } from '../hooks/useGetSearchQuery';
import { InsightSortDirection } from '../types';
import { buildEmailArticleColumns } from './helpers';
import GlobalFilterDrawer from 'src/components/global-filter-drawer';
import {
  listCVObjectDeserialize,
  listCVObjectSerialize,
} from 'src/components/global-filter-drawer/helper';
import { ContextVariableFilterItemValue } from 'src/components/global-filter-drawer/types';
import { useGetIntentsQueryWithProduct, useStateParams } from 'src/hooks/hooks';
import {
  getEmailInsightsFilterOptions,
  TOTAL_STICKY_HEADERS_HEIGHT_ARTICLES,
} from 'src/pages/intent-conversation-analytics/constants';
import { buildQueryFromMultiSelected } from 'src/pages/intent-conversation-analytics/hooks/useConversations';
import { useGetEmailArticleTableQuery } from 'src/services/insights';
import {
  InsightEmailArticle,
  InsightEmailArticleSortColumns,
} from 'src/services/insights/types';
import { setSelectedArticleId } from 'src/slices/solve-insights/solveInsightsSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  dateRangeToTimeFilter,
  genericSerializeAndDeserialize,
  listDeserialize,
  listSerialize,
} from 'src/utils/discover/helpers';
import { ExportableTableType, Routes } from 'src/utils/enums';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

interface EmailArticlesTableProps {
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
}

const EmailArticlesTable = ({
  dateRange,
  setDateRange,
}: EmailArticlesTableProps) => {
  const { articleId = undefined } = useParams<'articleId'>();
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const deserializeSortDirection = useCallback(
    (param: string) => (param === 'ASC' ? 'ASC' : 'DESC'),
    [],
  );

  const deserializeSortColumn = useCallback(
    (param: string) => param as InsightEmailArticleSortColumns,
    [],
  );

  // state params
  const [sortDirection, setSortDirection] =
    useStateParams<InsightSortDirection>({
      deserialize: deserializeSortDirection,
      initialState: 'DESC',
      paramsName: 'article_sort_direction',
      serialize: String,
    });
  const [searchQuery, setSearchQuery] = useStateParams<string>({
    deserialize: genericSerializeAndDeserialize,
    initialState: '',
    paramsName: 'articles_search',
    serialize: genericSerializeAndDeserialize,
  });
  const [sortColumn, setSortColumn] =
    useStateParams<InsightEmailArticleSortColumns>({
      deserialize: deserializeSortColumn,
      initialState: initialInsightParams.emailArticleColumnSort,
      paramsName: 'articles_sort_column',
      serialize: String,
    });
  const [multiSelected, setMultiSelected] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_article`,
    serialize: listSerialize,
  });
  const [cvFilters, setCvFilters] = useStateParams<
    ContextVariableFilterItemValue[]
  >({
    deserialize: listCVObjectDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_cv_article`,
    serialize: listCVObjectSerialize,
  });

  // end state params
  const { setUiQuery, uiQuery } = useGetSearchQuery({
    searchQuery,
    setSearchQuery,
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const replaceId = (path: string, id: string) => {
    return path.replace(':articleId', id);
  };

  const handleSetSelectedArticleId = useCallback(
    (articleId: string) => dispatch(setSelectedArticleId(articleId)),
    [dispatch],
  );

  const columns = useMemo(
    () =>
      buildEmailArticleColumns(palette, handleSetSelectedArticleId, navigate),
    [palette, handleSetSelectedArticleId, navigate],
  );

  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);

  const tableRequestParams = useMemo(
    () => ({
      article_id: articleId,
      cv_filters: cvFilters,
      data_export_type: ExportableTableType.INSIGHT_EMAIL_ARTICLES_TABLE,
      end: backendReadyTimestamps.end_timestamp,
      page,
      sort_column: sortColumn,
      sort_direction: sortDirection,
      start: backendReadyTimestamps.start_timestamp,
      user_query: searchQuery,
      ...buildQueryFromMultiSelected(multiSelected),
    }),
    [
      backendReadyTimestamps.end_timestamp,
      page,
      sortColumn,
      sortDirection,
      backendReadyTimestamps.start_timestamp,
      multiSelected,
      searchQuery,
      articleId,
      cvFilters,
    ],
  );

  const {
    data: articleTableData,
    isError: isArticleTableError,
    isFetching: isArticleTableFetching,
    isLoading: isArticleTableLoading,
  } = useGetEmailArticleTableQuery({
    ...tableRequestParams,
  });

  const timeFilter = dateRangeToTimeFilter(dateRange);
  const initialTimeFilter = dateRangeToTimeFilter(initialInsightParams.date);
  const showLoadingSkeleton =
    isArticleTableLoading || (page === 1 && isArticleTableFetching);

  const areFiltersUsed = Boolean(
    uiQuery.length ||
      timeFilter.key !== initialTimeFilter.key ||
      multiSelected.length ||
      cvFilters.length,
  );

  const getArticleTableDownloadUrl = useCallback(() => {
    const url = new URL(`${API_URL}data-export`);
    return url.href;
  }, []);

  const tableDataPage = articleTableData?.metadata.page ?? 0;

  const handleLoadMore = useCallback(() => {
    if (tableDataPage === page) {
      const nextPage = page + 1;
      setPage(nextPage);
    }
  }, [page, tableDataPage]);

  const handleSortCallback = useCallback(
    (property: keyof InsightEmailArticle, order: Order) => {
      setPage(1);
      setSortDirection(sortDirectionToInsightSortDirection(order));
      setSortColumn(
        property === 'user_feedback_positive'
          ? 'user_feedback'
          : (property as InsightEmailArticleSortColumns),
      );
    },
    [setSortColumn, setSortDirection],
  );

  const handleResetAllFilter = useCallback(() => {
    setUiQuery('');
    setDateRange(initialInsightParams.date);
    setSortColumn(initialInsightParams.emailArticleColumnSort, () =>
      setMultiSelected([], () => setCvFilters([])),
    );
  }, [setUiQuery, setDateRange, setSortColumn, setMultiSelected, setCvFilters]);

  const { data: intentsData, isLoading: intentsLoading } =
    useGetIntentsQueryWithProduct();
  const { intents = [] } = intentsData ?? {};

  const filterOptions = useMemo(() => {
    const extraFilters = [];

    if (!intentsLoading) {
      extraFilters.push({
        label: 'All workflows',
        options: getIntentFilterOptions(intents),
        value: 'intent_id',
      });
    }

    return getEmailInsightsFilterOptions({
      extraFilters,
      type: 'article',
    });
  }, [intentsLoading, intents]);

  const initialSorting = useMemo(
    () => [
      {
        desc: sortDirection === 'DESC',
        id: sortColumn,
      },
    ],
    [sortColumn, sortDirection],
  );

  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    Object.fromEntries(
      initialInsightParams.emailArticleColumns.map(column => [column, true]),
    ),
  );

  const filterCount = useGetFilterCount({
    channels: [],
    cvFilters,
    multiSelected,
    workflowTags: [],
  });

  const tableOptions = useMemo<MRT_TableOptions<InsightEmailArticle>>(
    () => ({
      columns: columns,
      data: articleTableData?.data ?? [],
      enableColumnActions: false,
      enableColumnFilters: false,
      enableExpanding: false,
      enableFullScreenToggle: false,
      enableGlobalFilter: false,
      enableRowActions: false,
      initialState: {
        columnPinning: {
          left: ['title'],
        },
      },
      muiTableBodyRowProps: ({ row }) => ({
        onClick: () => {
          navigate({
            pathname: replaceId(
              Routes.SOLVE_INSIGHTS_ARTICLE_DETAIL,
              row.original.id,
            ),
            search: new URLSearchParams({
              product_mode: 'email',
              tab: 'article',
            }).toString(),
          });
        },
        sx: {
          cursor: 'pointer',
        },
      }),
      onColumnVisibilityChange: setColumnVisibility,
      renderTopToolbarCustomActions: () => (
        <Box display='flex' gap='8px' paddingTop='4px' width='100%'>
          <Box key={1} width='200px'>
            <SearchBar
              onChange={e => setUiQuery(e.target.value)}
              placeholder='Search article'
              size='small'
              value={uiQuery}
            />
          </Box>
          <DateRangeFilterButton
            explicitLabel={true}
            initialValue={initialInsightParams.date}
            onChange={value => setDateRange(value)}
            options={datePickerRangeOptionsRevamp}
            value={dateRange}
          />
          <Button
            onClick={() => setIsDrawerOpen(true)}
            size='medium'
            startIcon={<TuneIcon />}
            variant='secondary'
          >
            <Typography noWrap variant='font14Medium'>
              {filterCount ? `All filters (${filterCount})` : 'All filters'}
            </Typography>
          </Button>
          {areFiltersUsed && (
            <Button
              onClick={handleResetAllFilter}
              size='medium'
              variant='ghost'
            >
              <Typography noWrap variant='font14Medium'>
                Reset filters
              </Typography>
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <InsightDownloadCsv
              filename='email_articles.csv'
              requestData={{
                ...tableRequestParams,
              }}
              url={getArticleTableDownloadUrl()}
            />
          </Box>
        </Box>
      ),
      state: {
        columnVisibility,
      },
    }),
    [
      columns,
      articleTableData?.data,
      columnVisibility,
      navigate,
      uiQuery,
      dateRange,
      areFiltersUsed,
      getArticleTableDownloadUrl,
      setUiQuery,
      setDateRange,
      tableRequestParams,
      filterCount,
      handleResetAllFilter,
    ],
  );

  return (
    <>
      <InfiniteTable
        hasNextPage={typeof articleTableData?.metadata.next_page === 'number'}
        initialSorting={initialSorting}
        isError={isArticleTableError}
        isLoadingFirstPage={showLoadingSkeleton}
        isLoadingNextPage={isArticleTableFetching}
        onLoadMore={handleLoadMore}
        onSortCallback={handleSortCallback}
        stickyHeaderHeight={TOTAL_STICKY_HEADERS_HEIGHT_ARTICLES}
        tableOptions={tableOptions}
      />
      {isDrawerOpen && (
        <GlobalFilterDrawer
          filterType='article'
          handleReset={handleResetAllFilter}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          otherFiltersOptions={filterOptions}
          product='email'
          setUiQuery={setUiQuery}
          tab='article'
          uiQuery={uiQuery}
        />
      )}
    </>
  );
};

export default EmailArticlesTable;
