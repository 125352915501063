import Skeleton from 'react-loading-skeleton';
import { Box, Grid, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  dateRangeToTimestamp,
  numbersToStringWithCommas,
} from '../../solve-insights/helpers';
import { AGENTS_HEADER_TOOLTIP_COPY } from '../constants';
import { useGetFilterForBackend } from '../hook/useGetFilterForBackend';
import { formatSecondsToReadableHrMinTime } from '../utils';
import { useGetAgentsHeaderQuery } from 'src/services/insights';
import { DateRange } from 'src/types/types';
interface HeaderAnalyticsProps {
  dateRange: DateRange;
  multiSelected: string[];
}

const HeaderAnalytics = ({
  dateRange,
  multiSelected,
}: HeaderAnalyticsProps) => {
  const { palette } = useTheme();

  const { end_timestamp: end, start_timestamp: start } =
    dateRangeToTimestamp(dateRange);
  const filterForBackendQuery = useGetFilterForBackend(multiSelected);

  const { data, isFetching, isLoading } = useGetAgentsHeaderQuery({
    end,
    start,
    ...filterForBackendQuery,
  });

  const showLoadingSkeleton = isFetching || isLoading;

  const AnalyticCard = ({
    percentValue,
    title,
    tooltipContent,
    value,
  }: {
    percentValue?: string;
    title: string;
    tooltipContent?: string;
    value?: string;
  }) => {
    return (
      <Box display='flex' flexDirection='column' gap={1}>
        <Box alignItems='center' display='flex' gap='2px'>
          <Typography color={palette.grey[800]} variant='font14Bold'>
            {title}
          </Typography>
          {tooltipContent && (
            <Tooltip tooltipContent={tooltipContent}>
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          )}
        </Box>
        <Box>
          {showLoadingSkeleton ? (
            <Skeleton height='20px' width='200px' />
          ) : (
            <Typography variant='font24'>
              {value}
              {percentValue !== undefined && ` (${percentValue})`}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box display='flex' paddingTop={4}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <AnalyticCard
            title='Total received ticket'
            tooltipContent={AGENTS_HEADER_TOOLTIP_COPY.total_ticket_count}
            value={numbersToStringWithCommas({
              number: data?.total_ticket_count,
            })}
          />
        </Grid>
        <Grid item xs={3}>
          <AnalyticCard
            title='Ticket assigned'
            tooltipContent={AGENTS_HEADER_TOOLTIP_COPY.ticket_assigned_count}
            value={numbersToStringWithCommas({
              number: data?.ticket_assigned_count,
            })}
          />
        </Grid>
        <Grid item xs={3}>
          <AnalyticCard
            percentValue={numbersToStringWithCommas({
              number: data?.solved_ticket_percentage,
              style: 'percent',
            })}
            title='Resolved tickets'
            tooltipContent={AGENTS_HEADER_TOOLTIP_COPY.solved_ticket_count}
            value={numbersToStringWithCommas({
              number: data?.solved_ticket_count,
            })}
          />
        </Grid>
        <Grid item xs={3}>
          <AnalyticCard
            percentValue={numbersToStringWithCommas({
              number: data?.first_contact_resolution_percentage,
              style: 'percent',
            })}
            title='First contact resolution'
            value={numbersToStringWithCommas({
              number: data?.first_contact_resolution_count,
            })}
          />
        </Grid>
        <Grid item xs={3}>
          <AnalyticCard
            title='Avg. full resolution time'
            value={formatSecondsToReadableHrMinTime(
              data?.full_resolution_time_secs,
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <AnalyticCard
            title='Avg. time to first response'
            value={formatSecondsToReadableHrMinTime(
              data?.time_to_first_response,
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeaderAnalytics;
