import { useCallback, useEffect, useMemo, useState } from 'react';
import { MRT_TableOptions } from 'material-react-table';
import { useParams } from 'react-router-dom';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  Checkbox,
  DateRangeFilterButton,
  InfiniteTable,
  Order,
  SearchWithDropdown,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import InsightDownloadCsv from '../common/InsightDownloadCsv';
import { initialInsightParams, INTENT_FILTER_PARAM_NAME } from '../constants';
import {
  dateRangeToTimestamp,
  isUiQueryError,
  sortDirectionToInsightSortDirection,
} from '../helpers';
import { useGetCurrentTab } from '../hooks/useGetCurrentTab';
import { useGetFilterCount } from '../hooks/useGetFilterCount';
import { useGetIntentsForFilter } from '../hooks/useGetIntentsForFilter';
import { useGetSearchQuery } from '../hooks/useGetSearchQuery';
import {
  InsightPeriod,
  InsightSortDirection,
  Scope,
  SearchQueryMode,
} from '../types';
import ChatsTableLayout from './ChatsTableLayout';
import { buildConversationColumns, getTableHeight, View } from './helpers';
import GlobalFilterDrawer from 'src/components/global-filter-drawer';
import {
  listCVObjectDeserialize,
  listCVObjectSerialize,
} from 'src/components/global-filter-drawer/helper';
import { ContextVariableFilterItemValue } from 'src/components/global-filter-drawer/types';
import WithHelperText from 'src/components/with-helper-text/WithHelperText';
import {
  useEmitTrackingEventCallback,
  useGetIntentsQueryWithProduct,
  useGetWorkflowTags,
  useStateParams,
} from 'src/hooks/hooks';
import {
  ALLTIME_START,
  DEFLECTION_INSIGHT_FILTER_OPTIONS,
  getInsightsFilterOptions,
  getVoiceInsightsFilterOptions,
} from 'src/pages/intent-conversation-analytics/constants';
import ConversationDrawer from 'src/pages/intent-conversation-analytics/ConversationDrawer';
import { buildQueryFromMultiSelected } from 'src/pages/intent-conversation-analytics/hooks/useConversations';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import useIsDeflectionInsightsEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsDeflectionInsightsEnabled';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  useGetConversationQuery,
  useGetConversationTableQuery,
} from 'src/services/insights';
import {
  InsightConversation,
  InsightConversationSortColumns,
} from 'src/services/insights/types';
import { getWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import { ConversationChannel } from 'src/types/workflowBuilderAPITypes';
import {
  dateRangeToTimeFilter,
  genericSerializeAndDeserialize,
  listDeserialize,
  listSerialize,
} from 'src/utils/discover/helpers';
import { CommonIntentWorkflowType, ExportableTableType } from 'src/utils/enums';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

interface ChatsTableProps {
  dateRange: DateRange;
  handleFilterReset?: () => void;
  isDetail?: boolean;
  isVoiceChannel?: boolean;
  period?: InsightPeriod;
  scope: Scope;
  scrollTop: number;
  scrollToTop: (yOffset: number, smooth?: boolean) => void;
  setDateRange: (
    dateRange: DateRange,
    batchUpdater?: () => URLSearchParams,
  ) => URLSearchParams;
  setPeriodicalFilter?: (period: InsightPeriod) => void;
  view: View;
}

const ChatsTable = ({
  dateRange,
  handleFilterReset,
  isDetail,
  isVoiceChannel,
  period,
  scope,
  scrollToTop,
  setDateRange,
  setPeriodicalFilter,
  view,
}: ChatsTableProps) => {
  const dispatch = useAppDispatch();
  const { articleId = undefined } = useParams<'articleId'>();
  const { topicId = undefined } = useParams<'topicId'>();
  const { tableTab = undefined } = useParams<'tableTab'>();
  const { workflowId = undefined } = useParams<'workflowId'>();
  const isParentTopic = location.pathname.includes('parent');
  const [page, setPage] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const getCSATColor = useGetCSATColor();
  const { palette } = useTheme();

  useEffect(() => {
    dispatch(getWidgetConfiguration());
  }, [dispatch]);

  const tab = useGetCurrentTab({
    articleId,
    isParentTopic,
    tableTab,
    topicId,
    workflowId,
  });
  const [cvFilters, setCvFilters] = useStateParams<
    ContextVariableFilterItemValue[]
  >({
    deserialize: listCVObjectDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_cv_${tab}`,
    serialize: listCVObjectSerialize,
  });
  const [multiSelected, setMultiSelected] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_${tab}`,
    serialize: listSerialize,
  });

  const deserializeSortDirection = useCallback(
    (param: string) => (param === 'ASC' ? 'ASC' : 'DESC'),
    [],
  );

  const deserializeSortColumn = useCallback(
    (param: string) => param as InsightConversationSortColumns,
    [],
  );

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');
  const isQuickFeedbackAnalyticsEnabled = featureFlags.includes(
    'show_quick_feedback_analytics',
  );
  const shouldShowQuickFeedbackAnalytics =
    isQuickFeedbackEnabled && isQuickFeedbackAnalyticsEnabled;
  // state params
  const [sortDirection, setSortDirection] =
    useStateParams<InsightSortDirection>({
      deserialize: deserializeSortDirection,
      initialState: 'DESC',
      paramsName: 'chats_sort_direction',
      serialize: String,
    });
  const [searchQuery, setSearchQuery] = useStateParams<string>({
    deserialize: (str: string) => (str === 'null' ? '' : str),
    initialState: '',
    paramsName: 'chats_search',
    serialize: genericSerializeAndDeserialize,
  });
  const [searchQueryMode, setSearchQueryMode] = useStateParams<SearchQueryMode>(
    {
      deserialize: genericSerializeAndDeserialize as (
        str: string,
      ) => SearchQueryMode,
      initialState: 'conversation_id',
      paramsName: 'chats_search_mode',
      serialize: genericSerializeAndDeserialize,
    },
  );
  const [sortColumn, setSortColumn] =
    useStateParams<InsightConversationSortColumns>({
      deserialize: deserializeSortColumn,
      initialState: initialInsightParams.chatColumnSort,
      paramsName: 'chats_sort_column',
      serialize: String,
    });
  const [channels, setChannels] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: 'channels',
    serialize: listSerialize,
  });
  // end state params

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const workflowTags = useGetWorkflowTags();
  /**
   * Since both design version exist with the feature flag value
   * TODO - clean up the prop drilling once only the new design is used
   * The debouncing is causing issues when using with conjunction with useStateParams
   */
  const { setUiQuery, uiQuery } = useGetSearchQuery({
    searchQuery,
    setSearchQuery,
  });

  const uiQueryError = isUiQueryError({ searchQueryMode, uiQuery });

  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();

  // get conversation details once clicked
  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >(null);
  const {
    data: conversationData,
    isFetching: isFetchingConversation,
    isLoading: isLoadingConversation,
  } = useGetConversationQuery(
    { conversation_id: selectedConversationId || '' },
    {
      skip: !selectedConversationId,
    },
  );
  // end get conversation details once clicked

  const columns = useMemo(
    () =>
      buildConversationColumns(
        palette,
        getCSATColor,
        emitTrackingEventCallback,
        scope,
        tab,
        isDetail,
        isDeflectionInsightsEnabled,
        shouldShowQuickFeedbackAnalytics,
        Boolean(isVoiceChannel),
      ),
    [
      palette,
      getCSATColor,
      isDetail,
      emitTrackingEventCallback,
      scope,
      tab,
      isDeflectionInsightsEnabled,
      shouldShowQuickFeedbackAnalytics,
      isVoiceChannel,
    ],
  );
  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);

  const isSearchingByChatId =
    searchQueryMode === 'conversation_id' && uiQuery && !uiQueryError;
  const end = useMemo(() => Math.floor(Date.now() / 1000), []);

  const filterCount = useGetFilterCount({
    channels,
    cvFilters,
    multiSelected,
    workflowTags,
  });

  const handleResetAllFilter = useCallback(() => {
    setPeriodicalFilter && setPeriodicalFilter(initialInsightParams.period);
    handleFilterReset && handleFilterReset();
    emitTrackingEventCallback('insight-reset-filter', {
      scope,
      tab,
      table: 'chat',
    });

    setUiQuery('');

    setSearchQueryMode('conversation_id', () =>
      setSortColumn(initialInsightParams.chatColumnSort, () =>
        setDateRange(initialInsightParams.date, () =>
          setMultiSelected([], () => setChannels([], () => setCvFilters([]))),
        ),
      ),
    );
  }, [
    setPeriodicalFilter,
    handleFilterReset,
    emitTrackingEventCallback,
    scope,
    tab,
    setUiQuery,
    setSearchQueryMode,
    setSortColumn,
    setDateRange,
    setMultiSelected,
    setChannels,
    setCvFilters,
  ]);

  const tableRequestParams = useMemo(
    () => ({
      article_id: articleId,
      channels: isVoiceChannel
        ? 'voice'
        : buildQueryFromMultiSelected(channels)?.channels,
      chat: searchQueryMode === 'chat' ? searchQuery : '',
      conversation_id:
        searchQueryMode === 'conversation_id' && !uiQueryError
          ? searchQuery
          : '',
      cv_filters: cvFilters,
      data_export_type: ExportableTableType.INSIGHT_CHATS_TABLE,
      is_parent_topic: isParentTopic,
      sort_column: sortColumn,
      sort_direction: sortDirection,
      topic_id: topicId,
      user_query: searchQueryMode === 'user_query' ? searchQuery : '',
      workflow_id: workflowId,
      ...(isSearchingByChatId
        ? // Use maximum date range:
          {
            end: end,
            page: 1,
            start: Number(ALLTIME_START),
          }
        : {
            end: backendReadyTimestamps.end_timestamp,
            page,
            start: backendReadyTimestamps.start_timestamp,
            ...buildQueryFromMultiSelected(multiSelected),
          }),
    }),
    [
      articleId,
      searchQueryMode,
      searchQuery,
      uiQueryError,
      isParentTopic,
      sortColumn,
      sortDirection,
      topicId,
      workflowId,
      isSearchingByChatId,
      backendReadyTimestamps,
      page,
      multiSelected,
      channels,
      end,
      cvFilters,
      isVoiceChannel,
    ],
  );

  const {
    data: conversationTableData,
    isError: isChatsTableError,
    isFetching: isConversationTableFetching,
    isLoading: isConversationTableLoading,
  } = useGetConversationTableQuery({
    ...tableRequestParams,
  });

  const rows = useMemo(() => {
    if (!conversationTableData?.data) {
      return [];
    }
    return conversationTableData.data;
  }, [conversationTableData]);

  const showLoadingSkeleton =
    isConversationTableLoading || (page === 1 && isConversationTableFetching);

  const timeFilter = dateRangeToTimeFilter(dateRange);
  const initialTimeFilter = dateRangeToTimeFilter(initialInsightParams.date);

  const selectedConversation = useMemo(() => {
    if (!selectedConversationId || !conversationData) {
      return;
    }
    return conversationData;
  }, [conversationData, selectedConversationId]);

  const areFiltersUsed = Boolean(
    uiQuery.length ||
      timeFilter.key !== initialTimeFilter.key ||
      multiSelected.length ||
      cvFilters.length ||
      (period && period !== initialInsightParams.period) ||
      (channels.length && channels.length !== 3),
  );

  const getChatsTableDownloadUrl = useCallback(() => {
    const url = new URL(`${API_URL}data-export`);
    return url.href;
  }, []);

  const handleSortCallback = useCallback(
    (property: keyof InsightConversation, order: Order) => {
      setPage(1);
      setSortDirection(sortDirectionToInsightSortDirection(order));
      setSortColumn(
        property === 'useful_count'
          ? 'quick_feedback'
          : (property as InsightConversationSortColumns),
      );
      scrollToTop(72);
      emitTrackingEventCallback('insight-table-sort-change', {
        column: property,
        direction: order,
        scope,
        tab,
        table: 'chat',
      });
    },
    [
      tab,
      scrollToTop,
      emitTrackingEventCallback,
      scope,
      setSortColumn,
      setSortDirection,
    ],
  );

  const initialSorting = useMemo(
    () => [
      {
        desc: sortDirection === 'DESC',
        id: sortColumn,
      },
    ],
    [sortColumn, sortDirection],
  );

  const tableDataPage = conversationTableData?.metadata.page ?? 0;

  const handleLoadMore = useCallback(() => {
    if (tableDataPage === page) {
      const nextPage = page + 1;
      setPage(nextPage);
      emitTrackingEventCallback('insight-table-load-change', {
        currentPage: nextPage,
        scope,
        tab,
        table: 'chat',
      });
    }
  }, [tab, emitTrackingEventCallback, page, scope, tableDataPage]);

  const { data: intentsData } = useGetIntentsQueryWithProduct();

  // TODO: get this from query param once channels are implemented:
  const channel: ConversationChannel = 'widget';

  const intentsForFilter = useGetIntentsForFilter({ channel, intentsData });

  const filterOptions = useMemo(() => {
    if (isVoiceChannel) {
      return getVoiceInsightsFilterOptions();
    }

    const extraFilters = [];

    if (tab !== 'workflow') {
      extraFilters.push({
        label: 'All workflows',
        options: intentsForFilter,
        value: 'workflow_id',
      });
    }

    if (isDeflectionInsightsEnabled) {
      extraFilters.push(...DEFLECTION_INSIGHT_FILTER_OPTIONS);
    }

    return getInsightsFilterOptions({
      extraFilters,
      hideWorkflowTypes: tab === 'workflow',
    });
  }, [intentsForFilter, isDeflectionInsightsEnabled, tab, isVoiceChannel]);

  const searchOptions = useMemo(
    () => [
      { text: 'Conversation ID', value: 'conversation_id' },
      { text: 'Query', value: 'user_query' },
      { text: 'Conversation', value: 'chat' },
    ],
    [],
  );

  const fallbackWorkflowId = intentsData?.intents.find(
    intent => intent.intent_definition_id === CommonIntentWorkflowType.FALLBACK,
  )?.intent_workflow_id;

  const isGapsOnlySelected = multiSelected.includes(
    `workflow_id.${fallbackWorkflowId}`,
  );

  const tableOptions = useMemo<MRT_TableOptions<InsightConversation>>(
    () => ({
      columns,
      data: rows,
      enableColumnActions: false,
      enableColumnFilters: false,
      enableExpanding: false,
      enableFullScreenToggle: false,
      enableGlobalFilter: false,
      enableRowActions: false,
      initialState: {
        columnPinning: {
          left: ['timestamp'],
        },
      },
      muiTableBodyRowProps: ({ row }) => ({
        onClick: () => {
          setSelectedConversationId(row.original.conversation_id);
          emitTrackingEventCallback('insight-table-row-clicked', {
            id: row.original.conversation_id,
            scope,
            tab,
            table: 'chat',
          });
        },
      }),
      renderTopToolbarCustomActions: () => (
        <Box
          alignItems='center'
          display='flex'
          gap='8px'
          height='40px'
          width='100%'
        >
          <Box width='360px'>
            <WithHelperText helperText={uiQueryError}>
              <SearchWithDropdown
                aria-label='Chat ID filter'
                fullWidth
                onChange={e => setUiQuery(e.target.value)}
                onClear={() => setUiQuery('')}
                options={[...searchOptions]}
                placeholder={`Enter ${searchOptions
                  .find(option => option.value === searchQueryMode)
                  ?.text.toLowerCase()}`}
                selectChange={value => {
                  setSearchQueryMode(value as SearchQueryMode);
                  setUiQuery('');
                }}
                selectedOption={searchQueryMode}
                value={uiQuery}
              />
            </WithHelperText>
          </Box>
          <DateRangeFilterButton
            explicitLabel={true}
            initialValue={initialInsightParams.date}
            onChange={value => {
              setDateRange(value);
              const timeStamp = dateRangeToTimestamp(value);
              emitTrackingEventCallback('insight-date-change', {
                from: timeStamp.start_timestamp,
                scope,
                tab,
                table: 'chat',
                to: timeStamp.end_timestamp,
              });
            }}
            options={datePickerRangeOptionsRevamp}
            value={dateRange}
          />
          <Button
            onClick={() => setIsDrawerOpen(true)}
            size='medium'
            startIcon={<TuneIcon />}
            variant='secondary'
          >
            <Typography noWrap variant='font14Medium'>
              {filterCount ? `All filters (${filterCount})` : 'All filters'}
            </Typography>
          </Button>
          {Boolean(fallbackWorkflowId) && !isVoiceChannel && (
            <Tooltip tooltipContent="Chats trigger ‘Fallback’ when they can't answer a question due to insufficient knowledge articles or workflow gaps">
              <Checkbox
                checked={isGapsOnlySelected}
                label='Gaps only'
                onChange={event => {
                  const isChecked = event.target.checked;
                  if (isChecked) {
                    setMultiSelected([
                      ...multiSelected,
                      `workflow_id.${fallbackWorkflowId}`,
                    ]);
                  } else {
                    const updatedMultiSelected = multiSelected.filter(
                      item => item !== `workflow_id.${fallbackWorkflowId}`,
                    );
                    setMultiSelected(updatedMultiSelected);
                  }
                }}
              />
            </Tooltip>
          )}
          {areFiltersUsed && (
            <Button
              onClick={handleResetAllFilter}
              size='medium'
              variant='ghost'
            >
              <Typography noWrap variant='font14Medium'>
                Reset filters
              </Typography>
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <InsightDownloadCsv
              filename='chats.csv'
              requestData={{ ...tableRequestParams }}
              url={getChatsTableDownloadUrl()}
            />
          </Box>
        </Box>
      ),
    }),
    [
      columns,
      rows,
      emitTrackingEventCallback,
      scope,
      tab,
      uiQueryError,
      searchOptions,
      searchQueryMode,
      uiQuery,
      dateRange,
      multiSelected,
      fallbackWorkflowId,
      isVoiceChannel,
      areFiltersUsed,
      getChatsTableDownloadUrl,
      setUiQuery,
      setSearchQueryMode,
      setDateRange,
      setMultiSelected,
      tableRequestParams,
      filterCount,
      handleResetAllFilter,
      isGapsOnlySelected,
    ],
  );

  return (
    <>
      <ConversationDrawer
        isLoading={isLoadingConversation || isFetchingConversation}
        onClose={() => {
          setSelectedConversationId(null);
          emitTrackingEventCallback('insight-chat-drawer-close', {
            id: selectedConversation?.conversation_id,
            scope,
            tab,
          });
        }}
        openEventTrigger={() => {
          emitTrackingEventCallback('insight-chat-drawer-open', {
            id: selectedConversation?.conversation_id,
            scope,
            tab,
          });
        }}
        selectedConversation={selectedConversation}
      />
      {isDrawerOpen && (
        <GlobalFilterDrawer
          filterType='chat'
          handleReset={handleResetAllFilter}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          otherFiltersOptions={filterOptions}
          product={isVoiceChannel ? 'voice' : 'widget'}
          searchOptions={searchOptions}
          setUiQuery={setUiQuery}
          tab={tab}
          uiQuery={uiQuery}
        />
      )}
      <ChatsTableLayout
        table={
          <InfiniteTable
            hasNextPage={
              typeof conversationTableData?.metadata.next_page === 'number'
            }
            initialSorting={initialSorting}
            isError={isChatsTableError}
            isLoadingFirstPage={showLoadingSkeleton}
            isLoadingNextPage={isConversationTableFetching}
            onLoadMore={handleLoadMore}
            onSortCallback={handleSortCallback}
            stickyHeaderHeight={getTableHeight(view)}
            tableOptions={tableOptions}
          />
        }
      />
    </>
  );
};
export default ChatsTable;
